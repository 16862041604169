import {
    Conduct, Participant,
    ScenarioScriptSectionStep
} from "../../interfaces/interfaces";


export function getPlaceholderScenarioScriptSectionStep(): ScenarioScriptSectionStep {
    return {
        stepId: "",
        stepContent:  "",
        stepType: "Discussion",
        stepName:  "New Step",
        stepInstructions:  "",
        stepTalkingPoint:  "",
        stepResources: [],
        stepObjectives: [],
        stepGroups: [],
        stepQA: undefined,
        images: [],
        stepTimeInMinutes: 0,
        stepStoryline: ""
    };
}

export function getPlaceholderParticipant(): Participant {
    return{
        participantId: Math.random().toString(36).substring(2),
        name: '',
        role: '',
        email: '',
        attended: true,
        groups: []
    };
}
export function getPlaceholderConduct(): Conduct {
    return {
        location: "",
        agenda: "",
        sendNotification: false,
        isRemote: false,
        timeDuration: 0,
        timeDurationUnit: "",
        conductId: "",
        scenarioId: "",
        orgId: "",
        chatId: "",
        simulationId: "",
        portalId: null,
        companyId: "",
        name: "Placeholder",
        description: "",
        type: "",
        status: "",
        isComplete: false,
        isAARPublished: false,
        sendReminder: false,
        script: [],
        injects: [],
        team: [],
        roles: [],
        groups: [],
        notes: "",
        participants: [],
        timeStarted: "",
        failCondition: "",
        elapsedTime: "",
        elapsedTimeInMinutes: 0,
        messages: [],
        objective: "",
        actions: [],
        createDate: "",
        scheduledDate: "",
        userAudit: null,
        files: [],
        contextFiles: [],
        artifacts: [],
        focusArea: "",
        landingPageCode: "",
        isVirtualAssistantOn: false,
        facilitatorMessages: [],
        conductObjectives: [],
        conductMode: "",
        exerciseType: "",
        scenarioStory:null
    };
}