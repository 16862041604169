import React, { useEffect, useState } from 'react';
import { AAR, Conduct } from "./interfaces/interfaces";
import { Button, ButtonGroup, Grid, Stack, TextField } from "@mui/material";
import QuickLoaderComponent from "./components/cards/QuickLoaderComponent";
import ReactMarkdown from "react-markdown";

interface RecommendationAnalysisComponentProps {
    conduct: Conduct;
    portalId: string;
    companyId: string;
    scenarioId: string;
    aar: AAR;
    editable: boolean;
    onUpdate: () => void;
    persistAnalysis?: (analysis: string) => void;
    persistAnalysisValue?: string;
    isPortal: boolean;
}

const RecommendationAnalysisComponent: React.FC<RecommendationAnalysisComponentProps> = ({ conduct, portalId, scenarioId, aar, isPortal, companyId, editable, onUpdate, persistAnalysis, persistAnalysisValue }) => {
    const [frameworkBanner, setFrameworkBanner] = useState<string>("Loading Framework Analysis...");
    const [isFrameworkAnalysisLoading, setFrameworkAnalysisLoading] = useState<boolean>(false);
    const [updatedAAR, setAAR] = useState<AAR>(aar);
    const [frameworkAnalysis, setFrameworkAnalysis] = useState<string[]>(persistAnalysisValue ? [persistAnalysisValue] : []);

    useEffect(() => {
        if (updatedAAR && updatedAAR.recommendations) {
            setFrameworkAnalysis(updatedAAR.recommendations);
        }
    }, [updatedAAR]);

    useEffect(() => {
        if (persistAnalysisValue) {
            setFrameworkAnalysis([persistAnalysisValue]);
        }
    }, [persistAnalysisValue]);

    const handleUpdate = async () => {
        setFrameworkBanner("Updating After-Action Review with Recommendations...");
        setFrameworkAnalysisLoading(true);
        let token = localStorage.getItem("token");

        const updateURL = isPortal
            ? `${process.env.REACT_APP_REST_API_URL}/api/portal/conduct/aar/updateaar/${token}/${companyId}/${portalId}/${scenarioId}/${conduct.conductId}`
            : `${process.env.REACT_APP_REST_API_URL}/api/tabletop/company/scenario/tabletop/aar/updateaar/${token}/${companyId}/${scenarioId}/${conduct.conductId}`;
        try {
            const response = await fetch(updateURL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(updatedAAR),
            });
            const data = await response.json();
            setAAR(data);
        } catch (error) {
            console.error("Error updating scenario:", error);
        }
        setFrameworkAnalysisLoading(false);
        setFrameworkBanner("After-Action Review Updated!");
        onUpdate();
    }

    const [isEditMode, setIsEditMode] = useState<boolean>(editable);

    return (
        <Grid container={true} spacing={2}>
            {editable &&
                <>
                    {isFrameworkAnalysisLoading &&
                        <Grid item xs={12}>
                            <QuickLoaderComponent loadBanner={frameworkBanner} />
                        </Grid>
                    }
                    <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Stack direction={"row"} spacing={2} justifyContent={"center"}>
                                            <ButtonGroup size={"small"}>
                                                <Button
                                                    variant={isEditMode ? "contained" : "outlined"}
                                                    onClick={() => setIsEditMode(true)}
                                                >
                                                    Edit
                                                </Button>
                                                <Button
                                                    variant={!isEditMode ? "contained" : "outlined"}
                                                    onClick={() => setIsEditMode(false)}
                                                >
                                                    Preview
                                                </Button>
                                            </ButtonGroup>
                                            {frameworkAnalysis && frameworkAnalysis.length > 0 &&
                                                <Button
                                                    onClick={handleUpdate}
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={aar?.isPublished}
                                                >
                                                    Update
                                                </Button>
                                            }
                                        </Stack>
                                    </Grid>

                                {frameworkAnalysis.map((analysis, index) => (
                                    <React.Fragment key={index}>
                                        <Grid item xs={12}>
                                            {isEditMode ? (
                                                <TextField
                                                    multiline
                                                    fullWidth
                                                    value={analysis}
                                                    onChange={(event) => {
                                                        const newAnalysis = [...frameworkAnalysis];
                                                        newAnalysis[index] = event.target.value;
                                                        setFrameworkAnalysis(newAnalysis);
                                                        setAAR(prevState => ({
                                                            ...prevState,
                                                            recommendations: newAnalysis
                                                        }));
                                                    }}
                                                />
                                            ) : (
                                                <ReactMarkdown children={analysis} />
                                            )}
                                        </Grid>
                                    </React.Fragment>
                                ))}
                                </Grid>
                    </Grid>
                </>
            }
        </Grid>
    );
};

export default RecommendationAnalysisComponent;