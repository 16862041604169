import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';

// material-ui
import {
    Button, ButtonGroup, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle,
    Grid, lighten, Paper, Stack, TextField, Tooltip, Typography,
} from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import {Company, Organization, UserLinkKey} from "../../interfaces/interfaces";
import CompanyTableView from "../../components/company/TabCompanies";
import {PlusOutlined} from "@ant-design/icons";
import {useTheme} from "@mui/material/styles";
import {BusinessOutlined, ManageAccountsOutlined, TipsAndUpdatesOutlined} from "@mui/icons-material";
import TipsDialog from "../../components/dialog/TipsDialog";
import HoverSocialCard from "../../components/cards/statistics/HoverSocialCard";
import QuickLoaderComponent from "../../components/cards/QuickLoaderComponent";
import ManageAccessDialog from "../../components/dialog/ManageAccessDialog";
import CompanyIndustryList from "../../components/shared/CompanyIndustryList";

const DashboardAnalytics = () => {
    const token = localStorage.getItem('token');
    const [companies, setCompanies] = useState<Company[]>([]);
    const [organization, setOrganization] = useState<Organization>(); // [1
    const [openTips, setOpenTips] = useState(false);
    const [openCompanyDialog, setOpenCompanyDialog] = useState(false);
    const [companyNameValue, setCompanyNameValue] = useState("");
    const [companyDomainValue, setCompanyDomainValue] = useState("");
    const [companyDescriptionValue, setCompanyDescriptionValue] = useState("");
    const [openAccessDialog, setOpenAccessDialog] = useState(false);
    const [userList, setUserList] = useState<UserLinkKey[]>([]);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [loaderBanner, setLoaderBanner] = useState<string>("Loading Threat Profile...");
    const [companyIndustryValue, setCompanyIndustryValue] = useState<string>("");

    const handleAddUser = (newUser: UserLinkKey) => setUserList([...userList, newUser]);

    function handleManageAccess() {
        setOpenAccessDialog(true);
        fetchUsers();
    }
    function handleAccessChange() {

        try {
            setLoading(true);
            setLoaderBanner("Updating User Access...");
            fetch(process.env.REACT_APP_REST_API_URL + '/api/tabletop/access/setdesigneruser/' + token + '/' + organization?.id, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userList),
            }).then(response => {
                if (response.ok) {
                    setOpenAccessDialog(false);
                }
            });
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
        setOpenAccessDialog(false);
    }

    function closeAccessDialog() {
        setOpenAccessDialog(false);
    }
    const handleRemoveUser = (user: UserLinkKey) => {
        setUserList(userList.filter(u => u.userEmail !== user.userEmail));
        try {
            setLoading(true);
            setLoaderBanner("Removing User Access...");
            fetch(process.env.REACT_APP_REST_API_URL + '/api/tabletop/access/removedesigneruser/' + token + '/' + organization?.id, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(user),
            });
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    };

    async function fetchUsers() {
        try {
            setLoading(true);
            setLoaderBanner("Loading Users...");
            const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/tabletop/access/getdesignerusers/' + token + '/' + organization?.id);
            const data = await response.json();
            setUserList(data);
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    }
    const theme = useTheme();

    const lighterPrimaryColor = lighten(theme.palette.primary.main, 0.2);


    const fetchCompanies = async () => {
        try {
            setLoading(true);
            setLoaderBanner("Loading Companies...");
            const response = await fetch(process.env.REACT_APP_REST_API_URL+'/api/organization/company/getcompanies/'+token);
            const data = await response.json();
            setCompanies(data);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
    const fetchData = async () => {
        fetchCompanies();
        try {
            const response = await fetch(process.env.REACT_APP_REST_API_URL+'/api/organization/getorganization/'+token);
            const data = await response.json();
            setOrganization(data);
        } catch (error) {
            console.error(error);
        }

    };
    fetchData();
  }, []);


    function handleTips() {
        setOpenTips(true);
    }

    function closeTips() {
        setOpenTips(false);
    }

    const handleSaveCompany = async () => {
        let updatedCompany: Company = {} as Company;
        setShowCreateCompanyLoading(true);
        updatedCompany.name = companyNameValue;
        updatedCompany.domain = companyDomainValue;
        updatedCompany.description = companyDescriptionValue;
        updatedCompany.industry = companyIndustryValue;
        //console.log(updatedCompany?.name);
        const updateURL = `${process.env.REACT_APP_REST_API_URL}/api/tabletop/company/addcompany/${token}`;
        try {
            const response = await fetch(updateURL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(updatedCompany),
            });
            const data = await response.json();
            setCompanies(prevCompanies => [...prevCompanies, data]);
            setOpenCompanyDialog(false);
            setCompanyDomainValue("");
            setCompanyDescriptionValue("");
            setCompanyNameValue("");
            setCompanyIndustryValue("");
        } catch (error) {
            console.error("Error updating scenario:", error);
        }
        setShowCreateCompanyLoading(false);
    };
    const handleNewCompanyDialog = () => {
        setOpenCompanyDialog(true);
    }
    const handleCloseCompanyDialog = () => {
        setOpenCompanyDialog(false);
        setLoading(true);
        setLoaderBanner("Loading Companies...");
        fetchCompanies();
        setLoading(false);
    }

    const [openArchivedDialog, setOpenArchivedDialog] = React.useState(false);

    const handleOpenArchivedDialog = () => {
        setOpenArchivedDialog(true);
    };

    const handleCloseArchivedDialog = () => {
        setOpenArchivedDialog(false);
    };
    const activeCompanies = companies.filter(company => company.status !== "Archived");
    const archivedCompanies = companies.filter(company => company.status === "Archived");

    function handleIndustryChange() {
        setCompanyIndustryValue(companyIndustryValue);
    }

    const [showCreateCompanyLoading, setShowCreateCompanyLoading] = useState(false);
    return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
          <MainCard sx={{ height: 1, '& .MuiCardContent-root': { height: 1, display: 'flex', flexDirection: 'column' } }}>
              <Grid container={true} spacing={2} >
                  <Grid item xs={6} >
                      <Stack direction="row" justifyContent="flex-start" spacing={1}>
                          <BusinessOutlined />
                        <Typography variant={"h4"}>{organization?.name}: Company List</Typography>
                        </Stack>
                  </Grid>
                  <Grid item xs={6} >
                      <Stack direction="row" justifyContent="flex-end" spacing={1}>
                          {organization && organization.isDemo ?
                                  (
                                      <Button
                                          onClick={handleTips}
                                          variant="contained"
                                          color="success"
                                          size="small"
                                          startIcon={<TipsAndUpdatesOutlined />}
                                      >
                                          Tips
                                      </Button>
                                  )
                                  :
                                  <ButtonGroup>
                                      <Tooltip title="Manage Access to Opsbook Designer">
                                      <Button variant="outlined" onClick={handleManageAccess}><ManageAccountsOutlined /></Button>
                                      </Tooltip>
                                      <Tooltip title="Add a New Company">
                                      <Button variant="outlined" startIcon={<PlusOutlined />} onClick={handleNewCompanyDialog}>
                                          Company
                                      </Button>
                                      </Tooltip>
                                  </ButtonGroup>
                               }
                        </Stack>
                    </Grid>
              </Grid>
              <Grid container={true} spacing={2} >
                <Grid item xs={12} >
                  <MainCard sx={{ height: 1, '& .MuiCardContent-root': { height: 1, display: 'flex', flexDirection: 'column' } }} >
                          {isLoading &&
                              <Grid container={true} spacing={1}>
                                <Grid item xs={12}>
                                    <QuickLoaderComponent loadBanner={loaderBanner} />
                                </Grid>
                              </Grid>
                            }
                        <Grid item xs={12}>
              <Grid container={true} spacing={2}>
                  <Grid item xs={12}>
                    <CompanyTableView token={token} companies={activeCompanies}/>
                  </Grid>
                  <Grid item xs={12}>
                      <Stack direction="row" justifyContent="end" alignItems="flex-end">
                          <Button variant="text" color="primary" onClick={handleOpenArchivedDialog}>
                              View Archived
                          </Button>
                      </Stack>
                  </Grid>
              </Grid>
          </Grid>
                 </MainCard>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TipsDialog tipsText={["This is your primary dashboard to track companies that you manage or work with.", "Start by clicking the 'View' button and drilling into the ACME Company."]} open={openTips} handleClose={closeTips}/>
                </Grid>
              </Grid>
            </MainCard>
      </Grid>
             <Grid item md={8} sx={{ display: { sm: 'none', md: 'block', lg: 'none' } }} />
        {organization && organization.isDemo &&
            <Grid item xs={12}>
              <Paper variant="outlined" elevation={3}>
                <MainCard title="About Opsbook " content={false} >
                <Grid container={true} spacing={2}>

                    <Grid item xs={12}>
                      {organization && organization.isDemo &&
                          <Card sx={{ minWidth: 275 }}>
                              <CardContent>
                                  <HoverSocialCard primary={"To help you understand the power of the platform, we've pre-loaded your environment with a sample Company (ACME Company)."+
                                      "As you explore ACME - feel free to Generate a Threat Profile, Scenarios and Runbooks. Dive into the Risks, Scenario Plans and Runbooks to see how you might use Opsbook to run "+
                                      "simulated & live tabletops to proactively test Scenarios.  View/Add/Edit Runbooks to help provide your Organization with the process and procedure to handle "+
                                      "issues if they come up."} secondary={""} color={theme.palette.primary.dark} />
                              </CardContent>
                          </Card>
                      }
                  </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                        <Typography variant={"body1"}>
                            Please contact us at <Link to="mailto:support@opsbook.ai" style={{ color: lighterPrimaryColor }}>support@opsbook.ai</Link> with any questions or comments.
                        </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                </Grid>
            </MainCard>
              </Paper>
             </Grid>
        }

              <Dialog open={openCompanyDialog} fullWidth={true} onClose={handleCloseCompanyDialog}>
                  <DialogTitle>Add a New Company</DialogTitle>
                  <DialogContent>
                      <Grid container spacing={4}>
                          {showCreateCompanyLoading &&
                              <Grid item xs={12}>
                                  <QuickLoaderComponent loadBanner={"Creating company...."} />
                            </Grid>
                          }
                          <Grid item xs={12}>
                              <Typography variant="h6">Add a new company to your organization. Please note that depending on your license, you may be billed for a adding a new company.</Typography>
                          </Grid>
                          <Grid item xs={12}>
                              <Typography variant="h6">   Once added, you can set additional information about the company, manage exercises and create specific scenarios for the company. </Typography>
                          </Grid>
                          <Grid item xs={12}>
                              <TextField
                                  name="companyNameValue"
                                  placeholder="Company Name"
                                  fullWidth={true}
                                  variant="outlined"
                                  value={companyNameValue}
                                  onChange={(e) => setCompanyNameValue(e.target.value)}
                              />
                          </Grid>
                          <Grid item xs={12}>
                              <TextField
                                  name="companyDomainValue"
                                  placeholder="Domain/Website (i.e. acmecorp.com)"
                                  fullWidth={true}
                                  variant="outlined"
                                  value={companyDomainValue}
                                  onChange={(e) => setCompanyDomainValue(e.target.value)}
                              />
                          </Grid>
                          <Grid item xs={12}>
                              <CompanyIndustryList onIndustryChange={handleIndustryChange}/>
                          </Grid>
                          <Grid item xs={12}>
                              <TextField
                                  name="companyDescriptionValue"
                                  placeholder="Brief company description"
                                  fullWidth={true}
                                  multiline={true}
                                  rows={3}
                                  variant="outlined"
                                  value={companyDescriptionValue}
                                  onChange={(e) => setCompanyDescriptionValue(e.target.value)}
                              />
                          </Grid>
                      </Grid>
                  </DialogContent>
                  <DialogActions>
                      <Button variant={"contained"} onClick={handleSaveCompany} color="primary">Save</Button>
                      <Button variant={"contained"} onClick={handleCloseCompanyDialog}>Cancel</Button>
                  </DialogActions>
              </Dialog>

                  <Dialog open={openArchivedDialog} fullWidth={true} onClose={handleCloseArchivedDialog}>
                      <DialogTitle>Archived Companies</DialogTitle>
                      <DialogContent>
                          <CompanyTableView token={token} companies={archivedCompanies} />
                      </DialogContent>
                      <DialogActions>
                          <Button onClick={handleCloseArchivedDialog} color="primary">
                              Close
                          </Button>
                      </DialogActions>
                  </Dialog>

                    <ManageAccessDialog
                        open={openAccessDialog}
                        onClose={closeAccessDialog}
                        userList={userList}
                        onRemoveUser={handleRemoveUser}
                        onAccessChange={handleAccessChange}
                        onAddUser={handleAddUser}
                    />
    </Grid>
  );
};

export default DashboardAnalytics;
