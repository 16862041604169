import React from 'react';
import {
    Button,
     Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    Select,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography
} from '@mui/material';
import {AppUserProfile, Conduct, Participant, UserLinkKey} from "../../interfaces/interfaces";
import SelectRoleType from "../utils/SelectRoleType";
import ConductParticipantList from "../tabletop/ConductParticipantList";
import GroupSelect from "../scenario/GroupSelect";

interface ScenarioParticipantAddDialogProps {
    open: boolean;
    userProfile: AppUserProfile | null | undefined;
    onClose: () => void;
    userLinks?: UserLinkKey[];
    participants?: Participant[] | null;
    onRemove: (participantId: string) => void;
    onAdd: (name: string, email: string, role: string, groups: string[]) => void;
    onNotify?: (participantId: string) => void;
    onAttended?: (participantId: string) => void;
    conduct?: Conduct;
}

const ScenarioParticipantAddDialog: React.FC<ScenarioParticipantAddDialogProps> = ({
    open,
    onClose,
    userLinks,
    participants,
    onAdd,
    onNotify,
    onRemove,
    conduct,
    userProfile,
    onAttended
}) => {
    const [participantName, setParticipantName] = React.useState('');
    const [participantEmail, setParticipantEmail] = React.useState('');
    const [participantRole, setParticipantRole] = React.useState('');
    const [participantGroups, setParticipantGroups] = React.useState<string[]>([]);
    const [activeTab, setActiveTab] = React.useState(0);
    const enableAdd = participantName && activeTab === 1;
    function handleDelete(participant: Participant) {
        if (!participant) return;
        if (participant.participantId) {
            onRemove(participant.participantId);
        }
    }

    function handleAdd() {
        console.log('Adding participant->', participantName, participantEmail, participantRole, participantGroups);
        if (onAdd) {
            onAdd(participantName, participantEmail, participantRole, participantGroups);
        } else {
            console.error('onAdd function is not defined');
        }
        setParticipantName('');
        setParticipantEmail('');
        setParticipantRole('');
        setParticipantGroups([]);
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    return (
        <Dialog open={open} fullWidth={true} onClose={onClose}>
            <DialogTitle>Manage Participants</DialogTitle>
            <DialogContent>
                <Tabs value={activeTab} onChange={handleTabChange} aria-label="participant tabs">
                    <Tab label="Current Participants" />
                    <Tab label="Add Participant" />
                </Tabs>
                {activeTab === 0 && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}><Typography variant={"h4"}>Current Participants ({participants ? participants.length : 0})</Typography></Grid>
                        <Grid item xs={12}>
                            {participants && participants.length > 0 && userProfile ?
                                <ConductParticipantList userProfile={userProfile} participants={participants} onTeamDelete={handleDelete} groups={conduct?.groups}/>
                                : <Chip size={"small"} label="No Participants" color="warning" />}
                        </Grid>
                    </Grid>
                )}
                {activeTab === 1 && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}></Grid>

                        <Grid item xs={12}><Typography variant={"h4"}>Add Participant</Typography></Grid>
                        {userLinks && userLinks.length > 0 && (
                            <>
                                <Grid item xs={12}>
                                    <Typography variant="caption">Option 1: Add a current Portal user to the tabletop</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Select
                                        fullWidth={true}
                                        value={userLinks.find(participant => participant.username === participantName)?.userId || ''}
                                        onChange={(event) => {
                                            const selectedParticipant = userLinks.find(participant => participant.userId === event.target.value);
                                            if (selectedParticipant) {
                                                setParticipantName(selectedParticipant.username);
                                                setParticipantEmail(selectedParticipant.userEmail);
                                            }
                                        }}
                                    >
                                        {userLinks && userLinks.map((participant) => (
                                            <MenuItem key={participant.userId} value={participant.userId}>
                                                {participant.username} ({participant.userEmail})
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12}>
                            <Typography variant="caption">
                                {userLinks && userLinks.length > 0 ? "Option 2:  Enter a non-user participant" : "Enter a participant for this tabletop"}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                autoFocus
                                required={true}
                                margin="dense"
                                id="name"
                                label="Name"
                                value={participantName}
                                fullWidth
                                onChange={(e) => setParticipantName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                margin="dense"
                                id="email"
                                label="Email"
                                type="email"
                                value={participantEmail}
                                fullWidth
                                onChange={(e) => setParticipantEmail(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SelectRoleType
                                value={participantRole}
                                onChange={(e) => setParticipantRole(e)}
                            />  
                        </Grid>
                        {conduct && conduct.groups && conduct.groups.length > 0 &&
                            <Grid item xs={12}>
                                <GroupSelect
                                    groups={conduct ? conduct.groups : []}
                                    linkToId={participantEmail}
                                    onLinkGroup={(groupId: string, scriptId: string) => {
                                        //add groupId to participantGroups if it doesn't exist already
                                        if (!participantGroups.includes(groupId)) {
                                            setParticipantGroups([...participantGroups, groupId]);
                                        }
                                    }}
                                />
                            </Grid>
                        }
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                            <Stack direction={"row"} spacing={1} justifyContent={"flex-end"}>
                                <Button variant="contained" disabled={!enableAdd} onClick={handleAdd}>Add</Button>
                                <Button variant="contained" onClick={onClose}>Close</Button>
                            </Stack>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default ScenarioParticipantAddDialog;