import React from 'react';
import ReactMarkdown from 'react-markdown';
import MainCard from "../MainCard";

interface AARSummaryComponentProps {
    companyId: string;
    scenarioId: string;
    conductId: string;
    token: string;
    aarSummary?: string;
}

const AARSummaryComponent: React.FC<AARSummaryComponentProps> = ({ companyId, scenarioId, conductId, token, aarSummary }) => {

    return (
        <MainCard title="Summary">
        <div style={{ maxHeight: '75em', overflowY: 'auto' }}>
            {aarSummary &&
                <div style={{maxHeight: '75em', overflowY: 'auto'}}>
                    {aarSummary && aarSummary.split('\n').map((line, i) => (
                        <ReactMarkdown key={i} children={line}/>
                    ))}
                </div>
            }
        </div>
        </MainCard>
    );
};

export default AARSummaryComponent;