import React, { useState, useEffect } from 'react';
import {
    Button,
    TextField,
    List,
    ListItem,
    ListItemText,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    CardContent, Checkbox, Chip, LinearProgress, Typography, Box, Tooltip
} from '@mui/material';
import { AddCircleOutline, Edit, Delete, CheckCircle } from '@mui/icons-material';
import { ScenarioObjective } from '../../interfaces/interfaces';
import MainCard from "../MainCard";

interface ObjectiveListProps {
    objectives: ScenarioObjective[];
    id: string;
    title: string;
    onUpdate?: (id: string, objectives: ScenarioObjective[]) => void;
    showAdd: boolean;
    showEdit: boolean;
    showDelete: boolean;
    showComplete: boolean;
}

const ObjectiveList: React.FC<ObjectiveListProps> = ({ showAdd, showDelete, showEdit, showComplete, title, id, objectives: initialObjectives = [], onUpdate }) => {
    const [open, setOpen] = useState(false);
    const [currentObjective, setCurrentObjective] = useState<ScenarioObjective | null>(null);
    const [objectiveText, setObjectiveText] = useState('');
    const [objectives, setObjectives] = useState<ScenarioObjective[]>(initialObjectives);
    const [completionPercentage, setCompletionPercentage] = useState(0);

    useEffect(() => {
        setObjectives(initialObjectives);
    }, [initialObjectives]);

    useEffect(() => {
        const totalObjectives = objectives?.length || 0;
        const completedObjectives = objectives?.filter(obj => obj?.isMet)?.length || 0;
        const percentage = totalObjectives > 0 ? (completedObjectives / totalObjectives) * 100 : 0;
        setCompletionPercentage(percentage);
    }, [objectives]);

    const handleOpen = (objective: ScenarioObjective | null) => {
        setCurrentObjective(objective);
        setObjectiveText(objective ? objective.objective : '');
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentObjective(null);
        setObjectiveText('');
    };

    const handleSave = () => {
        if (!objectives) {
            setObjectives([]);
        }

        if (currentObjective) {
            // Edit existing objective
            const updatedObjectives = objectives.map(obj =>
                obj === currentObjective ? { ...obj, objective: objectiveText } : obj
            );
            setObjectives(updatedObjectives);
            if (onUpdate) {
                onUpdate(id, updatedObjectives);
            }
        } else {
            // Add new objective
            const newObjective: ScenarioObjective = {
                objective: objectiveText,
                isMet: false,
                notes: ''
            };
            const updatedObjectives = [...(objectives || []), newObjective];
            setObjectives(updatedObjectives);
            if (onUpdate) {
                onUpdate(id, updatedObjectives);
            }
        }
        handleClose();
    };

    const handleDelete = (objective: ScenarioObjective) => {
        const updatedObjectives = objectives.filter(obj => obj !== objective);
        setObjectives(updatedObjectives);
        if (onUpdate) {
            onUpdate(id, updatedObjectives);
        }
    };

    const handleToggleComplete = (objective: ScenarioObjective) => {
        const updatedObjectives = objectives.map(obj =>
            obj === objective ? { ...obj, isMet: !obj.isMet } : obj
        );
        setObjectives(updatedObjectives);
        if (onUpdate) {
            onUpdate(id, updatedObjectives);
        }
    };

    return (
        <div>
            <MainCard title={title} secondary={
                <>
                    {showAdd &&
                        <Tooltip title="Add Objective">
                        <Button variant="outlined" startIcon={<AddCircleOutline />} onClick={() => handleOpen(null)}>
                            Objective
                        </Button>
                        </Tooltip>
                    }
                </>
            }>
                <CardContent>
                    {showComplete &&
                    <Box display="flex" alignItems="center" mb={2}>
                        <Box width="100%" mr={1}>
                            <LinearProgress variant="determinate" value={completionPercentage} />
                        </Box>
                        <Box minWidth={35}>
                            <Typography variant="body2" color="textSecondary">{`${Math.round(completionPercentage)}%`}</Typography>
                        </Box>
                        {completionPercentage === 100 && <CheckCircle style={{ color: 'green' }} />}
                    </Box>
                    }
                    <List>
                        {!objectives || objectives.length === 0 && <ListItem><ListItemText primary="No objectives" /></ListItem>}
                        {objectives && objectives.map((objective, index) => (
                            <ListItem key={index}>
                                {showComplete &&
                                    <Checkbox onChange={() => handleToggleComplete(objective)} checked={objective.isMet} />
                                }
                                <ListItemText primary={`${index + 1}. ${objective.objective}`} />
                                {showEdit &&
                                    <IconButton onClick={() => handleOpen(objective)}>
                                        <Edit />
                                    </IconButton>
                                }
                                {showDelete &&
                                    <IconButton onClick={() => handleDelete(objective)}>
                                        <Delete />
                                    </IconButton>
                                }
                                {objective.isMet && <Chip size={"small"} color={"success"} label={"Completed"} />}
                            </ListItem>
                        ))}
                    </List>
                </CardContent>
            </MainCard>
            <Dialog open={open} onClose={handleClose} fullWidth={true}>
                <DialogTitle>{currentObjective ? 'Edit Objective' : 'Add Objective'}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Objective"
                        fullWidth
                        value={objectiveText}
                        onChange={(e) => setObjectiveText(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ObjectiveList;