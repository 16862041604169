import React, { useState, useEffect } from 'react';
import { TextField, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';

interface EditableAnswerProps {
  answer: string;
  onSave: (newAnswer: string) => void;
}

const EditableAnswer: React.FC<EditableAnswerProps> = ({ answer, onSave }) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editedAnswer, setEditedAnswer] = useState<string>(answer);

  useEffect(() => {
    setEditedAnswer(answer);
  }, [answer]);

  const handleSave = () => {
    setIsEditing(false);
    onSave(editedAnswer);
  };

  return (
    <>
      {isEditing ? (
        <TextField
          fullWidth
          multiline
          value={editedAnswer}
          onChange={(e) => setEditedAnswer(e.target.value)}
          onBlur={handleSave}
          autoFocus
        />
      ) : (
        <Typography onClick={() => setIsEditing(true)}>
          <ReactMarkdown>{answer}</ReactMarkdown>
        </Typography>
      )}
    </>
  );
};

export default EditableAnswer;