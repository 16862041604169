import { FC } from 'react';
import {Button, List, ListItem, ListItemText, ListItemSecondaryAction, Stack, Grid, Tooltip} from '@mui/material';
import { ScriptResource } from '../../interfaces/interfaces';
import {DeleteForeverOutlined} from "@mui/icons-material";

interface ResourceListProps {
  linkedResources: string[];
  resources: ScriptResource[];
  scriptId: string;
  onUnlinkResource?: (resourceId: string, scriptId: string) => void;
}

const ResourceList: FC<ResourceListProps> = ({ linkedResources, resources, scriptId, onUnlinkResource }) => {
  return (
    <List>
      {linkedResources && linkedResources.map((resourceId) => {
        const resource = resources.find((res) => res.resourceId === resourceId);
        return (
          <ListItem key={resourceId}>
            <Grid container spacing={2}>
              <Grid item xs={10}>
            <ListItemText
                primary={resource?.resourceShortName}
                secondary={resource?.resourceName ? resource.resourceName + " (" + resource.resourceType + ")" : ""}
            />
            </Grid>
            <Grid item xs={2}>
            {onUnlinkResource && (
              <ListItemSecondaryAction>
                <Stack spacing={2} direction="row" justifyContent={"flex-end"}>
                  <Tooltip title={"Unlink Resource"}>
                    <Button onClick={() => onUnlinkResource(resourceId, scriptId || '')}><DeleteForeverOutlined/></Button>
                  </Tooltip>
                </Stack>
              </ListItemSecondaryAction>
            )}
            </Grid>
            </Grid>
          </ListItem>
        );
      })}
    </List>
  );
};

export default ResourceList;