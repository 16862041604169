import React, { useEffect, useState } from 'react';
import { TextField, Autocomplete, CircularProgress } from '@mui/material';
import { TTXCategory } from '../../interfaces/interfaces';

interface TTXCategoryComboBoxProps {
  onSave?: (value: string) => void;
  value?: string;
}

const TTXCategoryComboBox: React.FC<TTXCategoryComboBoxProps> = ({ onSave, value = '' }) => {
  const [categories, setCategories] = useState<TTXCategory[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(value || '');
  const [selectedCategory, setSelectedCategory] = useState<TTXCategory | null>(null);

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${process.env.REACT_APP_REST_API_URL}/api/scenario/getmasterttxcategories/${token}`);
        const data = await response.json();
        setCategories(data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const handleChange = (event: any, newValue: TTXCategory | string | null) => {
    if (typeof newValue === 'string') {
      setSelectedCategory({ id: 'aaa', name: newValue });
      if (onSave) onSave(newValue);
    } else {
      setSelectedCategory(newValue);
      if (onSave && newValue) onSave(newValue.name);
    }
  };

  return (
    <Autocomplete
      options={categories}
      getOptionLabel={(option) => typeof option === 'string' ? option : option.name}
      value={selectedCategory}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select or type a category"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default TTXCategoryComboBox;