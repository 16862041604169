import {
    Button, ButtonGroup,
    Card,
    CardContent, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle,
    Grid,
    List,
    ListItem, Stack,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {AAR, AppUserProfile, Conduct, Participant} from "../../interfaces/interfaces";
import QuickLoaderComponent from "../cards/QuickLoaderComponent";
import ConductParticipantList from "../tabletop/ConductParticipantList";
import ReactMarkdown from "react-markdown";
import SelectRoleType from "../utils/SelectRoleType";
import ColorTextBox from "../icon/ColorTextBox";
import {PlusCircleFilled} from "@ant-design/icons";
import { ClearRounded} from "@mui/icons-material";
import {getPlaceholderParticipant} from "../functions/interfacePlaceholders";

interface AARComponentProps {
    aar: AAR;
    conduct: Conduct;
    editable: boolean;
    companyId: string | undefined;
    scenarioId: string | undefined;
    conductId: string | undefined;
    portalId?: string | undefined;
    isPortal?: boolean;
    onUpdate?: () => void;
    userProfile: AppUserProfile;
}
const AARComponent : React.FC<AARComponentProps> = ({ aar, editable, scenarioId, portalId, onUpdate,
                                                        conduct, conductId, companyId, isPortal, userProfile }) => {
    const [updatedAAR, setAAR] = useState<AAR>(aar);
    // const [attachmentsSupportingDocuments, setAttachmentsSupportingDocuments] = useState("");
    // const [approvalAndSignOff, setApprovalAndSignOff] = useState("");
    const [isLoading, setLoading] = useState<boolean>(false);
    const [bannerText, setBannerText] = useState<string>("Loading...");
    const token = localStorage.getItem('token');
    const [isEditable, setIsEditable] = useState<boolean>(editable);
    const [participantsEdited, setParticipantsEdited] = useState<boolean>(false);
    const [updatedConduct, setUpdatedConduct] = useState<Conduct>(conduct);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [attributeToClear, setAttributeToClear] = useState<string>("");


    const handleOpenConfirmDialog = (attribute: string) => {
        setAttributeToClear(attribute);
        setOpenConfirmDialog(true);
    };

    const handleConfirmClear = () => {
        setAAR(prevState => {
            return { ...prevState, [attributeToClear]: [] };
        });
        setOpenConfirmDialog(false);
    };

    const handleObjectiveChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        setAAR(prevState => {
            const newObjectives = [...prevState.objectives];
            newObjectives[index] = e.target.value;
            return { ...prevState, objectives: newObjectives };
        });
    };

    const handleActionItemChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        setAAR(prevState => {
            const newObjectives = [...prevState.actionItems];
            newObjectives[index] = e.target.value;
            return { ...prevState, actionItems: newObjectives };
        });
    };

    const handleAddObjective = () => {
        setAAR(prevState => {
            const newRecommendations = prevState.objectives
                ? [...prevState.objectives, ""]
                : [""];
            return { ...prevState, objectives: newRecommendations };
        });
    };

    const handleAddActionItem = () => {
        setAAR(prevState => {
            const newRecommendations = prevState.actionItems
                ? [...prevState.actionItems, ""]
                : [""];
            return { ...prevState, actionItems: newRecommendations };
        });
    };
    // const handleActionChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    //     setAAR(prevState => {
    //         const newMessages = [...prevState.messages];
    //         newMessages[index].answer = e.target.value;
    //         return { ...prevState, messages: newMessages };
    //     });
    // };
    //
    // const handleAddAction = () => {
    //     const newMessage: GameMessage = {
    //         chatId: "", files: [], gameQuestion: "",
    //         id: '',
    //         conductScriptItem: undefined,
    //         answer: '',
    //         flag: '',
    //         isPrivate: false,
    //         isVisible: true,
    //         isEdited: false,
    //         assistantAnalysis: undefined
    //     };
    //
    //     setAAR(prevState => {
    //         const newMessages = prevState.messages ? [...prevState.messages, newMessage] : [newMessage];
    //         return { ...prevState, messages: newMessages };
    //     });
    // };
    const handleLessonLearnedChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        setAAR(prevState => {
            const newObjectives = [...prevState.lessonsLearned];
            newObjectives[index] = e.target.value;
            return { ...prevState, lessonsLearned: newObjectives };
        });
    };

    const handleAddLessonLearned = () => {
        setAAR(prevState => {
            const newRecommendations = prevState.lessonsLearned
                ? [...prevState.lessonsLearned, ""]
                : [""];
            return { ...prevState, lessonsLearned: newRecommendations };
        });
    };
    const handleRecommendationChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        setAAR(prevState => {
            const newObjectives = [...prevState.recommendations];
            newObjectives[index] = e.target.value;
            return { ...prevState, recommendations: newObjectives };
        });
    };

    const handleAddRecommendation = () => {
        setAAR(prevState => {
            const newRecommendations = prevState.recommendations
                ? [...prevState.recommendations, ""]
                : [""];
            return { ...prevState, recommendations: newRecommendations };
        });
    };
    const handleWWWChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        setAAR(prevState => {
            const newObjectives = [...prevState.whatWentWell];
            newObjectives[index] = e.target.value;
            return { ...prevState, whatWentWell: newObjectives };
        });
    };

    const handleAddWWW = () => {
        setAAR(prevState => {
            const newRecommendations = prevState.whatWentWell
                ? [...prevState.whatWentWell, ""]
                : [""];
            return { ...prevState, whatWentWell: newRecommendations };
        });
    };
    const handleChallengeChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        setAAR(prevState => {
            const newObjectives = [...prevState.challenges];
            newObjectives[index] = e.target.value;
            return { ...prevState, challenges: newObjectives };
        });
    };

    const handleAddAreaForImprovement = () => {
        setAAR(prevState => {
            const newRecommendations = prevState.areasForImprovement
                ? [...prevState.areasForImprovement, ""]
                : [""];
            return { ...prevState, areasForImprovement: newRecommendations };
        });
    };
    const handleAddChallenge = () => {
        setAAR(prevState => {
            const newRecommendations = prevState.challenges
                ? [...prevState.challenges, ""]
                : [""];
            return { ...prevState, challenges: newRecommendations };
        });
    };

    useEffect(() => {
        // Update prevAAR state with the new aar
        setAAR(aar);
        //console.log("updated aar!")
    }, [aar]);

    const handleUpdate = async () => {
        setBannerText("Updating After-Action Review...");
        setLoading(true);
        const updateURL = isPortal
            ? `${process.env.REACT_APP_REST_API_URL}/api/portal/conduct/aar/updateaar/${token}/${companyId}/${portalId}/${scenarioId}/${conductId}`
        : `${process.env.REACT_APP_REST_API_URL}/api/tabletop/company/scenario/tabletop/aar/updateaar/${token}/${companyId}/${scenarioId}/${conductId}`;
    try {
            const response = await fetch(updateURL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(updatedAAR),
            });
            const data = await response.json();
            setAAR(data);
        } catch (error) {
            console.error("Error updating scenario:", error);
        }

        //update the conduct only participants have changed

        try {
            const response = await fetch(updateURL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(updatedAAR),
            });
            const data = await response.json();
            setAAR(data);
        } catch (error) {
            console.error("Error updating scenario:", error);
        }

        if (participantsEdited) {
            const updateConductURL = isPortal
                ? `${process.env.REACT_APP_REST_API_URL}/api/portal/company/scenario/tabletop/updateconduct/${token}/${companyId}/${portalId}/${scenarioId}/${conductId}`
                : `${process.env.REACT_APP_REST_API_URL}/api/tabletop/ccompany/scenario/tabletop/updateconduct/${token}/${companyId}/${scenarioId}/${conductId}`;

            try {
                const response = await fetch(updateConductURL, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(updatedConduct),
                });
                const data = await response.json();
                setUpdatedConduct(data);

            } catch (error) {
                console.error("Error updating scenario:", error);
            }
        }
        setLoading(false);
        if (onUpdate) {
            onUpdate();
        }
    };

    // const clearStringArray = (attribute: string ) => {
    //     setAAR(prevState => {
    //         return { ...prevState, [attribute]: [] };
    //     });
    // };

    const [openDialog, setOpenDialog] = useState(false);

    const defaultParticipant = getPlaceholderParticipant();
    const [newParticipant, setNewParticipant] = useState<Participant>(defaultParticipant);
    const handleDialog = () => {
        setOpenDialog(!openDialog);
    };

    const setParticipantRole = (e: string) => {
        setNewParticipant({ ...newParticipant, role: e });
    }
    const handleNewParticipantChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        newParticipant.attended = true;
        setNewParticipant({
            ...newParticipant,
            [e.target.name]: e.target.value
        });
    };
    const handleAddParticipant = () => {
        if (updatedConduct) {
            let newParticipants = updatedConduct.participants ? [...updatedConduct.participants, newParticipant] : [newParticipant];
            updatedConduct.participants = newParticipants;
            setParticipantsEdited(true);
        }
        handleDialog();
    };

    const handleToggleEditable = () => {
        setIsEditable(!isEditable);
    };


    return (

<Grid container spacing={2}>
    {userProfile && userProfile.role != 'PortalViewParticipant' &&
    <Grid item xs={12}>
        <Stack direction="row" justifyContent="center" spacing={2}>
            <ButtonGroup variant="contained" size={"small"}>
                <Button onClick={handleToggleEditable} variant={isEditable ? "contained" : "outlined"}>
                    Edit
                </Button>
                <Button onClick={handleToggleEditable} variant={!isEditable ? "contained" : "outlined"}>
                    Preview
                </Button>
            </ButtonGroup>
        </Stack>
    </Grid>
    }
    <Grid item xs={12} >
        {isLoading &&
            <QuickLoaderComponent loadBanner={bannerText} />
        }
    </Grid>

    <Grid item xs={12} >
    <Grid container spacing={2}>
        <Grid item xs={2}>
            <ColorTextBox size={200} text={"Report"}/>
        </Grid>
        <Grid item xs={10}>
            <Card>
                <CardContent >
                    <Grid container spacing={2}>
                        <Grid item xs={10}>
                            {isEditable ?
                            <TextField
                                placeholder="Title"
                                value={updatedAAR.reportTitle}
                                variant={"standard"}
                                multiline
                                InputProps={{
                                    readOnly: !isEditable,
                                }}
                                fullWidth
                                onChange={(e) => setAAR({ ...updatedAAR, reportTitle: e.target.value })}
                            /> :
                                <>
                                    {updatedAAR.reportTitle &&
                                        <ReactMarkdown key={999} children={`## ${updatedAAR.reportTitle}`} />
                                    }
                                </>
                            }
                        </Grid>
                        <Grid item xs={2}>
                            <Stack direction={"column"} justifyContent={"flex-end"} spacing={2}>

                            {updatedAAR.isPublished ?
                                (
                                    <Chip label={`Published`} size="large" color="success" />)
                                : ( <Chip label="Draft" size="large" color="warning" />
                                )
                            }
                                {updatedAAR.integrationSends && updatedAAR.integrationSends.length > 0 && (
                                    <Tooltip title={updatedAAR.integrationSends[0].auditNote}>
                                        <Chip label={"Uploaded"} size="large" color="primary" />
                                    </Tooltip>
                                )}
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction={"column"} justifyContent={"flex-start"} spacing={1}>
                                {updatedAAR.date &&
                                    <ReactMarkdown key={998} children={`#### Date of Event: ${updatedAAR.date}`} />
                                }
                                {updatedAAR?.userAudit?.createdDate &&
                                    <ReactMarkdown key={997} children={`#### Date of Report: ${updatedAAR?.userAudit?.createdDate}`} />
                                }
                                {updatedAAR?.userAudit?.createdByName &&
                                    <ReactMarkdown key={996} children={`#### Report Prepared  ${updatedAAR?.userAudit?.createdByName}`} />
                                }
                            </Stack>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
    </Grid>

    <Grid item xs={12} >
    <Grid container spacing={2}>
        <Grid item xs={2}>
            <ColorTextBox size={200} text={"Executive Summary"}/>
        </Grid>
        <Grid item xs={10}>

            <Card>
                <CardContent>
                    {isEditable ?
                    <TextField
                        placeholder="Executive Summary"
                        value={updatedAAR.executiveSummary}
                        variant={"standard"}
                        multiline
                        InputProps={{
                            readOnly: !isEditable,
                        }}
                        fullWidth
                        onChange={(e) => setAAR({ ...updatedAAR, executiveSummary: e.target.value })}
                    /> :
                        <>
                        {updatedAAR.executiveSummary && updatedAAR.executiveSummary.split('\n').map((line, i) => (
                                <ReactMarkdown key={i} children={line} />
                            ))}
                        </>
                    }
                </CardContent>
            </Card>
        </Grid>
    </Grid>
    </Grid>
    {updatedConduct && updatedConduct.participants && updatedConduct.participants.length > 0 &&
        <>

        <Grid item xs={12} >
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <ColorTextBox size={200} text={"Participants"}/>
                </Grid>
                <Grid item xs={10}>
                    <Card>
                        <CardContent>
                            <List>
                                <ConductParticipantList participants={updatedConduct.participants} groups={updatedConduct.groups}/>
                            </List>
                            <Stack direction={"row"} justifyContent={"flex-end"} spacing={2}>
                            {isEditable && <Button variant="outlined" color="primary" onClick={handleDialog}>Add Participant</Button>}
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    </>
    }

    <Dialog open={openDialog} onClose={handleDialog}>
        <DialogTitle>Add a New Participant</DialogTitle>
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        name="name"
                        label="Name"
                        fullWidth
                        value={newParticipant.name}
                        onChange={handleNewParticipantChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <SelectRoleType
                        onChange={(e) => setParticipantRole(e)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Checkbox checked={newParticipant.attended} onChange={(e) => setNewParticipant({ ...newParticipant, attended: e.target.checked })} />Attended?
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleAddParticipant} color="primary">Add</Button>
            <Button onClick={handleDialog} color="secondary">Cancel</Button>
        </DialogActions>
    </Dialog>

    <Grid item xs={12} >
    <Grid container spacing={2}>
        <Grid item xs={2}>
            <ColorTextBox size={200} text={"Objectives"}/>
        </Grid>
        <Grid item xs={10}>
            <Card>
                <CardContent>
                    <List>
                        {isEditable ?
                            <>
                                {updatedAAR.objectives && updatedAAR.objectives.map((objective, index) => (
                                    <ListItem key={index}>
                                        <TextField
                                            value={objective}
                                            variant={"standard"}
                                            fullWidth={true}
                                            multiline={true}
                                            InputProps={{
                                                readOnly: !isEditable,
                                            }}
                                            onChange={(e) => handleObjectiveChange(e, index)}
                                        />
                                    </ListItem>
                                ))}
                            </> :
                            <>
                                {updatedAAR.objectives && updatedAAR.objectives.map((line, i) => (
                                    <ReactMarkdown key={i} children={`${line}`} />
                                ))}
                            </>
                        }
                        {updatedAAR.objectives && updatedAAR.objectives.length === 0 && (
                            <Grid item xs={12}>
                            <Typography variant="caption">No Objectives Defined</Typography>
                            </Grid>
                        )}
                        {isEditable &&
                           <>
                               <Stack direction={"row"} justifyContent={"flex-end"} spacing={2}>
                               <ButtonGroup variant={"outlined"}>
                               <Button onClick={handleAddObjective} startIcon={<PlusCircleFilled/>}>Objective</Button>
                               {updatedAAR.objectives &&
                                   <Button onClick={() => handleOpenConfirmDialog("objectives")} startIcon={<ClearRounded />}>Objectives</Button>
                               }
                               </ButtonGroup>
                               </Stack>
                           </>
                        }
                    </List>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
    </Grid>

    {/*<Grid item xs={12} >*/}
    {/*<Grid container spacing={2}>*/}
    {/*    <Grid item xs={2}>*/}
    {/*        <ColorTextBox size={200} text={"Actions"}/>*/}
    {/*    </Grid>*/}
    {/*    <Grid item xs={10}>*/}

    {/*        <Card>*/}
    {/*            <CardContent >*/}
    {/*                <List >*/}
    {/*                    <List>*/}
    {/*                        {!updatedAAR.messages || updatedAAR.messages.length == 0 &&*/}
    {/*                            <Grid item xs={12}> <Chip size={"small"} label={"No Actions Defined"} color={"warning"} /></Grid>*/}
    {/*                        }*/}
    {/*                            <>*/}
    {/*                                <Grid container={true} spacing={1}>*/}
    {/*                                    {updatedAAR.messages && (() => {*/}
    {/*                                        let lastName = "";*/}

    {/*                                        return updatedAAR.messages.map((message, index) => {*/}
    {/*                                            const nameChanged = message.conductScriptItem?.name !== lastName;*/}
    {/*                                            if (nameChanged) {*/}
    {/*                                                lastName = message.conductScriptItem?.name || "";*/}
    {/*                                            }*/}
    {/*                                            return (*/}
    {/*                                                <React.Fragment key={index}>*/}
    {/*                                                {message && message.messageType!="MSG_SCRIPT_ITEM_COMPLETE" && message.messageType!="MSG_SECTION_ITEM_COMPLETE" &&*/}
    {/*                                                    <>*/}
    {/*                                                    {nameChanged && (*/}
    {/*                                                       <>*/}
    {/*                                                           <Grid item xs={12}>*/}
    {/*                                                            <Divider/>*/}
    {/*                                                        </Grid>*/}
    {/*                                                        <Grid item xs={12} key={`name-${index}`}>*/}
    {/*                                                            <Typography variant="h5">{message.conductScriptItem?.name}</Typography>*/}
    {/*                                                        </Grid>*/}
    {/*                                                           </>*/}
    {/*                                                    )}*/}
    {/*                                                    <Grid item xs={12} key={`message-${index}`}>*/}
    {/*                                                        <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={2}>*/}
    {/*                                                            <ListItem>*/}
    {/*                                                                {isEditable ?*/}
    {/*                                                                    <TextField*/}
    {/*                                                                    value={message.answer}*/}
    {/*                                                                    variant={"standard"}*/}
    {/*                                                                    fullWidth={true}*/}
    {/*                                                                    InputProps={{*/}
    {/*                                                                        readOnly: !isEditable,*/}
    {/*                                                                    }}*/}
    {/*                                                                    onChange={(e) => handleActionChange(e, index)}*/}
    {/*                                                                    />*/}
    {/*                                                                    :*/}
    {/*                                                                    <>*/}
    {/*                                                                        <Grid container={true} spacing={1}>*/}
    {/*                                                                            <Grid item xs={12}>*/}
    {/*                                                                                <Typography variant="body1">{message.answer}</Typography>*/}
    {/*                                                                            </Grid>*/}
    {/*                                                                            <Grid item xs={12}>*/}
    {/*                                                                                <Typography variant="caption" color={"secondary"}>- {message.userAudit?.createdByName}</Typography>*/}
    {/*                                                                            </Grid>*/}
    {/*                                                                        </Grid>*/}
    {/*                                                                    </>*/}
    {/*                                                                }*/}
    {/*                                                            </ListItem>*/}
    {/*                                                            {message.flag && <Chip size={"small"} label={message.flag} color={"primary"} />}*/}
    {/*                                                        </Stack>*/}
    {/*                                                    </Grid>*/}
    {/*                                                    </>*/}
    {/*                                                }*/}
    {/*                                                </React.Fragment>                                                );*/}
    {/*                                        });*/}
    {/*                                    })()}*/}

    {/*                                </Grid>*/}
    {/*                            </>*/}

    {/*                        {isEditable &&*/}
    {/*                            <>*/}
    {/*                                <Stack direction={"row"} justifyContent={"flex-end"} spacing={2}>*/}
    {/*                                    <ButtonGroup variant={"outlined"}>*/}
    {/*                                        <Button onClick={handleAddAction}>Add Action</Button>*/}
    {/*                                    </ButtonGroup>*/}
    {/*                                </Stack>*/}
    {/*                            </>*/}
    {/*                        }*/}
    {/*                    </List>*/}
    {/*                </List>*/}
    {/*            </CardContent>*/}
    {/*        </Card>*/}
    {/*    </Grid>*/}
    {/*</Grid>*/}
    {/*</Grid>*/}
    {updatedAAR.actionAnalysis &&
        <Grid item xs={12} >
    <Grid container spacing={2}>
        <Grid item xs={2}>
            <ColorTextBox size={200} text={"Action Analysis"}/>
        </Grid>
        <Grid item xs={10}>
            <Card>
                <CardContent >
                    <List >
                        <List>
                            {updatedAAR.actionAnalysis &&
                                <>
                                {isEditable ? <TextField
                                        placeholder="Action analysis"
                                        value={updatedAAR.actionAnalysis}
                                        variant={"standard"}
                                        multiline
                                        InputProps={{
                                            readOnly: !isEditable,
                                        }}
                                        fullWidth
                                        onChange={(e) => setAAR({ ...updatedAAR, actionAnalysis: e.target.value })}
                                    /> :
                                            <ReactMarkdown children={updatedAAR.actionAnalysis} />
                                    }
                                </>
                            }
                            {!updatedAAR.actionAnalysis &&
                                <Grid item xs={12}>
                                <Typography variant="caption">No Analysis</Typography>
                                </Grid>
                            }
                        </List>
                    </List>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
    </Grid>
    }

    <Grid item xs={12} >
    <Grid container spacing={2}>
        <Grid item xs={2}>
            <ColorTextBox size={200} text={"Analysis"}/>
        </Grid>
        <Grid item xs={10}>

            <Card>
                <CardContent>
                    {isEditable ?
                        <TextField
                            placeholder="Analysis"
                            variant={"standard"}
                            value={updatedAAR.analysis}
                            multiline
                            fullWidth
                            InputProps={{
                                readOnly: !isEditable,
                            }}
                            onChange={(e) => setAAR({ ...updatedAAR, analysis: e.target.value })}
                        /> :
                        <>
                            {updatedAAR.analysis && updatedAAR.analysis.split('\n').map((line, i) => (
                                <ReactMarkdown key={i} children={line} />
                            ))}
                        </>
                    }
                </CardContent>
            </Card>
        </Grid>
    </Grid>
    </Grid>

    <Grid item xs={12} >
    <Grid container spacing={2}>
        <Grid item xs={2}>
            <ColorTextBox size={200} text={"What Went Well"}/>
        </Grid>
        <Grid item xs={10}>

            <Card>
                <CardContent>
                    <List>
                        {isEditable ?
                            <>{updatedAAR.whatWentWell && updatedAAR.whatWentWell.map((wwell, index) => (
                                <ListItem key={index}>
                                    <TextField
                                    value={wwell}
                                    variant={"standard"}
                                    fullWidth={true}
                                    multiline={true}
                                    InputProps={{
                                        readOnly: !isEditable,
                                    }}
                                    onChange={(e) => handleWWWChange(e, index)}
                                />
                                </ListItem>
                            ))} </> :
                            <>
                                {updatedAAR.whatWentWell && updatedAAR.whatWentWell.map((line, i) => (
                                    <ReactMarkdown key={i} children={` ${line}`} />
                                ))}
                            </>
                        }
                        {updatedAAR.whatWentWell && updatedAAR.whatWentWell.length === 0 && (
                            <Typography variant="caption">Nothing Went Well??</Typography>
                        )}
                        {isEditable &&
                            <>
                                <Stack direction={"row"} justifyContent={"flex-end"} spacing={2}>
                                    <ButtonGroup variant={"outlined"}>
                                        <Button onClick={handleAddWWW} startIcon={<PlusCircleFilled/>}>Add What Went Well?</Button>
                                        {updatedAAR.whatWentWell &&
                                            <Button onClick={() => handleOpenConfirmDialog("whatWentWell")} startIcon={<ClearRounded />}>What Went Well</Button>
                                    }
                                    </ButtonGroup>
                                </Stack>
                            </>
                        }
                    </List>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
    </Grid>

    <Grid item xs={12} >
    <Grid container spacing={2}>
        <Grid item xs={2}>
            <ColorTextBox size={200} text={"Challenges"}/>
        </Grid>
        <Grid item xs={10}>

            <Card>
                <CardContent>
                    <List >
                        <List>
                            {isEditable ?
                              <>
                              {updatedAAR.challenges && updatedAAR.challenges.map((challenge, index) => (
                                    <ListItem key={index}>
                                        <TextField
                                        value={challenge}
                                        variant={"standard"}
                                        fullWidth={true}
                                        multiline={true}
                                        InputProps={{
                                            readOnly: !isEditable,
                                        }}
                                        onChange={(e) => handleChallengeChange(e, index)}
                                    />
                                    </ListItem>
                                ))}
                              </> :
                                <>
                                    {updatedAAR.challenges && updatedAAR.challenges.map((line, i) => (
                                        <ReactMarkdown key={i} children={`${line}`} />
                                    ))}
                                </>
                            }
                            {updatedAAR.challenges && updatedAAR.challenges.length === 0 && (
                                <Typography variant="caption">No Challenges Defined</Typography>
                            )}
                            {isEditable &&
                                <>
                                    <Stack direction={"row"} justifyContent={"flex-end"} spacing={2}>
                                        <ButtonGroup variant={"outlined"}>
                                            <Button onClick={handleAddChallenge} startIcon={<PlusCircleFilled/>}>Challenge</Button>
                                            {updatedAAR.challenges &&
                                                <Button onClick={() => handleOpenConfirmDialog("challenges")} startIcon={<ClearRounded />}>Challenges</Button>
                                        }
                                        </ButtonGroup>
                                    </Stack>
                                </>
                            }
                        </List>
                    </List>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
    </Grid>

    <Grid item xs={12} >
    <Grid container spacing={2}>
        <Grid item xs={2}>
            <ColorTextBox size={220} text={"Areas for Improvement"}/>
        </Grid>
        <Grid item xs={10}>

            <Card>
                <CardContent>
                    <List >
                        {isEditable ?
                            <>
                                {updatedAAR.areasForImprovement && updatedAAR.areasForImprovement.map((ll, index) => (
                                    <ListItem key={index}>
                                        <TextField
                                            value={ll}
                                            variant={"standard"}
                                            fullWidth={true}
                                            multiline={true}
                                            InputProps={{
                                                readOnly: !isEditable,
                                            }}
                                            onChange={(e) => {
                                                const newRecommendations = [...updatedAAR.areasForImprovement];
                                                newRecommendations[index] = e.target.value;
                                                setAAR({ ...updatedAAR, areasForImprovement: newRecommendations });
                                            }}
                                        />
                                    </ListItem>
                                ))}
                                </>
                            :
                            <>
                                {updatedAAR.areasForImprovement && updatedAAR.areasForImprovement.map((line, i) => (
                                    <ReactMarkdown key={i} children={`${line}`} />
                                ))}
                            </>
                        }
                        {isEditable &&
                            <>
                                <Stack direction={"row"} justifyContent={"flex-end"} spacing={2}>
                                    <ButtonGroup variant={"outlined"}>
                                        <Button onClick={handleAddAreaForImprovement} startIcon={<PlusCircleFilled/>}>Areas for Improvement</Button>
                                        {updatedAAR.areasForImprovement &&
                                            <Button onClick={() => handleOpenConfirmDialog("areasForImprovement")} startIcon={<ClearRounded />}>Areas for Improvement</Button>
                                    }
                                    </ButtonGroup>
                                </Stack>
                            </>
                        }
                    </List>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
    </Grid>

    <Grid item xs={12} >
    <Grid container spacing={2}>
        <Grid item xs={2}>
            <ColorTextBox size={200} text={"Lessons Learned"}/>
        </Grid>
        <Grid item xs={10}>

            <Card>
                <CardContent>
                    <List>
                        {isEditable ?
                            <>
                                {updatedAAR.lessonsLearned && updatedAAR.lessonsLearned.map((lessonlearned, index) => (
                                    <ListItem key={index}>
                                        <TextField
                                        value={lessonlearned}
                                        variant={"standard"}
                                        fullWidth={true}
                                        multiline={true}
                                        InputProps={{
                                            readOnly: !isEditable,
                                        }}
                                        onChange={(e) => handleLessonLearnedChange(e, index)}
                                    />
                                    </ListItem>
                                ))}
                                </> :
                            <>
                                {updatedAAR.lessonsLearned && updatedAAR.lessonsLearned.map((line, i) => (
                                    <ReactMarkdown key={i} children={`${line}`} />
                                ))}
                            </>
                        }
                        {updatedAAR.lessonsLearned && updatedAAR.lessonsLearned.length === 0 && (
                            <Typography variant="caption">No Lessons Learned Defined</Typography>
                        )}
                        {isEditable &&
                            <>
                                <Stack direction={"row"} justifyContent={"flex-end"} spacing={2}>
                                    <ButtonGroup variant={"outlined"}>
                                        <Button onClick={handleAddLessonLearned} startIcon={<PlusCircleFilled/>}>Lesson Learned</Button>
                                        {updatedAAR.lessonsLearned &&
                                            <Button onClick={() => handleOpenConfirmDialog("lessonsLearned")} startIcon={<ClearRounded />}>Lessons Learned</Button>
                                    }
                                    </ButtonGroup>
                                </Stack>
                            </>
                        }
                    </List>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
    </Grid>

    <Grid item xs={12} >
    <Grid container spacing={2}>
        <Grid item xs={2}>
            <ColorTextBox size={200} text={"Action Items"}/>
        </Grid>
        <Grid item xs={10}>
            <Card>
                <CardContent>
                    <List>
                        {isEditable ?
                            <>
                                {updatedAAR.actionItems && updatedAAR.actionItems.map((ai, index) => (
                                    <ListItem key={index}>
                                        <TextField
                                        value={ai}
                                        variant={"standard"}
                                        fullWidth={true}
                                        multiline={true}
                                        InputProps={{
                                            readOnly: !isEditable,
                                        }}
                                        onChange={(e) => handleActionItemChange(e, index)}
                                    />
                                    </ListItem>
                                ))}
                            </>:
                            <>
                                {updatedAAR.actionItems && updatedAAR.actionItems.map((line, i) => (
                                    <ReactMarkdown key={i} children={`${line}`} />
                                ))}
                            </>
                        }
                        {updatedAAR.actionItems && updatedAAR.actionItems.length === 0 && (
                            <Grid item xs={12}>
                                <Typography variant="caption">No Action Items Defined</Typography>
                            </Grid>
                        )}
                        {isEditable &&
                            <>
                                <Stack direction={"row"} justifyContent={"flex-end"} spacing={2}>
                                    <ButtonGroup variant={"outlined"}>
                                        <Button onClick={handleAddActionItem} startIcon={<PlusCircleFilled/>}>Action Item</Button>
                                        {updatedAAR.actionItems &&
                                            <Button onClick={() => handleOpenConfirmDialog("actionItems")} startIcon={<ClearRounded />}>Action Items</Button>
                                        }
                                    </ButtonGroup>
                                </Stack>
                            </>
                        }
                    </List>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
    </Grid>

    <Grid item xs={12} >
    <Grid container spacing={2}>
        <Grid item xs={2}>
            <ColorTextBox size={200} text={"Recommend"}/>
        </Grid>
        <Grid item xs={10}>

            <Card>
                <CardContent>
                    <List>
                        {isEditable ?
                            <>
                                {updatedAAR.recommendations && updatedAAR.recommendations
                                    .filter(recommendation => recommendation && recommendation.trim() !== '')
                                    .map((recommendation, index) => (
                                        <ListItem key={index}>
                                            <TextField
                                            value={recommendation}
                                            variant={"standard"}
                                            fullWidth={true}
                                            multiline={true}
                                            InputProps={{
                                                readOnly: !isEditable,
                                            }}
                                            onChange={(e) => handleRecommendationChange(e, index)}
                                        />
                                        </ListItem>
                                    ))}
                            </> :
                            <>
                                {updatedAAR.recommendations && updatedAAR.recommendations
                                    .filter(line => line && line.trim() !== '')
                                    .map((line, i) => (
                                        <ReactMarkdown key={i} children={`${line}`} />
                                    ))}
                            </>
                        }
                        {updatedAAR.recommendations && updatedAAR.recommendations.length === 0 && (
                            <Typography variant="caption">No Recommendations Defined</Typography>
                        )}
                        {isEditable &&
                            <>
                                <Stack direction={"row"} justifyContent={"flex-end"} spacing={2}>
                                    <ButtonGroup variant={"outlined"}>
                                        <Button onClick={handleAddRecommendation} startIcon={<PlusCircleFilled/>}>Recommendation</Button>
                                        {updatedAAR.recommendations &&
                                            <Button onClick={() => handleOpenConfirmDialog("recommendations")} startIcon={<ClearRounded />}>Recommendations</Button>                                        }
                                    </ButtonGroup>
                                </Stack>
                            </>
                        }
                    </List>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
    </Grid>

    <Grid item xs={12} >
    <Grid container spacing={2}>
        <Grid item xs={2}>
            <ColorTextBox size={200} text={"Conclusion"}/>
        </Grid>
        <Grid item xs={10}>

            <Card>
                <CardContent>
                    {isEditable ?
                    <TextField
                        placeholder="Conclusion"
                        value={updatedAAR.conclusion}
                        variant={"standard"}
                        multiline
                        fullWidth
                        InputProps={{
                            readOnly: !isEditable,
                        }}
                        onChange={(e) => setAAR({ ...updatedAAR, conclusion: e.target.value })}
                    /> :
                        <>
                            {updatedAAR.conclusion && updatedAAR.conclusion.split('\n').map((line, i) => (
                                <ReactMarkdown key={i} children={`${line}`} />
                            ))}
                        </>
                    }
                </CardContent>
            </Card>
        </Grid>
    </Grid>
    </Grid>
    {isEditable &&
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" color="primary" size={"large"} onClick={handleUpdate}>
                Update
            </Button>
        </Grid>
    }
    <Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
            <Typography>Are you sure you want to clear this attribute?</Typography>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setOpenConfirmDialog(false)} color="secondary">Cancel</Button>
            <Button onClick={handleConfirmClear} color="primary">Confirm</Button>
        </DialogActions>
    </Dialog>
</Grid>

);
};

export default AARComponent;