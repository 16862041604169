import {
    Button, ButtonGroup, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle,
    Divider,
    Grid,
    Stack,
    TextField, Tooltip,
    Typography
} from "@mui/material";
import {
    FileData, ScenarioGroup,
    ScenarioObjective,
    ScriptItem,
    ScriptResource
} from "../../interfaces/interfaces";
import MainCard from "../../components/MainCard";

import React, {useEffect, useRef, useState} from "react";
import {
    AddCircleOutlineOutlined, ArrowCircleDownOutlined, ArrowCircleUpOutlined, DeleteForeverOutlined,
    DeleteOutlineOutlined,
    DetailsOutlined,
    DirectionsOutlined, FileUploadOutlined,
    ImageOutlined, RecordVoiceOverOutlined
} from "@mui/icons-material";
import SectionFlow from "../tabletop/SectionFlow";
import ScenarioImageGridDisplay from "./ScenarioImageGridDisplay";
import AIAssistantIcon from "../icon/AIAssistantIcon";
import QuickLoaderComponent from "../cards/QuickLoaderComponent";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {styled} from "@mui/material/styles";
import {Autocomplete} from "@mui/lab";
import ObjectiveList from "./ObjectiveList";
import ResourceCard from "./ResourceCard";
import {getPlaceholderScenarioScriptSectionStep} from "../functions/interfacePlaceholders";
import GroupCard from "./GroupCard";

interface ScriptItemProps {
    script: ScriptItem[];
    scenarioId: string;
    companyId?: string;
    updateScript: (script: ScriptItem[]) => void;
    generateImage?: (scriptItemId: string) => void;
    resources?: ScriptResource[];
    groups?: ScenarioGroup[];
}

const ScenarioScriptEditorComponent: React.FC<ScriptItemProps> = ({ script, companyId,
                                                                      scenarioId, updateScript, resources, generateImage,groups }) => {
    const [scriptItems, setScriptItems] = useState(script);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentScriptItem, setCurrentScriptItem] = useState(script[0]); // Set the current script item to the first item in the array

    useEffect(() => {
        setCurrentScriptItem(scriptItems[currentIndex]); // Update the current script item whenever scriptItems changes
    }, [scriptItems, currentIndex]);

    useEffect(() => {
        updateScript(scriptItems);
    }, [scriptItems]);

    useEffect(() => {
        setScriptItems(script);
    }, [script]);

    const handleSectionChange = (index: number, value: string) => {
        //console.log("section change: "+index+" "+value)
        const updatedScriptItems = [...scriptItems];
        updatedScriptItems[index] = {
            ...updatedScriptItems[index],
            section: value,
        };
        setCurrentScriptItem(updatedScriptItems[index]);
        setScriptItems(updatedScriptItems);
        updateScript(updatedScriptItems);
    };

    const handleBackgroundChange = (index: number, value: string) => {
        const updatedScriptItems = [...scriptItems];
        updatedScriptItems[index] = {
            ...updatedScriptItems[index],
            background: value,
        };
        setCurrentScriptItem(updatedScriptItems[index]);
        setScriptItems(updatedScriptItems);
        updateScript(updatedScriptItems);

    };

    function handleAddStep(index: number) {
        setScriptItems((prevScriptItems) => {
            //console.log(index);
            const updatedScriptItems = [...prevScriptItems];
            if (updatedScriptItems[index]) {
                if (!updatedScriptItems[index].scenarioScriptSectionSteps) {
                    updatedScriptItems[index].scenarioScriptSectionSteps = [];
                    //   console.log("added new sectionsteps");
                }

                const newStep = getPlaceholderScenarioScriptSectionStep();

                updatedScriptItems[index].scenarioScriptSectionSteps.push(newStep);
                // console.log("new step: "+updatedScriptItems[index].sectionSteps.length);
                updateScript(updatedScriptItems);
            }
            return updatedScriptItems;
        });
    }

    const handleAddSection = () => {
        // @ts-ignore
        setScriptItems(prevScriptItems => [...prevScriptItems, { section: 'New Section', background: '', sectionSteps: [] }]);
    };

    const handleRemoveSection = (index: number) => {
        setScriptItems(prevScriptItems => prevScriptItems.filter((_, i) => i !== index));
        //updateScript(scriptItems);
    };

    const handleNext = () => {
        if (currentIndex < scriptItems.length - 1) {
            setCurrentIndex(currentIndex + 1);
            setCurrentScriptItem(scriptItems[currentIndex + 1])
        }
    };

    const handlePrevious = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
            setCurrentScriptItem(scriptItems[currentIndex - 1])
        }
    };
    const [openRemoveSectionDialog, setRemoveSectionDialog] = useState(false);

    const handleDialogCancel = () => {
        setRemoveSectionDialog(false);
    };
    const handleDialogOk = () => {
        handleRemoveSection(currentIndex);
        setRemoveSectionDialog(false);
    };

    const [genImageLoading, setGenImageLoading] = useState(false);
    const handleGenerateImage = (scriptItemId: string) => {
        if (generateImage) {
            setGenImageLoading(true);
            generateImage(scriptItemId);
            setGenImageLoading(false);
        }
    }

    async function handleGenerateDetails(stepId: string, generateType:string) {
        if (currentScriptItem) {
            const stepIndex = currentScriptItem.scenarioScriptSectionSteps.findIndex((step) => step.stepId === stepId);
            const token = localStorage.getItem('token');
            if (stepIndex !== -1) {
                const endpoint = (companyId?process.env.REACT_APP_REST_API_URL + '/api/scenario/assistant/generatescenariosteptext/' + token + '/'+companyId +'/' + scenarioId + '/' + stepId+'/'+generateType : process.env.REACT_APP_REST_API_URL + '/api/scenario/organization/assistant/generatescenariosteptext/' + token + '/' + scenarioId + '/' + stepId+'/'+generateType);
                const response = await fetch(endpoint);
                const data = await response.text();
                const updatedScriptItems = [...scriptItems];
                updatedScriptItems[currentIndex].scenarioScriptSectionSteps[stepIndex].stepContent = data;
                setScriptItems(updatedScriptItems);
                setCurrentScriptItem(updatedScriptItems[currentIndex]);
            }
        }
    }

    async function handleGenerateInstructions(stepId: string, generateType:string) {
        if (currentScriptItem) {
            const stepIndex = currentScriptItem.scenarioScriptSectionSteps.findIndex((step) => step.stepId === stepId);
            const token = localStorage.getItem('token');
            if (stepIndex !== -1) {
                const endpoint = (companyId?process.env.REACT_APP_REST_API_URL + '/api/scenario/assistant/generatescenariosteptext/' + token + '/'+companyId +'/' + scenarioId + '/' + stepId+'/'+generateType : process.env.REACT_APP_REST_API_URL + '/api/scenario/organization/assistant/generatescenariosteptext/' + token + '/' + scenarioId + '/' + stepId+'/'+generateType);
                const response = await fetch(endpoint);
                const data = await response.text();
                const updatedScriptItems = [...scriptItems];
                updatedScriptItems[currentIndex].scenarioScriptSectionSteps[stepIndex].stepInstructions = data;
                setScriptItems(updatedScriptItems);
                setCurrentScriptItem(updatedScriptItems[currentIndex]);
            }
        }
    }

    async function handleGenerateTalkingPoint(stepId: string, generateType:string) {
        if ( currentScriptItem && stepId) {
            const token = localStorage.getItem('token');
            const endpoint = (companyId?process.env.REACT_APP_REST_API_URL + '/api/scenario/assistant/generatescenariosteptext/' + token + '/'+companyId +'/' + scenarioId + '/' + stepId+'/'+generateType : process.env.REACT_APP_REST_API_URL + '/api/scenario/organization/assistant/generatescenariosteptext/' + token + '/' + scenarioId + '/' + stepId+'/'+generateType);
            const response = await fetch(endpoint);
            const data = await response.text();
            const updatedScriptItems = scriptItems.map(scriptItem => {
                if (scriptItem === currentScriptItem) {
                    return {
                        ...scriptItem,
                        scenarioScriptSectionSteps: scriptItem.scenarioScriptSectionSteps.map(step => {
                            if (step.stepId === stepId) {
                                return {
                                    ...step,
                                    stepTalkingPoint: data
                                };
                            }
                            return step;
                        })
                    };
                }
                return scriptItem;
            });
            setScriptItems(updatedScriptItems);
            setCurrentScriptItem(updatedScriptItems.find(scriptItem => scriptItem === currentScriptItem) || currentScriptItem);        }
    }

    async function handleLinkResource(resourceId: string, scriptId: string) {
        //console.log('From editor: Linking resource', resourceId, 'to script', scriptId);
        if (companyId ) {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/scenario/linkresource/' + token + '/' + companyId + '/' + scenarioId + '/' + scriptId + '/' + resourceId);
                const data = await response.json();
                //console.log(data);
                setScriptItems(data.script);
            } catch (error) {
                console.error(error);
            }
        }
    }

    async function handleUnlinkResource(resourceId: string, scriptId: string) {
        //console.log('From editor: UNLINK resource', resourceId, 'to script', scriptId);
        if (companyId ) {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/scenario/unlinkresource/' + token + '/' + companyId + '/' + scenarioId + '/' + scriptId + '/' + resourceId);
                const data = await response.json();
                console.log(data);
                setScriptItems(data.script);
            } catch (error) {
                console.error(error);
            }
        }
    }

    async function handleRemoveStep(sectionId:string, stepId: string) {
            const token = localStorage.getItem('token');
            if (token && stepId && sectionId) {
                try {
                    let response;
                    if (companyId) {
                        response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/scenario/removestep/' + token + '/' + companyId + '/' + scenarioId + '/' + sectionId + '/' + stepId);
                    } else {
                        response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/scenario/organization/removestep/' + token + '/' + scenarioId + '/' + sectionId + '/' + stepId);
                    }
                    const data = await response.json();
                    //console.log(data);
                    setScriptItems(data.script);
                } catch (error) {
                    console.error(error);
                }
            }
    }

    const [stepToRemove, setStepToRemove] = useState<null | string>(null);
    const [openStepConfirm, setOpenStepConfirm] = useState(false);
    function handleOpenRemoveStepDialog(stepId: string) {
        setStepToRemove(stepId);
        setOpenStepConfirm(true);
    }
    function handleStepDialogCancel() {
        setOpenStepConfirm(false);
    }

    function handleStepDialogOk() {
        if (stepToRemove && currentScriptItem) {
            handleRemoveStep(currentScriptItem.id, stepToRemove);
        }
        setOpenStepConfirm(false);
        setStepToRemove(null);
    }

    const handleMoveUp = () => {
        if (currentIndex > 0) {
            const updatedScriptItems = [...scriptItems];
            const temp = updatedScriptItems[currentIndex - 1];
            updatedScriptItems[currentIndex - 1] = updatedScriptItems[currentIndex];
            updatedScriptItems[currentIndex] = temp;
            setScriptItems(updatedScriptItems);
            setCurrentIndex(currentIndex - 1);
        }
    };

    const handleMoveDown = () => {
        if (currentIndex < scriptItems.length - 1) {
            const updatedScriptItems = [...scriptItems];
            const temp = updatedScriptItems[currentIndex + 1];
            updatedScriptItems[currentIndex + 1] = updatedScriptItems[currentIndex];
            updatedScriptItems[currentIndex] = temp;
            setScriptItems(updatedScriptItems);
            setCurrentIndex(currentIndex + 1);
        }
    };

    const handleStepMoveUp = (stepIndex: number) => {
    if (stepIndex > 0) {
        const updatedScriptItems = [...scriptItems];
        const temp = updatedScriptItems[currentIndex].scenarioScriptSectionSteps[stepIndex - 1];
        updatedScriptItems[currentIndex].scenarioScriptSectionSteps[stepIndex - 1] = updatedScriptItems[currentIndex].scenarioScriptSectionSteps[stepIndex];
        updatedScriptItems[currentIndex].scenarioScriptSectionSteps[stepIndex] = temp;
        setScriptItems(updatedScriptItems);
    }
};

    const handleStepMoveDown = (stepIndex: number) => {
        if (stepIndex < scriptItems[currentIndex].scenarioScriptSectionSteps.length - 1) {
            const updatedScriptItems = [...scriptItems];
            const temp = updatedScriptItems[currentIndex].scenarioScriptSectionSteps[stepIndex + 1];
            updatedScriptItems[currentIndex].scenarioScriptSectionSteps[stepIndex + 1] = updatedScriptItems[currentIndex].scenarioScriptSectionSteps[stepIndex];
            updatedScriptItems[currentIndex].scenarioScriptSectionSteps[stepIndex] = temp;
            setScriptItems(updatedScriptItems);
        }
    };

    const [currentStepImage, setCurrentStepImage] = useState<null | string>(null);

    //BEGIN remove step image
    const [openRemoveImageDialog, setOpenRemoveImageDialog] = useState(false);

// Add this function to handle the opening of the dialog
    const handleOpenRemoveImageDialog = (stepId:string) => {
        setCurrentStepImage(stepId);
        setOpenRemoveImageDialog(true);
    };

// Add this function to handle the closing of the dialog
    const handleCloseRemoveImageDialog = () => {
        setOpenRemoveImageDialog(false);
        setCurrentStepImage(null);
    };

    function handleRemoveStepImageOK() {
        if (currentStepImage && currentScriptItem) {
            handleRemoveImage(currentScriptItem.id, currentStepImage);
        }
        setOpenStepConfirm(false);
        setCurrentStepImage(null);
    }

    const handleRemoveImage = async (sectionId:string, stepId:string) => {
        const token = localStorage.getItem('token');
        let response;
        if (companyId) {
            response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/scenario/removestepimage/' + token + '/' + companyId + '/' + scenarioId + '/' + sectionId + '/' + stepId);
        } else {
            response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/scenario/organization/removestepimage/' + token + '/' + scenarioId + '/' + sectionId + '/' + stepId);
        }
        const data = await response.json();
        setScriptItems(data.script);
        handleCloseRemoveImageDialog();
    };

    //END remove step image
    //BEGIN Upload image
    const [openUploadImageDialog, setOpenUploadImageDialog] = useState(false);

    const handleOpenUploadImageDialog = (stepId:string) => {
        setCurrentStepImage(stepId);
        setOpenUploadImageDialog(true);
    };
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileSelect = () => {
        const files = fileInputRef.current?.files;
        if (files) {
            uploadFiles(files);
        }
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        uploadFiles(files);
    };

    const [uploadingFile, setUploadingFile] = useState(false);
    const uploadFiles = (files: FileList) => {
        const formData = new FormData();
        setUploadingFile(true);
        // Create a FileData object
        const fileData: FileData = {
            shortName: "",
            description: "",
            category: "", // Add the category state variable when it's defined
            type: "",
            fileText: ""
        };

        const fileDataBlob = new Blob([JSON.stringify(fileData)], { type: 'application/json' });
        formData.append('fileData', fileDataBlob);

        Array.from(files).forEach(file => {
            formData.append('file', file);
        });
        const token = localStorage.getItem('token');

        if (companyId) {
            var apiURL = process.env.REACT_APP_REST_API_URL + '/api/files/upload/company/scenario/image/' + token + '/' + companyId + '/' + scenarioId  + '/' + currentStepImage
        } else {
            var apiURL = process.env.REACT_APP_REST_API_URL + '/api/files/upload/organization/scenario/image/' + token + '/' + scenarioId  + '/' + currentStepImage
        }
        fetch(apiURL, {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(data => {
                setScriptItems(data.script);
                setUploadingFile(false);
                setOpenUploadImageDialog(false);
            })
            .catch(error => {
                // Handle error
                setUploadingFile(false);
                console.error('Upload error:', error);
            });
    };

    const handleUploadImageClose = () => {
        setOpenUploadImageDialog(false);
        setCurrentStepImage(null);
    };

    //END upload image
    //BEGIN remove section image
    const [openRemoveSectionImageDialog, setOpenRemoveSectionImageDialog] = useState(false);

    const handleOpenRemoveSectionImageDialog = () => {
        setOpenRemoveSectionImageDialog(true);
    };
    const handleCloseRemoveSectionImageDialog = () => {
        setOpenRemoveSectionImageDialog(false);
    };
    function handleRemoveSectionImageOK() {
        if ( currentScriptItem) {
            handleRemoveSectionImage(currentScriptItem.id);
        }
        setOpenRemoveSectionImageDialog(false);
    }

    const handleRemoveSectionImage = async (sectionId:string) => {
        const token = localStorage.getItem('token');
        let response;
        if (companyId) {
            response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/scenario/removesectionimage/' + token + '/' + companyId + '/' + scenarioId + '/' + sectionId );
        } else {
            response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/scenario/organization/removesectionimage/' + token + '/' + scenarioId + '/' + sectionId );
        }
        const data = await response.json();
        setScriptItems(data.script);
        handleCloseRemoveImageDialog();
    };
    //END remove section image

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const [showSectionImageButtons, setShowSectionImageButtons] = useState(false);

    const toggleSectionImageButtons = () => {
        setShowSectionImageButtons(!showSectionImageButtons);
    };

    function handleStepObjectiveUpdate(id: string, objectives: ScenarioObjective[]) {
        if (currentScriptItem) {
            const updatedScriptItems = scriptItems.map(scriptItem => {
                if (scriptItem === currentScriptItem) {
                    return {
                        ...scriptItem,
                        scenarioScriptSectionSteps: scriptItem.scenarioScriptSectionSteps.map(step => {
                            if (step.stepId === id) {
                                return {
                                    ...step,
                                    stepObjectives: objectives
                                };
                            }
                            return step;
                        })
                    };
                }
                return scriptItem;
            });
            setScriptItems(updatedScriptItems);
            setCurrentScriptItem(updatedScriptItems.find(scriptItem => scriptItem === currentScriptItem) || currentScriptItem);
        }

    }

    function handleLinkGroup(groupId: string, scriptId: string) {
       console.log('From editor: Linking group', groupId, 'to script', scriptId);
        // Handle linking a group to the script
        // This function should be implemented based on your requirements
        if (companyId) {
            const token = localStorage.getItem('token');
            fetch(process.env.REACT_APP_REST_API_URL + '/api/scenario/linkgroup/' + token + '/' + companyId + '/' + scenarioId + '/' + scriptId + '/' + groupId)
                .then(response => response.json())
                .then(data => {
                    setScriptItems(data.script);
                })
                .catch(error => {
                    console.error('Error linking group:', error);
                });
        }
    }

    function handleUnlinkGroup() {
        // Handle unlinking a group from the script
        // This function should be implemented based on your requirements
    }

    const [visibleComponent, setVisibleComponent] = useState<'objectives' | 'resources' | 'groups'>('objectives');

    const handleToggle = (component: 'objectives' | 'resources' | 'groups') => {
        setVisibleComponent(component);
    };


    return (
        <>
            <Dialog
                open={openUploadImageDialog}
                onClose={handleUploadImageClose}
            >
                <DialogTitle>{"Upload a new image"}</DialogTitle>
                <DialogContent>
                    <div
                        className="p-4 border rounded text-center"
                        onDrop={handleDrop}
                        onDragOver={event => event.preventDefault()}
                    >
                        <Card>
                            <CardContent>
                                <Grid container={true} spacing={3}>
                                    {uploadingFile && <QuickLoaderComponent loadBanner={"Uploading Image..."} />}
                                    <Grid item xs={12}>
                                        <br/>
                                        <Grid container={true} spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography variant="caption" color="textSecondary">For file
                                                    assets</Typography>
                                                <Card variant="outlined">
                                                    <CardContent>
                                                        <Typography variant="caption" color="textSecondary">
                                                            <span className="drop-text">Drag and drop files here or click to select files</span>
                                                            <VisuallyHiddenInput
                                                                type="file"
                                                                id="file-input"
                                                                multiple
                                                                onChange={handleFileSelect}
                                                                className="d-none"
                                                                ref={fileInputRef}
                                                            />
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack spacing={1} alignItems="center" direction={"row"}
                                               justifyContent="flex-end">
                                            <Button variant="outlined" size={"small"}
                                                    onClick={() => fileInputRef.current?.click()}
                                                    startIcon={<CloudUploadIcon/>}>
                                                Select Files to Upload
                                            </Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUploadImageClose} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openRemoveSectionDialog}
                onClose={handleDialogCancel}
            >
                <DialogTitle>{"Are you sure you want to remove this section?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleDialogCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDialogOk} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openStepConfirm}
                onClose={handleStepDialogCancel}
            >
                <DialogTitle>{"Are you sure you want to remove this step?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleStepDialogCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleStepDialogOk} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openRemoveImageDialog}
                onClose={handleCloseRemoveImageDialog}
            >
                <DialogTitle>{"Are you sure you want to remove this image?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleCloseRemoveImageDialog} color="primary">
                        No
                    </Button>
                    <Button onClick={handleRemoveStepImageOK} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openRemoveSectionImageDialog}
                onClose={handleCloseRemoveSectionImageDialog}
            >
                <DialogTitle>{"Are you sure you want to remove this image?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleCloseRemoveSectionImageDialog} color="primary">
                        No
                    </Button>
                    <Button onClick={handleRemoveSectionImageOK} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

                <Card>
                    <CardContent>
                        <Grid container={true} spacing={3}>
                        <Grid item xs={12}>
                            {scriptItems && <SectionFlow sections={scriptItems} currentSection={currentScriptItem} /> }
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container={true} spacing={1}>
                                <Grid item xs={6}>
                            <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1.25}>
                                <Tooltip title={"Add a new section to the script"}>
                                <Button variant="outlined" size="small" onClick={handleAddSection}>
                                    <AddCircleOutlineOutlined fontSize="small" /> Section
                                </Button>
                                </Tooltip>
                            </Stack>
                                </Grid>
                                <Grid item xs={6}>
                            <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={1.25}>
                                <Button variant="outlined" size="small" onClick={handlePrevious} disabled={currentIndex === 0}>Previous</Button>
                                <Button variant="outlined" size="small" onClick={handleNext} disabled={currentIndex === scriptItems.length - 1}>Next</Button>
                            </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {genImageLoading && <QuickLoaderComponent loadBanner={"Generating Image..."} />}
                            <MainCard title={"Section Details: "+currentScriptItem?.section}
                                  secondary={
                                <>
                                    <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1.25}>
                                        <ButtonGroup size="small">
                                            <Tooltip title={"Move this section up"}>
                                                <Button variant="outlined" size="small" onClick={handleMoveUp}><ArrowCircleUpOutlined/>&nbsp;Move Up </Button>
                                            </Tooltip>
                                                <Tooltip title={"Move this section down"}>
                                            <Button variant="outlined" size="small" onClick={handleMoveDown}>Move Down&nbsp; <ArrowCircleDownOutlined/></Button>
                                                </Tooltip>
                                        </ButtonGroup>
                                        <ButtonGroup size="small">
                                        <Tooltip title={"Add a new step to this section"}>
                                        <Button disabled={!currentScriptItem} size="small" onClick={() => handleAddStep(currentIndex)} >
                                            <AddCircleOutlineOutlined fontSize="small" />Step
                                        </Button>
                                        </Tooltip>
                                        <Tooltip title={"Remove this section"}>
                                            <Button size="small" onClick={() => setRemoveSectionDialog(true)}>
                                                <DeleteOutlineOutlined fontSize="small" />Section
                                            </Button>
                                        </Tooltip>
                                            <Tooltip title={"Generate an image for this section"}>
                                                <Button size="small" onClick={() => handleGenerateImage(currentScriptItem?.id)}>
                                                    <FileUploadOutlined/>Image
                                                </Button>
                                            </Tooltip>
                                    </ButtonGroup>

                                        <ButtonGroup size="small">
                                        <Button onClick={toggleSectionImageButtons}><ImageOutlined /></Button>
                                        {showSectionImageButtons && (
                                            <>
                                                {generateImage && (
                                                    <Tooltip title={"Generate an image for this section"}>
                                                        <Button size="small" onClick={() => handleGenerateImage(currentScriptItem?.id)}>
                                                            <AIAssistantIcon />
                                                        </Button>
                                                    </Tooltip>
                                                )}
                                                <Tooltip title={"Upload an image for this section"}>
                                                    <Button size="small" onClick={() => handleOpenUploadImageDialog(currentScriptItem?.id)}>
                                                        <FileUploadOutlined />
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title={"Remove the image for this section"}>
                                                    <Button size="small" onClick={handleOpenRemoveSectionImageDialog}
                                                        disabled={!currentScriptItem?.images || currentScriptItem?.images.length === 0}
                                                    >
                                                        <DeleteForeverOutlined />
                                                    </Button>
                                                </Tooltip>
                                            </>
                                        )}
                                        </ButtonGroup>
                                    </Stack>
                                      </>
                            }
                        >
                            <Grid container spacing={3} >
                                <Grid item xs={currentScriptItem?.images?8:12}>
                                    <TextField
                                        name={`section${currentIndex}`}
                                        label="Section"
                                        fullWidth
                                        variant={"standard"}
                                        value={currentScriptItem?.section?currentScriptItem?.section:""}
                                        onChange={(e) => handleSectionChange(currentIndex, e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={currentScriptItem?.images?8:12}>
                                    <TextField
                                        name={`background${currentIndex}`}
                                        label="Background"
                                        variant={"standard"}
                                        fullWidth
                                        multiline={true}
                                        value={currentScriptItem?.background?currentScriptItem?.background:""}
                                        onChange={(e) => handleBackgroundChange(currentIndex, e.target.value)}
                                    />
                                </Grid>
                                {currentScriptItem?.images &&
                                  <>
                                    <Grid item xs={4}>
                                        <ScenarioImageGridDisplay images={currentScriptItem?.images} />
                                        <Button onClick={handleOpenRemoveSectionImageDialog}>Remove Image</Button>
                                    </Grid>
                                  </>

                                }
                                {/*<Grid item xs={12}>*/}
                                {/*    <Typography variant={"caption"}>{currentScriptItem.id}</Typography>*/}
                                {/*</Grid>*/}
                                <Divider/>
                                {currentScriptItem?.scenarioScriptSectionSteps && currentScriptItem?.scenarioScriptSectionSteps.length > 0 &&
                                    <Grid item={true} xs={12}><Typography variant="h4">{currentScriptItem?.section} - Steps</Typography></Grid>
                                }
                                {currentScriptItem?.scenarioScriptSectionSteps && currentScriptItem?.scenarioScriptSectionSteps.length > 0 && currentScriptItem?.scenarioScriptSectionSteps.map((step, innerIndex) => (
                                   <>
                                       {genImageLoading &&<Grid item xs={12}><QuickLoaderComponent loadBanner={"Generating Image..."} /></Grid> }
                                       <Grid item xs={8}>
                                           <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1.25}>
                                               <Typography variant="h4">Step {innerIndex+1} - {step.stepName}</Typography>
                                           </Stack>

                                       </Grid>
                                       <Grid item xs={4}>
                                           {generateImage &&
                                               <>
                                                   <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={1.25}>
                                                       <ButtonGroup variant="outlined" size="small" disabled={!step?.stepId}>
                                                           <Button variant="contained" color={"secondary"}>
                                                               <AIAssistantIcon/>Generate
                                                           </Button>
                                                           <Tooltip title={"Generate an image for this step"}>
                                                           <Button onClick={() => handleGenerateImage(step.stepId)}><ImageOutlined/></Button>
                                                           </Tooltip>
                                                            <Tooltip title={"Generate/wordsmith the step details text"}>
                                                           <Button onClick={() => handleGenerateDetails(step.stepId, "description")}><DetailsOutlined/></Button>
                                                           </Tooltip>
                                                           <Tooltip title={"Generate/wordsmith the step instructions"}>
                                                           <Button onClick={() => handleGenerateInstructions(step.stepId, "instructions")}><DirectionsOutlined/></Button>
                                                           </Tooltip>
                                                           <Tooltip title={"Generate/wordsmith the step talking point(s)"}>
                                                           <Button onClick={() => handleGenerateTalkingPoint(step.stepId, "talkingpoints")}><RecordVoiceOverOutlined/></Button>
                                                           </Tooltip>
                                                       </ButtonGroup>
                                                       {currentScriptItem && currentScriptItem.id && step && step.stepId &&
                                                           <ButtonGroup variant="outlined" size="small" disabled={!step?.stepId}>
                                                               <Tooltip title={"Move this step up"}>
                                                               <Button variant="outlined" size="small" onClick={() => handleStepMoveUp(innerIndex)}><ArrowCircleUpOutlined/></Button>
                                                               </Tooltip>
                                                               <Tooltip title={"Move this step down"}>
                                                                   <Button variant="outlined" size="small" onClick={() => handleStepMoveDown(innerIndex)}><ArrowCircleDownOutlined/></Button>
                                                               </Tooltip>
                                                               <Button variant="outlined" onClick={() => handleOpenRemoveStepDialog(step.stepId)}>
                                                               <DeleteOutlineOutlined/>
                                                               </Button>

                                                           </ButtonGroup>
                                                       }
                                                   </Stack>
                                               </>
                                           }
                                       </Grid>
                                       <Grid item xs={12}>
                                            <Grid container={true} spacing={1}>
                                                <Grid item xs={8}>
                                                    <Grid container={true} spacing={3}>
                                                        <Grid item xs={6}>
                                                            <Autocomplete
                                                                options={["Action", "Activity", "Analysis","Conclusion","Discussion","Inject","Investigate","Poll","Review","What If?","Workshop"]}
                                                                freeSolo
                                                                value={step.stepType?step.stepType:"Discussion"}
                                                                onChange={(event, newValue) => {
                                                                    const updatedScriptItems = [...scriptItems];
                                                                    if (typeof newValue === "string") {
                                                                        updatedScriptItems[currentIndex].scenarioScriptSectionSteps[innerIndex].stepType = newValue;
                                                                    }
                                                                    setScriptItems(updatedScriptItems);
                                                                    updateScript(updatedScriptItems);
                                                                }}
                                                                onInputChange={(event, newInputValue) => {
                                                                    const updatedScriptItems = [...scriptItems];
                                                                    updatedScriptItems[currentIndex].scenarioScriptSectionSteps[innerIndex].stepType = newInputValue;
                                                                    setScriptItems(updatedScriptItems);
                                                                    updateScript(updatedScriptItems);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        name={`steptype${innerIndex}`}
                                                                        label="Step Type"
                                                                        fullWidth
                                                                        variant={"standard"}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                name={`stepname${innerIndex}`}
                                                                label="Step Name"
                                                                fullWidth
                                                                variant={"standard"}
                                                                value={step.stepName?step.stepName:""}
                                                                onChange={(e) => {
                                                                    const updatedScriptItems = [...scriptItems];
                                                                    updatedScriptItems[currentIndex].scenarioScriptSectionSteps[innerIndex].stepName = e.target.value;
                                                                    setScriptItems(updatedScriptItems);
                                                                    updateScript(updatedScriptItems);
                                                                }}
                                                            />

                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Grid container={true} spacing={2}>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        name={`stepdeets${innerIndex}`}
                                                                        label="Step Details"
                                                                        fullWidth
                                                                        multiline={true}
                                                                        variant={"standard"}
                                                                        value={step.stepContent?step.stepContent:""}
                                                                        onChange={(e) => {
                                                                            const updatedScriptItems = [...scriptItems];
                                                                            updatedScriptItems[currentIndex].scenarioScriptSectionSteps[innerIndex].stepContent = e.target.value;
                                                                            setScriptItems(updatedScriptItems);
                                                                            updateScript(updatedScriptItems);
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        name={`steptalking${innerIndex}`}
                                                                        label="Step Talking Points"
                                                                        fullWidth
                                                                        multiline={true}
                                                                        variant={"standard"}
                                                                        value={step.stepTalkingPoint?step.stepTalkingPoint:""}
                                                                        onChange={(e) => {
                                                                            const updatedScriptItems = [...scriptItems];
                                                                            updatedScriptItems[currentIndex].scenarioScriptSectionSteps[innerIndex].stepTalkingPoint = e.target.value;
                                                                            setScriptItems(updatedScriptItems);
                                                                            updateScript(updatedScriptItems);
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        name={`stepstory${innerIndex}`}
                                                                        label="Step Storyline "
                                                                        fullWidth
                                                                        multiline={true}
                                                                        variant={"standard"}
                                                                        value={step.stepStoryline?step.stepStoryline:""}
                                                                        onChange={(e) => {
                                                                            const updatedScriptItems = [...scriptItems];
                                                                            updatedScriptItems[currentIndex].scenarioScriptSectionSteps[innerIndex].stepStoryline = e.target.value;
                                                                            setScriptItems(updatedScriptItems);
                                                                            updateScript(updatedScriptItems);
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Grid container={true} spacing={2}>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        name={`stepinstru${innerIndex}`}
                                                                        label="Facilitator: Step Instructions"
                                                                        fullWidth
                                                                        multiline={true}
                                                                        variant={"outlined"}
                                                                        value={step.stepInstructions?step.stepInstructions:""}
                                                                        onChange={(e) => {
                                                                            const updatedScriptItems = [...scriptItems];
                                                                            updatedScriptItems[currentIndex].scenarioScriptSectionSteps[innerIndex].stepInstructions = e.target.value;
                                                                            setScriptItems(updatedScriptItems);
                                                                            updateScript(updatedScriptItems);
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Grid container={true} spacing={1}>
                                                                        <Grid item xs={12}>
                                                                            <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1.25}>
                                                                                <ButtonGroup variant="outlined" size="small">
                                                                                    <Button
                                                                                        variant={visibleComponent === 'objectives' ? 'contained' : 'outlined'}
                                                                                        onClick={() => handleToggle('objectives')}
                                                                                    >
                                                                                        Objectives
                                                                                    </Button>
                                                                                    {resources && resources.length > 0 &&
                                                                                    <Button
                                                                                        variant={visibleComponent === 'resources' ? 'contained' : 'outlined'}
                                                                                        onClick={() => handleToggle('resources')}
                                                                                    >
                                                                                        Resources
                                                                                    </Button>
                                                                                    }
                                                                                    { groups && groups.length > 0 &&
                                                                                    <Button
                                                                                        variant={visibleComponent === 'groups' ? 'contained' : 'outlined'}
                                                                                        onClick={() => handleToggle('groups')}
                                                                                    >
                                                                                        Groups
                                                                                    </Button>
                                                                                    }
                                                                                </ButtonGroup>
                                                                            </Stack>
                                                                        </Grid>

                                                                        {visibleComponent === 'objectives' && (
                                                                            <Grid item xs={12}>
                                                                                <ObjectiveList
                                                                                    id={step.stepId}
                                                                                    objectives={step.stepObjectives}
                                                                                    onUpdate={handleStepObjectiveUpdate}
                                                                                    title={"Step Objectives"}
                                                                                    showAdd={true}
                                                                                    showComplete={false}
                                                                                    showDelete={true}
                                                                                    showEdit={true}
                                                                                />
                                                                            </Grid>
                                                                        )}

                                                                        {visibleComponent === 'resources' && resources && resources.length > 0 && (
                                                                            <Grid item xs={12}>
                                                                                <ResourceCard
                                                                                    title="Step Resources"
                                                                                    linkedResources={step.stepResources}
                                                                                    resources={resources}
                                                                                    id={step.stepId}
                                                                                    onLinkResource={handleLinkResource}
                                                                                    onUnlinkResource={handleUnlinkResource}
                                                                                />
                                                                            </Grid>
                                                                        )}

                                                                        {visibleComponent === 'groups' && groups && groups.length > 0 && (
                                                                            <Grid item xs={12}>
                                                                                <GroupCard
                                                                                    title="Step Groups"
                                                                                    linkedGroups={step.stepGroups}
                                                                                    groups={groups}
                                                                                    id={step.stepId}
                                                                                    onLinkGroup={handleLinkGroup}
                                                                                    onUnlinkGroup={handleUnlinkGroup}
                                                                                />
                                                                            </Grid>
                                                                        )}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>


                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Grid container={true} spacing={1}>
                                                        <Grid item xs={12}>
                                                            {step.images ?
                                                            <ScenarioImageGridDisplay images={step.images}/> :
                                                                <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={1.25}>
                                                                    <Typography variant={"h5"}>No Image</Typography>
                                                                </Stack>
                                                            }
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={1.25}>
                                                                <ButtonGroup variant="outlined" size="small" disabled={!step?.stepId}>
                                                                    <Button variant="contained" color={"secondary"}><ImageOutlined/></Button>
                                                                    {step.images && step.images.length > 0 &&
                                                                        <Tooltip title={"Remove the image from this step"}>
                                                                        <Button onClick={() => handleOpenRemoveImageDialog(step.stepId)}><DeleteForeverOutlined/></Button>
                                                                        </Tooltip>
                                                                    }
                                                                    <Tooltip title={"Upload an image for this step"}>
                                                                    <Button onClick={() => handleOpenUploadImageDialog(step.stepId)}><FileUploadOutlined/></Button>
                                                                    </Tooltip>
                                                                </ButtonGroup>
                                                            </Stack>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                       </Grid>
                                       {/*<Grid item xs={12}>*/}
                                       {/*    <Typography variant={"caption"}>{step.stepId}</Typography>*/}
                                       {/*</Grid>*/}
                                       <Grid item xs={12}>
                                       <Divider/>
                                       </Grid>
                                   </>
                                ))}

                                {/*{currentScriptItem?.scenarioScriptSectionSteps==null && currentScriptItem?.sectionSteps && currentScriptItem?.sectionSteps.length > 0 && currentScriptItem?.sectionSteps.map((step, innerIndex) => (*/}
                                {/*    <Grid item xs={12}>*/}
                                {/*            <TextField*/}
                                {/*                name={`step${innerIndex}`}*/}
                                {/*                label="Step"*/}
                                {/*                fullWidth*/}
                                {/*                variant={"standard"}*/}
                                {/*                value={step}*/}
                                {/*                onChange={(e) => {*/}
                                {/*                    const updatedScriptItems = [...scriptItems];*/}
                                {/*                    updatedScriptItems[currentIndex].sectionSteps[innerIndex] = e.target.value;*/}
                                {/*                    setScriptItems(updatedScriptItems);*/}
                                {/*                    updateScript(updatedScriptItems);*/}
                                {/*                }}*/}
                                {/*            />*/}

                                {/*    </Grid>*/}
                                {/*))}*/}
                            </Grid>
                        </MainCard>
                        </Grid>
                        </Grid>
                    </CardContent>
                </Card>
        </>
    );
};

export default ScenarioScriptEditorComponent;