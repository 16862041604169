import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField } from '@mui/material';

interface CompanyIndustryListProps {
  industries?: string[];
  onIndustryChange?: (industry: string) => void;
  industryValue?: string;
}

const defaultIndustries = [
  'Technology',
  'Finance',
  'Healthcare',
  'Education',
  'Manufacturing',
  'Retail',
  'Transportation',
  'Energy',
  'Real Estate',
  'Telecommunications',
];

const CompanyIndustryList: React.FC<CompanyIndustryListProps> = ({ industries = defaultIndustries, industryValue, onIndustryChange }) => {
  const [inputValue, setInputValue] = useState<string>(industryValue || '');
  const [selectedIndustry, setSelectedIndustry] = useState<string | null>(industryValue || null);

  useEffect(() => {
    if (industryValue) {
      setInputValue(industryValue);
    }
  }, [industryValue]);

  const handleInputChange = (event: React.ChangeEvent<{}>, newInputValue: string) => {
    setInputValue(newInputValue);
    if (onIndustryChange) {
      onIndustryChange(newInputValue);
    }
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string | null) => {
    setSelectedIndustry(newValue);
    if (onIndustryChange && newValue) {
      onIndustryChange(newValue);
    }
  };

  return (
    <Autocomplete
      options={industries}
      value={selectedIndustry}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select or type an industry"
          variant="outlined"
        />
      )}
    />
  );
};

export default CompanyIndustryList;