import React, {useCallback, useEffect, useState} from 'react';
import { ConductFile } from "../../interfaces/interfaces";
import FullScreenImageDialog from './FullScreenImageDialog';
import {Typography} from "@mui/material";

interface ScenarioImageProps {
    image: ConductFile;
    imageSize?: number;
    scenarioId: string;
    companyId: string;
    portalId?: string;
    conductId?: string;
}

const ConductFileImageDisplay: React.FC<ScenarioImageProps> = ({ image, imageSize, scenarioId, conductId, portalId, companyId }) => {
    const [open, setOpen] = useState(false);
    const token = localStorage.getItem('token');
    //const imageUrl = `${process.env.REACT_APP_REST_API_URL}/api/files/scenario/getfile/${token}/${companyId}/${scenarioId}/${image.fileId}`;
    const [imageUrl, setImageUrl] = useState<string | null>(null);

    const [error, setError] = useState<string | null>(null);

    const fetchImageUrl = useCallback(async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_REST_API_URL}/api/files/scenario/getfileurl/${token}/${companyId}/${scenarioId}/${image.fileId}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const url = await response.text();
            setImageUrl(url);
        } catch (error) {
            console.error('Failed to fetch video URL:', error);
            setError('Failed to load video URL');
        }
    }, [token, companyId, scenarioId, image.fileId]);

    useEffect(() => {
        fetchImageUrl();
    }, [fetchImageUrl]);
    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            {error ? (
                <Typography variant="body2" color="error">
                    {error}
                </Typography>
            ) : (
                imageUrl && (
                    <>
                       <img
                        style={{ maxWidth: imageSize ? `${imageSize}px` : '100%' }}
                        src={imageUrl}
                        alt={image.fileName}
                        onClick={handleClick}
                        />
                        <FullScreenImageDialog
                            open={open}
                            onClose={handleClose}
                            imageUrl={imageUrl}
                            name={image.shortName}
                            altText={image.fileName}
                        />
                    </>
                )
            )}
        </>
    );
};

export default ConductFileImageDisplay;