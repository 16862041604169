import React, {useEffect, useState} from 'react';
import { Grid, Typography, Button, Stack } from '@mui/material';
import ConductFileImageDisplay from './ConductFileImageDisplay';
import { ConductFile, Scenario } from "../../interfaces/interfaces";
import {AttachFileOutlined, NavigateBeforeOutlined, NavigateNextOutlined} from "@mui/icons-material";
import ConductFileVideoDisplay from "./ConductFileVideoDisplay";

interface ConductFileNavigatorProps {
    resources: string[];
    scenario: Scenario;
    portalId?: string;
    conductId?: string;
    title?: string;
}

const ConductFileNavigator: React.FC<ConductFileNavigatorProps> = ({ resources, scenario, title, portalId, conductId }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        setCurrentIndex(0);
    }, [resources]);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % resources.length);
    };

    const handlePrevious = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + resources.length) % resources.length);
    };

    const findConductFileById = (id: string, scenario: Scenario): ConductFile | undefined => {
        return scenario?.files?.find(file => file.fileId === id);
    };

    if (!resources || !scenario || !scenario.files) {
        return (
            <Typography variant="body2" color="error">
                Invalid input data
            </Typography>
        );
    }

    const conductFile = findConductFileById(resources[currentIndex], scenario);

    return (
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <Stack direction={"row"}  justifyContent={"flex-start"} alignItems={'center'} spacing={3}>
                    <AttachFileOutlined style={{ fontSize: 36 }} color={"primary"}/>
                    <Typography variant="overline" color={"textSecondary"}  gutterBottom>
                        {title ? title : 'Resources'}
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={4}>
                <Stack direction={"row"}  justifyContent={"flex-start"} alignItems={'center'} spacing={3}>
                    {conductFile && conductFile.fileName &&
                        <Typography variant="caption" color={"textSecondary"}  gutterBottom>
                            {conductFile.shortName}
                        </Typography>
                    }
                </Stack>
            </Grid>
            <Grid item xs={4}>
                {resources && resources.length > 0 &&
                    <Stack direction="row" justifyContent="flex-end" spacing={2}>
                        <Button variant={"contained"} size={"extraSmall"} onClick={handlePrevious} disabled={resources.length <= 1}>
                            <NavigateBeforeOutlined/>
                        </Button>
                        <Button variant={"contained"} size={"extraSmall"} onClick={handleNext} disabled={resources.length <= 1}>
                            <NavigateNextOutlined/>
                        </Button>
                    </Stack>
                }
            </Grid>
            <Grid item xs={12}>
                {conductFile && conductFile.fileName ? (
                    /\.(png|jpe?g)$/i.test(conductFile.fileName) ? (
                        <ConductFileImageDisplay
                            key={conductFile.fileId} // Add key prop to force re-render
                            image={conductFile}
                            scenarioId={scenario.scenarioId}
                            companyId={scenario.companyId}
                            portalId={portalId}
                            conductId={conductId}
                        />
                    ) : /\.(mp4)$/i.test(conductFile.fileName) ? (
                        <ConductFileVideoDisplay
                            key={conductFile.fileId} // Add key prop to force re-render
                            video={conductFile}
                            scenarioId={scenario.scenarioId}
                            companyId={scenario.companyId}
                            portalId={portalId}
                            conductId={conductId}
                        />
                    ) : (
                        <Typography variant="body2">{conductFile.fileName}</Typography>
                    )
                ) : (
                    <Typography variant="body2" color="error">
                        File not found
                    </Typography>
                )}
            </Grid>
        </Grid>
    );
};

export default ConductFileNavigator;