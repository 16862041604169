import React, {useEffect, useRef, useState} from "react";
import Box from "@mui/material/Box";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { green } from '@mui/material/colors';

import {
    Alert,
    Button, ButtonGroup,
    Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Divider,
    Grid,
    IconButton,
    InputAdornment, Menu, MenuItem,
    OutlinedInput, Stack, Tooltip,
    Typography
} from "@mui/material";
import {
    Annotation, AppUserProfile, Artifact, Conduct, ConductFile,
    ConductScriptItem,
    GameMessage,
    Participant,
    Scenario, ScenarioObjective,
    ScriptItem
} from "../../interfaces/interfaces";
import {useTheme} from "@mui/material/styles";
import QuickLoaderComponent from "../cards/QuickLoaderComponent";
import GuidedConductChatHistory from "../tabletop/GuidedConductChatHistory";
import MainCard from "../MainCard";
import {
    ArrowCircleUpOutlined,
    CheckBoxOutlineBlank,
    CheckBoxOutlineBlankOutlined,
    CheckBoxOutlined,
    CheckBoxTwoTone,
    ForkRightOutlined,
    IndeterminateCheckBox,
    NavigateBeforeOutlined,
    NavigateNextOutlined,
    ArrowDropDown,
    RefreshOutlined,
    CopyAll,
    AssistantOutlined,
    RecordVoiceOverOutlined,
    AttachFileOutlined,
    MicOffOutlined,
    MicOutlined,
    SummarizeOutlined,
    AssessmentOutlined,
    HelpCenterOutlined,
    InsertPageBreakOutlined,
    ChecklistOutlined,
    VisibilityOutlined,
    VisibilityOffOutlined //MyLocationOutlined, PlaceOutlined,
} from "@mui/icons-material";
import FinishedBanner from "../tabletop/FinishedBanner";
import FileList from "../FileList";
import ArtifactList from "../ArtifactList";
import DialogContentText from "@mui/material/DialogContentText";
import BranchOnInjectDialog from "../dialog/BranchOnInjectDialog";
import SectionFlow from "../tabletop/SectionFlow";
import ScenarioImageGridDisplay from "./ScenarioImageGridDisplay";
import ReactMarkdown from "react-markdown";
import {apiFetch} from "hooks/apiFetch";
import {useAudioRecorder} from "../../utils/audioRecorder";
import ObjectiveList from "./ObjectiveList";
import TextToSpeechComponent from "../shared/TextToSpeechComponent";
import ColorTextBox from "../icon/ColorTextBox";
import ConductFileNavigator from "./ConductFileNavigator";

interface Props {
    scenario: Scenario;
    chatId: string | null;
    conduct: Conduct;
    portalId?: string | null;
    actions?: string[];
    isTabletopStarted?: boolean;
    conductScriptItems?: ScriptItem[];
    participants?: Participant[];
    setConductScriptItems?: (conductScriptItems: ScriptItem[]) => void;
    selectedScriptId?: string | null;
    selectedActionId?: string | null;
    setSelectedConductScriptItem?: (conductScriptItem: ConductScriptItem) => void;
    onCompleteTabletop?: () => void;
    conductFiles?: ConductFile[];
    conductArtifacts?: Artifact[];
    userProfile: AppUserProfile | null | undefined;
    onConductUpdate?: (conduct: Conduct) => void;
    refreshConduct?: () => void;
}

const ScenarioTabletopChatComponent: React.FC<Props> = ({
                                                            scenario, chatId, conduct, actions, conductArtifacts,
                                                            conductScriptItems, setConductScriptItems, conductFiles,
                                                            isTabletopStarted, portalId, participants,
                                                            selectedScriptId, selectedActionId,
                                                            onCompleteTabletop, userProfile, setSelectedConductScriptItem,
                                                            onConductUpdate, refreshConduct
                                                        }) => {
    const [conversation, setConversation] = useState<GameMessage[]>([]);
    // const [listMessages, setListMessages] = useState<GameMessage[]>([]);
    const [message, setMessage] = useState("");
    // const textAreaRef = useAutoResizeTextArea();
    const bottomOfChatRef = useRef<HTMLDivElement>(null);
    const token = localStorage.getItem('token');
    const [isLoading, setLoading] = useState<boolean>(false);
    const [simChatBanner, setSimChatBanner] = useState<string>("");
    const theme = useTheme();
    //const latestScriptItem = conductScriptItems?.find(scriptItem => !scriptItem.sectionComplete);
    const [latestScriptItem, setLatestScriptItem] = useState<ScriptItem | undefined>(
        conductScriptItems?.find(scriptItem => !scriptItem.sectionComplete)
    );
    const latestScriptItemIndex = scenario.script.findIndex(item => item.id === latestScriptItem?.id);
    const latestScriptItemNumber = latestScriptItemIndex + 1; // This gives the position number
    const [conductActions, setConductActions] = useState<ConductScriptItem[]>([]);
    // const latestConductAction=  conductActions?.find(conductAction => !conductAction.isComplete);
    const [latestConductAction, setLatestConductActionState] = useState<ConductScriptItem | undefined>(
        conductActions.find(conductAction => !conductAction.isComplete)
    );
    const [facilitatorMessage, setFacilitatorMessage] = useState<GameMessage>();
    const [faciliatorLoading, setFacilitatorLoading] = useState<boolean>(false);

    const setLatestConductAction = (conductAction: ConductScriptItem | undefined) => {
        let needFacilitatorMessage = false;
        if (conduct.isVirtualAssistantOn) {
            needFacilitatorMessage = latestConductAction?.id !== conductAction?.id;
        }

        setLatestConductActionState(conductAction);

        if (conductAction && !conductAction.isComplete && conduct.isVirtualAssistantOn && needFacilitatorMessage) {
            try {
                const fetchData = async () => {
                    setFacilitatorLoading(true);
                    setFacilitatorMessage(undefined);
                    const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/portal/scenario/conduct/simfacilitator/' +
                        token + '/' + scenario.companyId + '/' + portalId + '/' + scenario.scenarioId + '/' + conductId + '/' + conductAction.id);
                    try {
                        const data = await response.json();
                        setFacilitatorMessage(data);
                    } catch (error) {
                        console.error(error);
                    }
                    setFacilitatorLoading(false);
                }
                fetchData();
            } catch (error) {
                console.error(error);
            }

        } else if (conductAction && conductAction.isComplete) {
            //find the facilitator message in conduct.facilitatorMessages
            const facilitatorMessage = conduct.facilitatorMessages?.find(message => message.conductScriptItem?.id === conductAction.id);
            if (facilitatorMessage) {
                setFacilitatorMessage(facilitatorMessage);
            }
        }
       if (setSelectedConductScriptItem && conductAction) {
           setSelectedConductScriptItem(conductAction);
       }
    };

    const [showSuggestTextField, setShowSuggestTextField] = useState(false);
    const [showSuggestAnalysisField, setShowAnalysisTextField] = useState(false);
    const [currentInjectSuggestion, setCurrentInjectSuggestion] = useState<string>();
    const [showInject, setShowInject] = useState(false);
    const [currentSuggestion, setCurrentSuggestion] = useState<string>();
    const [currentAnalysis, setCurrentAnalysis] = useState<string>();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    // const [showBranch, setShowBranch] = useState(false);
    const [showFiles, setShowFiles] = useState(false);
    // const [branchName, setBranchName] = useState("");
    const [branchDescription, setBranchDescription] = useState("");
    const [showFinishedBanner, setShowFinishedBanner] = useState(false);
    const [showSuggestionProgress, setShowSuggestionProgress] = useState(false);
    const [conductId] = useState<string | null>(conduct.conductId);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    let currentActionIndex = conductActions?.findIndex(item => item.id === latestConductAction?.id) ?? -1;
    // let currentSectionIndex = conductScriptItems?.findIndex(item => item.id === latestScriptItem?.id) ?? -1;
    // let totalSections = conductScriptItems?.length ?? 0;

    useEffect(() => {
        //console.log("ChatComp: Selected Script Id: ", selectedScriptId, "Selected Action Id: ", selectedActionId);
        if (selectedScriptId && selectedActionId) {
            // Find the ScriptItem with the id equal to scriptId
            const scriptItem = conductScriptItems?.find(item => item.id === selectedScriptId);
            //console.log("selectedScriptId: Script Item: ", scriptItem?.name);
            setLatestScriptItem(scriptItem);

            // Find the ConductScriptItem with the id equal to actionId
            const conductAction = conductActions.find(action => action.id === selectedActionId);
            //console.log("selectedActionId: Action Item: ", conductAction?.name);

            setLatestConductAction(conductAction);
        }
}   , [selectedScriptId, selectedActionId]);

    useEffect(() => {
        //console.log("Latest Script Item: ", latestScriptItem?.name);
        if (latestScriptItem && latestScriptItem.conductScriptItems) {
            setConductActions(latestScriptItem.conductScriptItems);
        }

    }, [latestScriptItem]);

    useEffect(() => {
        if (conduct) {
            // Update the state variables with the new conduct data
            const lsi = conduct.script.find(scriptItem => scriptItem.id === latestScriptItem?.id);
            setLatestScriptItem(lsi);
            setConversation(conduct.messages);
        }
    }, [conduct]);

    useEffect(() => {
        if (latestScriptItem) {
            setConductActions(latestScriptItem.conductScriptItems || []);
        }
    }, [latestScriptItem]);

    useEffect(() => {
        if (latestConductAction) {
            //console.log("Latest Conduct Action: ", latestConductAction.name);
            const matchingAction = conduct.script.flatMap(scriptItem => scriptItem.conductScriptItems || [])
                .find(conductAction => conductAction.id === latestConductAction.id);

            if (!matchingAction) {
                //check branches
                const branchItem = conduct.script.flatMap(scriptItem => scriptItem.conductScriptItems || [])
                    .find(conductAction => conductAction.branches && conductAction.branches.some(branch => branch.id === latestConductAction.id));
                if (branchItem) {
                    const branch = branchItem.branches?.find(branch => branch.id === latestConductAction.id);
                    if (branch) {
                        setLatestConductAction(branch);
                        return;
                    }
                }
            }
            //find the last conductScriptItem marked as complete (isComplete)
            // const matchingAction = conduct.script.flatMap(scriptItem => scriptItem.conductScriptItems || [])
            //     .reduce<ConductScriptItem | undefined>((acc, conductAction) => {
            //         if (conductAction.isComplete) {
            //             return conductAction;
            //         }
            //         return acc;
            //     }, undefined) || conduct.script.flatMap(scriptItem => scriptItem.conductScriptItems || [])
            //     .find(conductAction => !conductAction.isComplete);
            //console.log("Matching Action: ", matchingAction?.name);
             setLatestConductAction(matchingAction);
        }
    }, [conduct, latestConductAction]);


    useEffect(() => {
       // console.log("Latest Conduct Action: ", latestConductAction?.name);
        if (latestConductAction) {
           // console.log("setting conduct action to latest: ", latestConductAction.name);
            setLatestConductAction(latestConductAction);
        } else {
            const incompleteAction = conductActions.find(conductAction => !conductAction.isComplete);
           // console.log("finding last incomplete action: ", incompleteAction?.name);
            setLatestConductAction(incompleteAction || conductActions[conductActions.length - 1]);
        }
    }, [conductActions]);

    const [allObjectivesPassing, setAllObjectivesPassing] = useState(false);

// Function to check if all objectives are passing
    const checkObjectivesStatus = (objectives: ScenarioObjective[]) => {
        return objectives.every(objective => objective.isMet === true);
    };

// Update the status of the objectives whenever they change
    useEffect(() => {
        if (latestConductAction && latestConductAction.objectives) {
            setAllObjectivesPassing(checkObjectivesStatus(latestConductAction.objectives));
        }
    }, [latestConductAction]);

    useEffect(() => {
        //&&
        //             (!latestScriptItem || latestScriptItem === conductScriptItems?.[conductScriptItems?.length - 1]) &&
        //             (!latestConductAction || latestConductAction === conductActions[conductActions.length - 1])
        if ((!conductScriptItems || conductScriptItems?.every(item => item.sectionComplete)) ) {
            setShowFinishedBanner(true);
        } else {
            setShowFinishedBanner(false);
        }
        //console.log("show finished banner?", showFinishedBanner);

    }, [conductScriptItems, latestScriptItem, latestConductAction, conductActions]);

const handlePrevious = () => {
    const currentIndex = conductActions.findIndex(item => item.id === latestConductAction?.id);
    const currentScriptIndex = conductScriptItems?.findIndex(item => item.id === latestScriptItem?.id) ?? -1;
    //console.log("Current Index: ", currentIndex, "Current Script Index: ", currentScriptIndex);
    //console.log("Current Script Item: ", latestScriptItem?.name, "Current Conduct Action: ", latestConductAction?.name)
    if (currentIndex > 0) {
        setLatestConductAction(conductActions[currentIndex - 1]);
    } else if (currentIndex === 0 && conductScriptItems && currentScriptIndex > 0) {
        const previousScriptItem = conductScriptItems[currentScriptIndex - 1];
        //console.log("Previous Script Item: ", previousScriptItem?.name);
        setLatestScriptItem(previousScriptItem);
        if (previousScriptItem.conductScriptItems.length > 0) {
            setLatestConductAction(previousScriptItem.conductScriptItems[previousScriptItem.conductScriptItems.length - 1]);
        }
    } else if (currentIndex == -1 && conductScriptItems && currentScriptIndex > 0) {
        const previousScriptItem = conductScriptItems[currentScriptIndex - 1];
        //console.log("Previous Script Item: ", previousScriptItem?.name);
        setLatestScriptItem(previousScriptItem);
        if (previousScriptItem.conductScriptItems.length > 0) {
            setLatestConductAction(previousScriptItem.conductScriptItems[previousScriptItem.conductScriptItems.length - 1]);
        }
    }
    closeAll();
    setCurrentInjectSuggestion("");
    setCurrentSuggestion("");
    setCurrentAnalysis("");
};

    useEffect(() => {
        // Call the function passed in through props to notify the parent component of the change
        if (setConductScriptItems && conductScriptItems) {
            setConductScriptItems(conductScriptItems);
        }
    }, [conductScriptItems]);

const handleNext = () => {
    const currentIndex = conductActions.findIndex(item => item.id === latestConductAction?.id);
    const currentScriptIndex = conductScriptItems?.findIndex(item => item.id === latestScriptItem?.id) ?? -1;

    if (currentIndex < conductActions.length - 1) {
        setLatestConductAction(conductActions[currentIndex + 1]);
    } else if (currentIndex === conductActions.length - 1 && conductScriptItems && currentScriptIndex < conductScriptItems.length - 1) {
        const nextScriptItem = conductScriptItems[currentScriptIndex + 1];
        setLatestScriptItem(nextScriptItem);
        if (nextScriptItem.conductScriptItems.length > 0) {
            setLatestConductAction(nextScriptItem.conductScriptItems[0]);
        }
    }
    closeAll();
    setCurrentInjectSuggestion("");
    setCurrentSuggestion("");
    setCurrentAnalysis("");
};

    useEffect(() => {
        if (bottomOfChatRef.current) {
            // bottomOfChatRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [conversation]);


    useEffect(() => {
        // Check if showSuggestTextField is true and currentSuggestion is null or blank
        if (showInject && (!currentInjectSuggestion || currentInjectSuggestion.trim() === '')) {
            // Make the API call
            const fetchData = async () => {
                try {
                    setShowSuggestionProgress(true);

                    const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/portal/conduct/assistant/getactioninjectsuggestion/' + token + '/' +
                        scenario.companyId + '/' + portalId + '/' + latestConductAction?.notes + "." + latestConductAction?.instructions);
                    const data = await response.json();
                    setCurrentInjectSuggestion(data.suggestion);
                } catch (error) {
                    console.error('Error:', error);
                }
                setShowSuggestionProgress(false);

            };

            fetchData();
        }
    }, [showInject, currentInjectSuggestion]);

    useEffect(() => {
        // Check if showSuggestTextField is true and currentSuggestion is null or blank
        if (showSuggestAnalysisField && (!currentAnalysis || currentAnalysis.trim() === '')) {
            // Make the API call
            const fetchData = async () => {
                setShowSuggestionProgress(true);

                try {
                    const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/portal/conduct/assistant/getactionanalysis/' + token + '/' +
                        scenario.companyId + '/' + portalId + '/' + scenario?.scenarioId + '/' + conduct?.conductId + '/' + latestConductAction?.id);
                    const data = await response.json();
                    setCurrentAnalysis(data.suggestion);
                } catch (error) {
                    console.error('Error:', error);
                }
                setShowSuggestionProgress(false);

            };
            fetchData();
        }
    }, [showSuggestAnalysisField, currentAnalysis]);

    useEffect(() => {
        // Check if showSuggestTextField is true and currentSuggestion is null or blank
        if (showSuggestTextField && (!currentSuggestion || currentSuggestion.trim() === '')) {
            // Make the API call
            const fetchData = async () => {
                setShowSuggestionProgress(true);

                try {
                    const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/portal/conduct/assistant/getactionsuggestion/' + token + '/' +
                        scenario.companyId + '/' + portalId + '/' + latestConductAction?.notes + "." + latestConductAction?.instructions);
                    const data = await response.json();
                    setCurrentSuggestion(data.suggestion);
                } catch (error) {
                    console.error('Error:', error);
                }
                setShowSuggestionProgress(false);
            };
            fetchData();
        }
    }, [showSuggestTextField, currentSuggestion]);
    //used for when the component is "reloaded"
    useEffect(() => {
        fetchData();
    }, [chatId]);

    // const intervalRef = useRef<NodeJS.Timeout | null>(null);
    //
    // const setupInterval = () => {
    //     if (intervalRef.current) {
    //         clearInterval(intervalRef.current);
    //     }
    //     intervalRef.current = setInterval(() => {
    //         fetchData();
    //     }, 15000); // 15000 milliseconds = 15 seconds
    // };


    // useEffect(() => {
    //     setupInterval(); // Set up the interval when the component mounts
    //
    //     return () => {
    //         if (intervalRef.current) {
    //             clearInterval(intervalRef.current); // Cleanup interval on component unmount
    //         }
    //     };
    // }, []);

    const fetchData = async () => {
       if (conduct && !conduct.isComplete && conduct.participants && conduct.participants.length > 1) {
           try {
               // setLoading(true);
               // setSimChatBanner("Tabletop feed being loaded....");
               const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/portal/conduct/resumetabletopchat/' + token + '/' +
                   scenario.companyId + '/' + portalId + '/' + scenario.scenarioId + '/' + conductId + '/' + chatId);
               const data = await response.json();
               setConversation(data);
               // setSimChatBanner("");
               // setLoading(false);

               // console.log(data);
           } catch (error) {
               //console.error(error);
               setSimChatBanner("");
               setLoading(false);
           }
           //setupInterval();
       }
    };

    const sendMessage = (actionId: string) => {
        return async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault();
            setMessage("");
            setLoading(true);
            setSimChatBanner("Sending message....");
            if (selectedFile && selectedFile.size > 0) {
                handleFileUpload();
            } else {
                try {
                    const callURL = process.env.REACT_APP_REST_API_URL + '/api/portal/conduct/assistant/action/message/' + token + '/' + scenario.companyId + '/' + portalId + '/' + scenario.scenarioId + '/' + conductId + '/' + actionId + '/' + chatId;
                    const response = await apiFetch(callURL, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({text: message, isPrivateMessage: isPrivateMessage}),
                    });

                    if (response.ok) {
                        const data = await response.json();
                        setConversation(data);
                        closeAll();
                    } else {
                        console.error(response);
                    }
                } catch (error: any) {
                    console.error(error);
                }
            }
            setLoading(false);
        };
    };

    const handleKeypress = (actionId: string) => {
        return (e: any) => {
            // It's triggers by pressing the enter key
            if (e.keyCode == 13 && !e.shiftKey) {
                sendMessage(actionId)(e);
                e.preventDefault();
            }
        };
    };

    async function handleAnnotation(annotation: Annotation) {
       // console.log("Annotation to be added to message: ", annotation);
        const updateURL = `${process.env.REACT_APP_REST_API_URL}/api/portal/conduct/annotate/${token}/${scenario.companyId}/${portalId}/${scenario.scenarioId}/${conductId}`;
        try {
            const response = await fetch(updateURL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(annotation),
            });
            const returnAnnotation: Annotation = await response.json();
            setConversation(prevConversation => {
                return prevConversation.map((msgItem: GameMessage) => {
                    if (msgItem.id === returnAnnotation.gameMessageId) {
                        return {
                            ...msgItem,
                            annotations: msgItem.annotations ? [...msgItem.annotations, returnAnnotation] : [returnAnnotation]
                        };
                    } else {
                        return msgItem;
                    }
                });
            });
        } catch (error) {
            console.error("Error updating scenario:", error);
        }
    }

    async function handleConductScriptItemChange(conductScriptItem: ConductScriptItem) {
        //console.log("ConductScriptItem to be added to message: ", conductScriptItem.id);
        const updateURL = `${process.env.REACT_APP_REST_API_URL}/api/portal/scenario/tabletop/completescriptitem/${token}/${scenario.companyId}/${portalId}/${scenario.scenarioId}/${conductId}`;
        try {
            const response = await fetch(updateURL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(conductScriptItem),
            });
            const newGameMessage: GameMessage = await response.json();
            if (setConductScriptItems) {
                // @ts-ignore
                setConductScriptItems((prevItems: ConductScriptItem[]) =>
                    prevItems.map(item =>
                        item.id === conductScriptItem.id ? {...conductScriptItem} : item
                    )
                );
            }
            if (refreshConduct) {
                refreshConduct();
            }
            setLatestConductAction(conductScriptItem);
            setConversation(prevConversation => [...prevConversation, newGameMessage]);
            setShowSuggestTextField(false);
            setCurrentSuggestion("");
            handleNext();
        } catch (error) {
            console.error("Error updating scenario:", error);
        }
    }

    async function handleBranch(conductScriptItemId: string, branchName: string, branchDescription: string) {
       // console.log("Branching: ", conductScriptItemId);
        setLoading(true);
        setSimChatBanner("Branching....");
        const updateURL = `${process.env.REACT_APP_REST_API_URL}/api/portal/conduct/branch/${token}/${scenario.companyId}/${portalId}/${scenario.scenarioId}/${conductId}/${conductScriptItemId}/${branchName}/${branchDescription}`;
        const response = await fetch(updateURL);
        const newConductScriptItem: ConductScriptItem = await response.json();
        // Find the parent ConductScriptItem
        const parentConductScriptItem = conductActions.find(item => item.id === newConductScriptItem.parentId);

        // If the parent ConductScriptItem is found
        if (parentConductScriptItem) {
            // If the parent ConductScriptItem doesn't have a branches array, initialize it
            if (!parentConductScriptItem.branches) {
                parentConductScriptItem.branches = [];
            }

            // Add newConductScriptItem to the branches array of the parent ConductScriptItem
            parentConductScriptItem.branches.push(newConductScriptItem);

            // setBranchName("");
            setBranchDescription("");
        }
        setLoading(false);
        setLatestConductAction(newConductScriptItem);

        handleClose();
    }

    async function handleSectionComplete(sectionId: string) {
        //console.log("Section to be marked complete: ", sectionId);

        // @ts-ignore
        setConductScriptItems((prevItems: ConductScriptItem[]) =>
            prevItems.map(item =>
                item.id === sectionId ? {...item, sectionComplete: true} : item
            )
        );

        const updateURL = `${process.env.REACT_APP_REST_API_URL}/api/portal/scenario/tabletop/completesection/${token}/${scenario.companyId}/${portalId}/${scenario.scenarioId}/${conductId}/${sectionId}`;
        const response = await fetch(updateURL);
        const newGameMessage: GameMessage = await response.json();
        setConversation(prevConversation => [...prevConversation, newGameMessage]);

// Find the index of the current section
        const currentSectionIndex = conductScriptItems?.findIndex(item => item.id === sectionId);

// If the current section is found and it's not the last item in the array
        if (currentSectionIndex !== undefined && currentSectionIndex < (conductScriptItems?.length ?? 0) - 1) {
            // Get the next section
            const nextSection = conductScriptItems?.[currentSectionIndex + 1];
            if (nextSection) {
                setLatestScriptItem(nextSection);
            }
        }
    }

    const handleCompleteChange = (id: string) => {
        return (event: React.MouseEvent<HTMLElement>) => {
            // Create a new array with the updated step
            const updatedScriptItems = conductActions.map(item => {
                if (item.id === id) {
                    // This is the item we want to update
                    const updatedItem = {
                        ...item,
                        isComplete: !item.isComplete, // Toggle the isComplete property
                        isSuccess: !item.isSuccess // Toggle the isComplete property
                    };

                    // Call onConductScriptItemChange with the updated item
                    handleConductScriptItemChange(updatedItem);

                    return updatedItem;
                } else if (item.branches) {
                    // Loop through the branches of the item
                    const updatedBranches = item.branches.map(branch => {
                        if (branch.id === id) {
                            // This is the branch we want to update
                            const updatedBranch = {
                                ...branch,
                                isComplete: !branch.isComplete, // Toggle the isComplete property
                                isSuccess: !branch.isSuccess // Toggle the isComplete property
                            };

                            // Call onConductScriptItemChange with the updated branch
                            handleConductScriptItemChange(updatedBranch);

                            return updatedBranch;
                        } else {
                            // This is not the branch we want to update, so return it as is
                            return branch;
                        }
                    });

                    // Return the item with the updated branches
                    return {...item, branches: updatedBranches};
                } else {
                    // This is not the item we want to update, so return it as is
                    return item;
                }
            });

            // Update the state with the new array
            if (setConductScriptItems) {
                setConductActions(updatedScriptItems);
            }
            if (refreshConduct) {
                refreshConduct();
            }
            handleClose();
        };
    };

    const handleSkipChange = (id: string) => {
        return (event: React.MouseEvent<HTMLElement>) => {
            // Create a new array with the updated step
            const updatedScriptItems = conductActions.map(item => {
                if (item.id === id) {
                    // This is the item we want to update
                    const updatedItem = {
                        ...item,
                        isComplete: !item.isComplete, // Toggle the isComplete property
                        isSkipped: !item.isSkipped, // Toggle the isComplete property
                    };

                    // Call onConductScriptItemChange with the updated item
                    handleConductScriptItemChange(updatedItem);

                    return updatedItem;
                } else {
                    // This is not the item we want to update, so return it as is
                    return item;
                }
            });

            // Update the state with the new array
            if (setConductScriptItems) {
                setConductActions(updatedScriptItems);
            }
            handleClose();
        };
    };

    const handleFailChange = (id: string) => {
        return (event: React.MouseEvent<HTMLElement>) => {
            // Create a new array with the updated step
            const updatedScriptItems = conductActions.map(item => {
                if (item.id === id) {
                    // This is the item we want to update
                    const updatedItem = {
                        ...item,
                        isComplete: !item.isComplete, // Toggle the isComplete property
                        isFailed: !item.isFailed, // Toggle the isComplete property
                    };

                    // Call onConductScriptItemChange with the updated item
                    handleConductScriptItemChange(updatedItem);

                    return updatedItem;
                } else {
                    // This is not the item we want to update, so return it as is
                    return item;
                }
            });

            // Update the state with the new array
            if (setConductScriptItems) {
                setConductActions(updatedScriptItems);
            }
            handleClose();
        };
    };

    function handleBranchNavigation(conductActionId: string, branchActionId: string) {
        //console.log("Branching: ", conductActionId, branchActionId, latestConductAction?.id);
        if (latestConductAction && latestConductAction.branches) {
            //console.log("TEST Branching: ", conductActionId, branchActionId, latestConductAction?.id);
            const branch = latestConductAction.branches.find(branch => branch.id === branchActionId);
            if (branch) {
                setLatestConductAction(branch);
            }
        }
    }

    function handleBranchPrevious() {
        if (latestConductAction && latestConductAction.parentId) {
            const parent = conductActions.find(action => action.id === latestConductAction.parentId);
            if (parent) {
                setLatestConductAction(parent);
            }
        }
    }

    function handleTabletopComplete() {
       // console.log("Tabletop complete");
        if (onCompleteTabletop) {
            onCompleteTabletop();
        }
    }

    function handleVerifyAction(id: string) {
        //find the gamemessage by id and toggle the isVerified flag
        const updatedMessage = conversation.find(history => history.id === id);
        if (updatedMessage) {
            let token = localStorage.getItem('token');
            const updateURL = `${process.env.REACT_APP_REST_API_URL}/api/portal/scenario/conduct/toggleverifyaction/${token}/${scenario.companyId}/${portalId}/${scenario.scenarioId}/${conductId}/${id}`;
            fetch(updateURL);

            updatedMessage.isVerified = !updatedMessage.isVerified;
            setConversation(prevState => prevState.map(history => history.id === id ? updatedMessage : history));
        }

    }

    function handleDownload(file: ConductFile) {
        const url = process.env.REACT_APP_REST_API_URL + '/api/files/conduct/getfile/' + token + '/' + file.companyId + '/' + portalId + '/' + scenario.scenarioId + '/' + conductId + '/' + file.fileId;
        window.open(url, '_blank');
    }

    function handleMessageDownload(file: ConductFile) {
        const url = process.env.REACT_APP_REST_API_URL + '/api/files/conduct/message/getfile/' + token + '/' + file.companyId + '/' + portalId + '/' + scenario.scenarioId + '/' + conductId + '/' + file.fileId;
        window.open(url, '_blank');
    }

    const [currentArtifact, setCurrentArtifact] = useState<Artifact | null>(null);
    const [openArtifactDialog, setOpenArtifactDialog] = useState(false);
    function handleArtifactDownload(artifact: Artifact) {
        // const url = process.env.REACT_APP_REST_API_URL + '/api/files/conduct/getartifact/' + token + '/' + artifact.companyId + '/' + portalId + '/' + scenario.scenarioId + '/' + conductId + '/' + artifact.artifactId;
        // window.open(url, '_blank');
        console.log("Artifact to download: ", artifact);
        setCurrentArtifact(artifact);
        setOpenArtifactDialog(true);
    }

    let isLatestConductActionBranch = (latestConductAction && latestConductAction.parentId !== null);

    function closeAll() {
        setShowSuggestTextField(false);
        setShowFiles(false);
        setShowInject(false);
        setShowAnalysisTextField(false);
    }

    function handleStarChange(gameMessageId: string) {
        const updateURL = `${process.env.REACT_APP_REST_API_URL}/api/portal/conduct/star/${token}/${scenario.companyId}/${portalId}/${scenario.scenarioId}/${conductId}/${gameMessageId}`;
        //get request
        fetch(updateURL)
            .then(response => response.json())
            .then(updatedGameMessage => {
                setConversation(prevConversation => {
                    return prevConversation.map((msgItem: GameMessage) => {
                        if (msgItem.id === gameMessageId) {
                            return updatedGameMessage;
                        } else {
                            return msgItem;
                        }
                    });
                });
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    function handleInsightsChange(gameMessageId: string, insights: string) {
        //post to an API and send over both the gameMessageId and the insights
        const updateURL = `${process.env.REACT_APP_REST_API_URL}/api/portal/conduct/insights/${token}/${scenario.companyId}/${portalId}/${scenario.scenarioId}/${conductId}/${gameMessageId}`;
        fetch(updateURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({insights: insights}),
        })
            .then(response => response.json())
            .then(updatedGameMessage => {
                setConversation(prevConversation => {
                    return prevConversation.map((msgItem: GameMessage) => {
                        if (msgItem.id === gameMessageId) {
                            return updatedGameMessage;
                        } else {
                            return msgItem;
                        }
                    });
                });
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    function handleQuestionChange(gameMessageId: string, message: string) {
        //post to an API and send over both the gameMessageId and the insights
        const updateURL = `${process.env.REACT_APP_REST_API_URL}/api/portal/conduct/question/${token}/${scenario.companyId}/${portalId}/${scenario.scenarioId}/${conductId}/${gameMessageId}`;
        fetch(updateURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({question: message}),
        })
            .then(response => response.json())
            .then(updatedGameMessage => {
                setConversation(prevConversation => {
                    return prevConversation.map((msgItem: GameMessage) => {
                        if (msgItem.id === gameMessageId) {
                            return updatedGameMessage;
                        } else {
                            return msgItem;
                        }
                    });
                });
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const [branchOpenDialog, setBranchOpenDialog] = useState(false);

let injectTextArray = currentInjectSuggestion?.split(/(?=\b\d+\.)/g);
// let currentSuggestionArray = currentSuggestion?.split(/(?=\b\d+\.)/g);
    let currentSuggestAnalysisArray = currentAnalysis?.split(/(?=\b\d+\.)/g);

    function createBranchFromDialog(name: string, description: string) {
        setBranchOpenDialog(false);
        if (latestConductAction) {
            if (name === "") {
                name = "Branch for Conduct Action: " + latestConductAction.name;
            }
            if (description === "") {
                description = "Branch created from Conduct Action: " + latestConductAction.name;
            }
            handleBranch(latestConductAction.id, name, description);
        }
    }


    function handleCloseSuggestDialog() {
        setShowSuggestTextField(false);
       // setCurrentSuggestion("");
    }

    function handleCloseInjectDialog() {
        setShowInject(false);
       // setCurrentInjectSuggestion("");
    }

    function handleFlagChange(gameMessageId: string, flag: string) {
        const updateURL = `${process.env.REACT_APP_REST_API_URL}/api/portal/conduct/step/flag/${token}/${scenario.companyId}/${portalId}/${scenario.scenarioId}/${conductId}/${gameMessageId}/${flag}`;
        //get request
        fetch(updateURL)
            .then(response => response.json())
            .then(updatedGameMessage => {
                setConversation(prevConversation => {
                    return prevConversation.map((msgItem: GameMessage) => {
                        if (msgItem.id === gameMessageId) {
                            return updatedGameMessage;
                        } else {
                            return msgItem;
                        }
                    });
                });
            })
            .catch((error) => {
                console.error('Error:', error);
            });

    }

    const [openInstructionsDialog, setOpenInstructionsDialog] = useState(false);
    const [openObjectivesDialog, setOpenObjectivesDialog] = useState(false);

    function handleCloseSuggestAnalysisDialog() {
        setShowAnalysisTextField(false);

    }

    function handleTimerChange(gameMessageId: string, timeInMinutes: number, time: string) {
        const updateURL = `${process.env.REACT_APP_REST_API_URL}/api/portal/conduct/step/timer/${token}/${scenario.companyId}/${portalId}/${scenario.scenarioId}/${conductId}/${gameMessageId}/${timeInMinutes}/${time}`;
        //get request
        fetch(updateURL)
            .then(response => response.json())
            .then(updatedGameMessage => {
                setConversation(prevConversation => {
                    return prevConversation.map((msgItem: GameMessage) => {
                        if (msgItem.id === gameMessageId) {
                            return updatedGameMessage;
                        } else {
                            return msgItem;
                        }
                    });
                });
            })
            .catch((error) => {
                console.error('Error:', error);
            });

    }

    const [isVirtualAssistantOn] = useState(conduct.isVirtualAssistantOn);

    // const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setIsVirtualAssistantOn(event.target.checked);
    //     // set the conduct.isVirtualAssistantOn in the passed in conduct to the new value
    //     conduct.isVirtualAssistantOn = event.target.checked;
    //     // Call the parent component function to update the conduct
    //     if (onConductUpdate) {
    //         onConductUpdate(conduct);
    //     }
    // };


    const {
        startRecording,
        stopRecording,
        isRecording,
        showRecordingBanner,
        recordingBannerLabel
    } = useAudioRecorder(token, scenario, undefined, setMessage);


    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [openFileDialog, setOpenFileDialog] = useState(false);
    const [currentId, setCurrentId] = useState<string | null>(null);

    const openMessageSendFile = (id: string) => {
        setCurrentId(id);
        setOpenFileDialog(true);
    };

    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
        }
    };

    const handleFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            setSelectedFile(event.dataTransfer.files[0]);
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const handleFileUpload = async () => {
        if (selectedFile && currentId) {
            const formData = new FormData();
            formData.append('file', selectedFile);
            if (message) {
                formData.append('message', message);
            }

            try {
                const response = await fetch(`${process.env.REACT_APP_REST_API_URL}/api/files/upload/conduct/message/${token}/${scenario.companyId}/${portalId}/${scenario.scenarioId}/${conductId}/${currentId}`, {
                    method: 'POST',
                    body: formData,
                });

                if (response.ok) {
                    console.log('File uploaded successfully');
                    const data = await response.json();
                    setConversation(data);
                    setOpenFileDialog(false);
                    setSelectedFile(null);
                } else {
                    console.error('File upload failed');
                }
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
    };

    const handleCloseFileDialog = () => {
        setOpenFileDialog(false);
        setSelectedFile(null);
    };


    function handleObjectiveUpdate(id: string, objectives: ScenarioObjective[]) {
        if (onConductUpdate && conduct && conduct.script) {
            // Find the step in the conduct matching the id and update the objectives for that step
            conduct.script = conduct.script.map(step => {
                if (step.conductScriptItems) {
                    step.conductScriptItems = step.conductScriptItems.map(item => {
                        if (item.id === id) {
                            item.objectives = objectives;
                            if (latestConductAction) {
                                const updatedAction = { ...latestConductAction, objectives: objectives };
                                setLatestConductAction(updatedAction);
                            }                        }
                        return item;
                    });
                }
                return step;
            });
            onConductUpdate(conduct);
        }
    }

        let overviewAndTalkingPoints = "";

        if (isVirtualAssistantOn) {
            overviewAndTalkingPoints = facilitatorMessage?.answer || "";
        } else {
            if (latestConductAction && latestConductAction.notes && latestConductAction.notes.trim() !== "") {
                overviewAndTalkingPoints += "Overview: " + latestConductAction.notes;
            }

            if (latestConductAction && latestConductAction.talkingPoint && latestConductAction.talkingPoint.trim() !== "") {
                if (overviewAndTalkingPoints !== "") {
                    overviewAndTalkingPoints += " ";
                }
                overviewAndTalkingPoints += "Talking Points: " + latestConductAction.talkingPoint;
            }
        }

    function handleUpdateChat(updatedGameMessage: GameMessage) {
        const updateURL = `${process.env.REACT_APP_REST_API_URL}/api/portal/conduct/updategamemessage/${token}/${scenario.companyId}/${portalId}/${scenario.scenarioId}/${conductId}/${updatedGameMessage.id}`;
        //api post call to update the game message. you are sending the updatedGameMessage
        fetch(updateURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedGameMessage),
        })
            .then(response => response.json())
            .then(updatedGameMessage => {
                setConversation(prevConversation => {
                    return prevConversation.map((msgItem: GameMessage) => {
                        if (msgItem.id === updatedGameMessage.id) {
                            return updatedGameMessage;
                        } else {
                            return msgItem;
                        }
                    });
                });
            });
    }

        const [openOverviewDialog, setOpenOverviewDialog] = useState(false);
        const [openTalkingPointsDialog, setOpenTalkingPointsDialog] = useState(false);

    const [showAssistantToggle, setShowAssistantToggle] = useState(false);

    const handleShowAssistantToggle = () => {
        setShowAssistantToggle(prevShowDetails => !prevShowDetails);
    };

    // async function handleMarkCurrent() {
    //     if (latestConductAction) {
    //         try {
    //             const updateURL = `${process.env.REACT_APP_REST_API_URL}/api/portal/scenario/tabletop/markcurrentscriptitem/${token}/${scenario.companyId}/${portalId}/${scenario.scenarioId}/${conductId}/${latestConductAction.id}`;
    //             const response = await fetch(updateURL);
    //             if (response.ok) {
    //                 // Set all conductScriptItems' isCurrent to false
    //                 const updatedScriptItems = conductScriptItems?.map(scriptItem => {
    //                     if (scriptItem.conductScriptItems) {
    //                         scriptItem.conductScriptItems = scriptItem.conductScriptItems.map(item => ({
    //                             ...item,
    //                             isCurrent: false
    //                         }));
    //                     }
    //                     return scriptItem;
    //                 }) || [];
    //
    //                 // Update the state with the new array
    //                 if (setConductScriptItems) {
    //                     setConductScriptItems(updatedScriptItems);
    //                 }
    //
    //                 // Set the latestConductAction's isCurrent to true
    //                 const updatedAction = { ...latestConductAction, isCurrent: true };
    //                 setLatestConductAction(updatedAction);
    //             } else {
    //                 console.error("Failed to mark current");
    //             }
    //         } catch (Exception) {
    //             console.log("Error marking current:", Exception);
    //         }
    //     }
    // }

    // async function handleGoCurrent() {
    //
    //     if (refreshConduct) {
    //         refreshConduct();
    //         if (conductScriptItems) {
    //             for (const scriptItem of conductScriptItems) {
    //                 if (scriptItem.conductScriptItems) {
    //                     const currentItem = scriptItem.conductScriptItems.find(item => item.isCurrent);
    //                     if (currentItem) {
    //                         setLatestScriptItem(scriptItem);
    //                         setLatestConductAction(currentItem);
    //                         break;
    //                     }
    //                 }
    //             }
    //         }
    //
    //     }
    // }

    const [isPrivateMessage , setIsPrivateMessage] = useState(false);

    const togglePrivateMessage = () => {
        setIsPrivateMessage(!isPrivateMessage);
    }
    return (
        <>
            <Dialog open={openFileDialog} onClose={() => setOpenFileDialog(false)}>
                <DialogTitle>Upload File</DialogTitle>
                <DialogContent>
                    <Box
                        onDrop={handleFileDrop}
                        onDragOver={handleDragOver}
                        border="1px dashed grey"
                        p={2}
                        textAlign="center"
                    >
                        <input type="file" onChange={handleFileSelect} style={{ display: 'none' }} id="fileInput" />
                        <label htmlFor="fileInput">
                            <Typography>Drag & drop a file here, or click to select a file</Typography>
                        </label>
                    </Box>
                    {selectedFile && <Typography mt={2}>Selected file: {selectedFile.name}</Typography>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseFileDialog}>Cancel</Button>
                    <Button onClick={() => setOpenFileDialog(false)} disabled={!selectedFile}>Ok</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openArtifactDialog}
                fullWidth={true}
                onClose={() => setOpenArtifactDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Artifact Details"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {currentArtifact && currentArtifact.artifactText && currentArtifact?.artifactText.split('\n').map((line, index) => (
                            <React.Fragment key={index}>
                                {line}
                                <br />
                            </React.Fragment>
                        ))}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenArtifactDialog(false)} color="primary" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <BranchOnInjectDialog
                createBranchFromInject={createBranchFromDialog}
                branchDescription={branchDescription}
                open={branchOpenDialog}
                handleClose={() => setBranchOpenDialog(false)}
                conductScriptItem={latestConductAction}
            />

            <Dialog fullWidth={true} open={showSuggestTextField} onClose={handleCloseSuggestDialog}>
                <DialogTitle>Suggestions</DialogTitle>
                <DialogContent>
                    <>
                        {showSuggestionProgress &&
                            <Grid container={true} spacing={1}>
                                <Grid item xs={12}>
                                    <QuickLoaderComponent loadBanner={"Suggestion Assistant is working on your request..."}/>
                                </Grid>
                            </Grid>
                        }
                        <Card>
                            <CardContent>
                                <Grid container={true} spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h5" color="textSecondary"
                                                    gutterBottom>
                                            Discussion Suggestions: {latestConductAction && latestConductAction.name}
                                        </Typography>
                                    </Grid>
                                    <ReactMarkdown key={"suggestion"} children={currentSuggestion} />
                                </Grid>
                            </CardContent>
                        </Card>
                    </>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSuggestDialog}>Close</Button>
                </DialogActions>
            </Dialog>

            <Dialog fullWidth={true} open={showSuggestAnalysisField} onClose={handleCloseSuggestAnalysisDialog}>
                <DialogTitle>Analysis</DialogTitle>
                <DialogContent>
                    <>
                        {showSuggestionProgress &&
                            <Grid container={true} spacing={1}>
                                <Grid item xs={12}>
                                    <QuickLoaderComponent loadBanner={"Suggestion Assistant is working on your request..."}/>
                                </Grid>
                            </Grid>
                        }
                        <Card>
                            <CardContent>
                                <Grid container={true} spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h5" color="textSecondary"
                                                    gutterBottom>
                                            Analysis: {latestConductAction && latestConductAction.name}
                                        </Typography>
                                    </Grid>

                                    {currentSuggestAnalysisArray && currentSuggestAnalysisArray?.filter(line => line && line.trim() !== '').map((line, index) => (
                                        <React.Fragment key={index}>
                                            <Grid item xs={12}>
                                                <>
                                                    {line && line.length>0 && <ReactMarkdown key={index} children={line} /> }
                                                </>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                        </React.Fragment>
                                    ))}
                                </Grid>
                            </CardContent>
                        </Card>
                    </>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSuggestAnalysisDialog}>Close</Button>
                </DialogActions>
            </Dialog>

            <Dialog fullWidth={true} open={showInject} onClose={handleCloseInjectDialog}>
                <DialogTitle>Injects</DialogTitle>
                <DialogContent>
                    <>
                        {showSuggestionProgress &&
                            <Grid container={true} spacing={1}>
                                <Grid item xs={12}>
                                    <QuickLoaderComponent loadBanner={"Suggestion Assistant is working on your request..."}/>
                                </Grid>
                            </Grid>
                        }
                        <Card>
                            <CardContent>
                                <Grid container={true} spacing={2}>
                                    <Grid item xs={12}>
                                        {latestConductAction &&
                                            <Typography variant="h5" color="textSecondary"
                                                    gutterBottom>
                                            Suggested Injects: {latestConductAction.name}
                                            </Typography>
                                        }
                                    </Grid>

                                    {injectTextArray && injectTextArray?.filter(line => line && line.trim() !== '').map((line, index) => (
                                        <React.Fragment key={index}>
                                            <Grid item xs={12}>
                                                <>
                                                    {line && line.length>0 && <ReactMarkdown key={index} children={line} /> }
                                                </>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Stack direction="row" spacing={1} justifyContent={"flex-end"}>
                                                    <Tooltip title={"Copy this suggestion to the Assistant chat"}>
                                                        <Button variant={"outlined"} size={"small"} onClick={() => {
                                                            setMessage(line);
                                                        }}><CopyAll/> </Button>
                                                    </Tooltip>
                                                    <Tooltip title={"Add this inject as a branch"}>
                                                        <Button variant={"outlined"} size={"small"} onClick={() => {
                                                            setBranchDescription(line);
                                                            setBranchOpenDialog(true);
                                                        }}><ForkRightOutlined/> Branch</Button>
                                                    </Tooltip>
                                                </Stack>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                        </React.Fragment>
                                    ))}
                                </Grid>
                            </CardContent>
                        </Card>
                    </>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseInjectDialog}>Close</Button>
                </DialogActions>
            </Dialog>

            <Box style={{overflow: 'auto', minHeight: window.innerHeight}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        { showFinishedBanner && (
                            <>
                                <Divider/>
                                <Card>
                                    <CardContent>
                                        <FinishedBanner/>
                                        {}
                                        <Button
                                            variant="contained"
                                            color="success"
                                            disabled={!isTabletopStarted}
                                            onClick={() => handleTabletopComplete()}
                                        >
                                            Complete Tabletop
                                        </Button>
                                    </CardContent>
                                </Card>
                            </>
                        )}
                        {latestScriptItem && (
                          <>
                            <Grid item xs={12}>
                                <Grid container={true} spacing={1}>
                                    <Grid item xs={12}>
                                        <SectionFlow sections={conduct.script} currentSection={latestScriptItem}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <MainCard title={
                                                    <Accordion defaultExpanded={true} sx={{ boxShadow: 'none', border: 'none' }} >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            sx={{ boxShadow: 'none', border: 'none' }}
                                                        >
                                                            <Typography variant="h5" component="div" sx={{ flexGrow: 1, border: 'none'  }}>
                                                                <Stack direction="row" alignItems="center" justifyContent={"flex-start"} spacing={1}>
                                                                    {latestScriptItem?.sectionComplete && <CheckBoxOutlined color="success" />}
                                                                    {latestScriptItem?.section ? (
                                                                        <Typography variant="h3" sx={{ textDecoration: 'underline' }}>{latestScriptItem.section}</Typography>
                                                                    ) : (
                                                                        <Typography variant="h4">Section</Typography>
                                                                    )}
                                                                    {latestScriptItem?.background && <TextToSpeechComponent text={latestScriptItem.background} />}
                                                                    {/*{userProfile && userProfile.role != 'PortalViewParticipant' &&*/}
                                                                    {/*    !latestScriptItem.sectionComplete && conductActions.every(item => item.isComplete) && (*/}
                                                                    {/*        <Button*/}
                                                                    {/*            variant="contained"*/}
                                                                    {/*            color="success"*/}
                                                                    {/*            disabled={!isTabletopStarted}*/}
                                                                    {/*            onClick={() => handleSectionComplete(latestScriptItem.id)}*/}
                                                                    {/*        >*/}
                                                                    {/*            Complete Section*/}
                                                                    {/*        </Button>*/}
                                                                    {/*    )}*/}
                                                                </Stack>
                                                            </Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails sx={{ boxShadow: 'none', border: 'none' }}>
                                                            <Grid container={true} spacing={1}>
                                                                <Grid item xs={latestScriptItem?.images ? 2 : 1} justifyContent={"flex-start"}>
                                                                    <Grid container={true} spacing={1}>
                                                                        <Grid item xs={12}>
                                                                            <Stack direction="row" spacing={1} justifyContent={"flex-start"}>
                                                                                {latestScriptItem?.images ? (
                                                                                    <Grid container={true} spacing={1}>
                                                                                        <ScenarioImageGridDisplay images={latestScriptItem.images} imageSize={175} />
                                                                                    </Grid>
                                                                                ) : (
                                                                                    <ColorTextBox size={100} text={"" + latestScriptItemNumber} />
                                                                                )}
                                                                            </Stack>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={latestScriptItem?.images ? 10 : 11}>
                                                                    <Grid container={true} spacing={1}>
                                                                        <Grid item={true} xs={12}>
                                                                            {latestScriptItem?.background?.split('\n').map((background, index) => (
                                                                                <Typography key={index} variant="h5" gutterBottom>
                                                                                    <ReactMarkdown key={index} children={background} />
                                                                                </Typography>
                                                                            ))}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </AccordionDetails>
                                                    </Accordion>

                                                }>
                                                    {latestConductAction && (
                                                        <>
                                                                <Grid container={true} spacing={1}>
                                                                    <Grid item xs={4}>
                                                                        <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems={"center"}>
                                                                            {latestConductAction.isSuccess && (
                                                                                <CheckBoxOutlined color="success"/>
                                                                            )}
                                                                            {latestConductAction.isSkipped && (
                                                                                <IndeterminateCheckBox color="secondary"/>
                                                                            )}
                                                                            {latestConductAction.isFailed && (
                                                                                <CheckBoxOutlineBlank color="error"/>
                                                                            )}
                                                                            <Typography variant="h3" sx={{ textDecoration: 'underline' }}>{`${latestConductAction.name}`}</Typography>
                                                                            {overviewAndTalkingPoints &&
                                                                                <TextToSpeechComponent text={ overviewAndTalkingPoints} />
                                                                            }
                                                                            {isLatestConductActionBranch && (
                                                                                <ForkRightOutlined />
                                                                            )}
                                                                        </Stack>
                                                                        {/*{latestConductAction && latestConductAction.images &&*/}
                                                                        {/*    <Grid container={true} spacing={1}>*/}
                                                                        {/*        <ScenarioImageGridDisplay images={latestConductAction.images} imageSize={175}/>*/}
                                                                        {/*    </Grid>*/}
                                                                        {/*}*/}
                                                                        <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems={"center"}>
                                                                            {latestConductAction && latestConductAction.parentId === null &&
                                                                                <Typography variant="caption" color="textSecondary" gutterBottom>
                                                                                    Step {currentActionIndex + 1} of {conductActions.length}
                                                                                </Typography>
                                                                            }
                                                                            {isVirtualAssistantOn && latestConductAction && latestConductAction.notes &&
                                                                                <Button size="small" variant="text" onClick={() => setOpenOverviewDialog(true)}>
                                                                                    Overview
                                                                                </Button>
                                                                            }
                                                                            {isVirtualAssistantOn && latestConductAction && latestConductAction.talkingPoint &&
                                                                                <Button size="small" variant="text" onClick={() => setOpenTalkingPointsDialog(true)}>
                                                                                    Talking Points
                                                                                </Button>
                                                                            }
                                                                            {latestConductAction && latestConductAction.instructions && userProfile && userProfile.role != 'PortalViewParticipant' &&
                                                                                <Button size="small" variant="text" onClick={() => setOpenInstructionsDialog(true)}>
                                                                                    Instructions
                                                                                </Button>
                                                                            }
                                                                        </Stack>

                                                                        <Dialog fullWidth={true} open={openObjectivesDialog} onClose={() => setOpenObjectivesDialog(false)}>
                                                                            <DialogTitle>Objectives for {latestConductAction && latestConductAction.name} </DialogTitle>
                                                                            <DialogContent>
                                                                                <ObjectiveList objectives={latestConductAction.objectives}
                                                                                               id={latestConductAction.id} title={"Objectives"}
                                                                                               showEdit={false} showDelete={false} showAdd={false}
                                                                                               showComplete={true}
                                                                                               onUpdate={handleObjectiveUpdate}
                                                                                />
                                                                            </DialogContent>
                                                                            <DialogActions>
                                                                                <Button onClick={() => setOpenObjectivesDialog(false)}>Close</Button>
                                                                            </DialogActions>
                                                                        </Dialog>

                                                                        <Dialog fullWidth={true} open={openInstructionsDialog} onClose={() => setOpenInstructionsDialog(false)}>
                                                                            <DialogTitle>Instructions for {latestConductAction && latestConductAction.name} </DialogTitle>
                                                                            <DialogContent>
                                                                                {userProfile && userProfile.role != 'PortalViewParticipant' && latestConductAction && latestConductAction.instructions &&
                                                                                    <>
                                                                                        <Grid container={true} spacing={2}>
                                                                                            <Grid item xs={12}>
                                                                                                <Typography variant="h5">Facilitator Instructions</Typography>
                                                                                            </Grid>

                                                                                            <Grid item xs={12}>
                                                                                                {latestConductAction.instructions.split('\n').map((instruction, index) => (
                                                                                                    <ReactMarkdown key={index} children={instruction} />
                                                                                                ))}
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </>
                                                                                }
                                                                            </DialogContent>
                                                                            <DialogActions>
                                                                                <Button onClick={() => setOpenInstructionsDialog(false)}>Close</Button>
                                                                            </DialogActions>
                                                                        </Dialog>

                                                                        <Dialog fullWidth={true} open={openOverviewDialog} onClose={() => setOpenOverviewDialog(false)}>
                                                                            <DialogTitle>Overview for {latestConductAction && latestConductAction.name} </DialogTitle>
                                                                            <DialogContent>
                                                                                    <>
                                                                                        <Grid container={true} spacing={2}>
                                                                                            <Grid item xs={12}>
                                                                                                <Typography variant="h5">Overview</Typography>
                                                                                            </Grid>

                                                                                            <Grid item xs={12}>
                                                                                                {latestConductAction.notes && latestConductAction.notes.split('\n').map((instruction, index) => (
                                                                                                    <ReactMarkdown key={index} children={instruction} />
                                                                                                ))}
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </>
                                                                            </DialogContent>
                                                                            <DialogActions>
                                                                                <Button onClick={() => setOpenOverviewDialog(false)}>Close</Button>
                                                                            </DialogActions>
                                                                        </Dialog>

                                                                        <Dialog fullWidth={true} open={openTalkingPointsDialog} onClose={() => setOpenTalkingPointsDialog(false)}>
                                                                            <DialogTitle>Overview for {latestConductAction && latestConductAction.name} </DialogTitle>
                                                                            <DialogContent>
                                                                                <>
                                                                                    <Grid container={true} spacing={2}>
                                                                                        <Grid item xs={12}>
                                                                                            <Typography variant="h5">Talking Points</Typography>
                                                                                        </Grid>

                                                                                        <Grid item xs={12}>
                                                                                            {latestConductAction.talkingPoint && latestConductAction.talkingPoint.split('\n').map((instruction, index) => (
                                                                                                <ReactMarkdown key={index} children={instruction} />
                                                                                            ))}
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </>
                                                                            </DialogContent>
                                                                            <DialogActions>
                                                                                <Button onClick={() => setOpenTalkingPointsDialog(false)}>Close</Button>
                                                                            </DialogActions>
                                                                        </Dialog>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center" >
                                                                            {userProfile && userProfile.role != 'PortalViewParticipant'  &&
                                                                                <ButtonGroup variant={"outlined"} size={"small"} sx={{ pr: 1 }}>
                                                                                    {userProfile && userProfile.role != 'PortalViewParticipant' && !isLatestConductActionBranch &&
                                                                                        <>
                                                                                            <Tooltip title={"Create a branch, or breakout, for this step"}>
                                                                                                <Button
                                                                                                    startIcon={<ForkRightOutlined />}
                                                                                                        onClick={() => {
                                                                                                            setBranchDescription("");
                                                                                                            setBranchOpenDialog(true);
                                                                                                        }}>
                                                                                                    Branch
                                                                                                </Button>
                                                                                            </Tooltip>
                                                                                        </>
                                                                                    }
                                                                                    {latestConductAction.objectives && latestConductAction.objectives.length > 0 &&
                                                                                        <Button onClick={() => setOpenObjectivesDialog(true)}
                                                                                                startIcon={allObjectivesPassing ? <CheckCircleIcon style={{ color: green[500] }} /> :<ChecklistOutlined/>}
                                                                                        >
                                                                                            Objectives
                                                                                        </Button>
                                                                                    }
                                                                                    <Menu
                                                                                        id="simple-menu"
                                                                                        anchorEl={anchorEl}
                                                                                        keepMounted
                                                                                        open={Boolean(anchorEl)}
                                                                                        onClose={handleClose}
                                                                                        sx={{
                                                                                            pr: 1,
                                                                                            '& .MuiPaper-root': {
                                                                                                overflow: 'visible',
                                                                                                boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
                                                                                                border: '1px solid rgba(0, 0, 0, 0.12)',
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <MenuItem
                                                                                            onClick={handleCompleteChange(latestConductAction.id)}>
                                                                                            <CheckBoxTwoTone color="success"/>Success</MenuItem>
                                                                                        <MenuItem
                                                                                            onClick={handleSkipChange(latestConductAction.id)}><IndeterminateCheckBox
                                                                                            color="secondary"/> Skipped</MenuItem>
                                                                                        <MenuItem
                                                                                            onClick={handleFailChange(latestConductAction.id)}><CheckBoxOutlineBlankOutlined
                                                                                            color="error"/> Fail</MenuItem>
                                                                                    </Menu>
                                                                                    <Tooltip title={"When you have finished this step, mark it as complete (either success, skipped, or fail)"}>
                                                                                        <Button  onClick={handleClick} startIcon={latestConductAction && latestConductAction.isComplete && <CheckCircleIcon style={{ color: green[500] }} />} >
                                                                                            <ArrowDropDown /> Complete
                                                                                        </Button>
                                                                                    </Tooltip>

                                                                                </ButtonGroup>
                                                                            }
                                                                        </Stack>

                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems={"center"}>
                                                                            {/*{latestConductAction && userProfile && userProfile.role != 'PortalViewParticipant' &&*/}
                                                                            {/*    <Tooltip title="Mark this step as 'current'. This allows participants to easily find your location in the TTX.">*/}
                                                                            {/*        <Button variant={"contained"} size={"small"}*/}
                                                                            {/*                onClick={handleMarkCurrent} disabled={latestConductAction.isCurrent}><MyLocationOutlined/></Button>*/}
                                                                            {/*    </Tooltip>*/}
                                                                            {/*}*/}
                                                                            {/*{latestConductAction && userProfile && userProfile.role === 'PortalViewParticipant' &&*/}
                                                                            {/*    <Tooltip title="Go to the current step marked by the facilitator in the scenario">*/}
                                                                            {/*        <Button variant={"contained"} size={"small"}*/}
                                                                            {/*                onClick={handleGoCurrent} disabled={latestConductAction.isCurrent} ><PlaceOutlined/></Button>*/}
                                                                            {/*    </Tooltip>*/}
                                                                            {/*}*/}
                                                                            {latestConductAction && latestConductAction.parentId !== null &&
                                                                                <>
                                                                                    <Tooltip title="Back to parent">
                                                                                        <Button variant={"contained"} size={"small"}
                                                                                                onClick={handleBranchPrevious}><ArrowCircleUpOutlined/></Button>
                                                                                    </Tooltip>
                                                                                </>
                                                                            }
                                                                            {latestConductAction && latestConductAction.parentId === null &&
                                                                                <>
                                                                                    <Tooltip title="View previous step">
                                                                                        <Button variant={"contained"} size={"small"}
                                                                                                onClick={handlePrevious}><NavigateBeforeOutlined/></Button>
                                                                                    </Tooltip>

                                                                                    <Tooltip title="View next step">
                                                                                        <Button
                                                                                            variant={"contained"}
                                                                                            size={"small"}
                                                                                            onClick={handleNext}
                                                                                            disabled={
                                                                                                !!(latestConductAction && !latestConductAction.isComplete) &&
                                                                                                !!(userProfile && userProfile.role === 'PortalViewParticipant')
                                                                                            }
                                                                                        >
                                                                                            <NavigateNextOutlined />
                                                                                        </Button>
                                                                                    </Tooltip>
                                                                                </>
                                                                            }
                                                                        </Stack>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container={true} spacing={1}>
                                                                    {isLoading &&
                                                                        <Grid item xs={12}> <QuickLoaderComponent loadBanner={simChatBanner}/></Grid>
                                                                    }
                                                                <Grid item xs={6}>
                                                                        <Grid container={true} spacing={1}>
                                                                            <Grid item xs={12}>
                                                                                {!conduct.isVirtualAssistantOn && latestConductAction.notes &&
                                                                                    <Grid item xs={12}>
                                                                                        <Grid container={true} spacing={1}>
                                                                                            <Grid item xs={12}>
                                                                                                <Stack direction={"row"}  alignItems={'center'} spacing={3}>
                                                                                                    <SummarizeOutlined style={{ fontSize: 36 }} color={"primary"}/>
                                                                                                    <Typography variant="overline" color={"textSecondary"}  gutterBottom>Overview</Typography>
                                                                                                </Stack>
                                                                                            </Grid>
                                                                                            <Grid item xs={12}>
                                                                                                <Typography variant="h5"  gutterBottom>
                                                                                                    <ReactMarkdown  children={latestConductAction.notes} />
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                }
                                                                                {!conduct.isVirtualAssistantOn && latestConductAction.description &&
                                                                                    <Grid item xs={12}>
                                                                                        <Grid container={true} spacing={1}>
                                                                                            <Grid item xs={12}>
                                                                                                <Typography variant="overline" color={"textSecondary"}  gutterBottom>Notes</Typography>
                                                                                            </Grid>
                                                                                            <Grid item xs={12}>
                                                                                                <Typography variant="h5" gutterBottom>
                                                                                                    <ReactMarkdown children={latestConductAction.description} />
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                }
                                                                            </Grid>
                                                                            {latestConductAction && latestConductAction.resources && latestConductAction.resources.length > 0 &&
                                                                                <Grid item xs={12}>
                                                                                    {latestConductAction.resources && portalId && conductId &&
                                                                                        <Grid item xs={12}>
                                                                                            <ConductFileNavigator resources={latestConductAction.resources} scenario={scenario} portalId={portalId} conductId={conductId}/>
                                                                                        </Grid>
                                                                                    }
                                                                                </Grid>
                                                                            }
                                                                            <Grid item xs={12}>
                                                                                {!conduct.isVirtualAssistantOn && latestConductAction.talkingPoint &&
                                                                                    <Grid item xs={12}>
                                                                                        <Grid container={true} spacing={1}>
                                                                                            <Grid item xs={12}>
                                                                                                <Stack direction={"row"}  alignItems={'center'} spacing={3}>
                                                                                                <RecordVoiceOverOutlined style={{ fontSize: 36 }} color={"primary"}/>
                                                                                                <Typography variant="overline" color={"textSecondary"}  gutterBottom>Talking Points
                                                                                                </Typography>
                                                                                                </Stack>
                                                                                            </Grid>
                                                                                            <Grid item xs={12}>
                                                                                                <Stack direction={"row"}  alignItems={'center'} spacing={3}>
                                                                                                    <Typography variant="h5" fontStyle={"italic"} gutterBottom>
                                                                                                        {latestConductAction.talkingPoint && latestConductAction.talkingPoint.split('\n').map((line, index) => (
                                                                                                            <ReactMarkdown key={index} children={line} />
                                                                                                        ))}
                                                                                                    </Typography>
                                                                                                </Stack>
                                                                                            </Grid>

                                                                                            </Grid>
                                                                                    </Grid>
                                                                                }
                                                                            </Grid>
                                                                            {conduct.isVirtualAssistantOn && faciliatorLoading &&
                                                                                <Grid item xs={12}>
                                                                                    <QuickLoaderComponent loadBanner={"AI Facilitator is responding..."}/>
                                                                                </Grid>
                                                                            }
                                                                            {conduct.isVirtualAssistantOn && facilitatorMessage && (
                                                                                <Grid item xs={12}>
                                                                                    <Grid item xs={12}>
                                                                                        <Stack direction={"row"}  alignItems={'center'} spacing={3}>
                                                                                            <AssistantOutlined style={{ fontSize: 36 }} color={"primary"}/>
                                                                                            <Typography variant="overline" color={"textSecondary"}  gutterBottom>TTX Assistant
                                                                                            </Typography>
                                                                                        </Stack>
                                                                                    </Grid>
                                                                                    <Typography variant="h5"  gutterBottom>
                                                                                        <ReactMarkdown  children={facilitatorMessage.answer} />
                                                                                    </Typography>
                                                                                </Grid>
                                                                            )}
                                                                        </Grid>
                                                                </Grid>
                                                                <Grid item={true} xs={6}>
                                                                    <Grid container={true} spacing={1}>
                                                                        {latestConductAction.branches && latestConductAction.branches.length > 0 &&
                                                                            <>
                                                                                <Grid item={true} xs={12}>
                                                                                    <Grid container spacing={2}>
                                                                                        <Grid item={true} xs={12}>
                                                                                            <Accordion>
                                                                                                <AccordionSummary
                                                                                                    expandIcon={<ExpandMoreIcon/>}
                                                                                                    aria-controls="panel1a-content"
                                                                                                    id="panel1a-header"
                                                                                                    title={"Expand branches for this step"}
                                                                                                >
                                                                                                    <Typography variant="h5"
                                                                                                                color="textSecondary">Branches: {latestConductAction.name} </Typography>
                                                                                                </AccordionSummary>
                                                                                                <AccordionDetails>
                                                                                                    <Grid container={true} spacing={2}>
                                                                                                        {latestConductAction.branches.map((branch, branchIndex) => (
                                                                                                            <> <Grid item
                                                                                                                     xs={1}><ForkRightOutlined
                                                                                                                fontSize={"small"}/></Grid>
                                                                                                                <Grid item xs={9}>
                                                                                                                    <Typography
                                                                                                                        key={branchIndex}
                                                                                                                        variant={"caption"}
                                                                                                                        color={branch.isSuccess ? "green" : branch.isSkipped ? "orange" : branch.isFailed ? "red" : "lightgrey"}>
                                                                                                                        {branch.name} {branch.isComplete}
                                                                                                                    </Typography>
                                                                                                                </Grid>
                                                                                                                <Grid item xs={2}>
                                                                                                                    <Stack direction="row"
                                                                                                                           spacing={1}
                                                                                                                           justifyContent={"flex-end"}>
                                                                                                                        <Tooltip
                                                                                                                            title={"View branch"}>
                                                                                                                            <Button
                                                                                                                                variant={"outlined"}
                                                                                                                                size={"small"}
                                                                                                                                onClick={() => handleBranchNavigation(latestConductAction.id, branch.id)}>View</Button>
                                                                                                                        </Tooltip>
                                                                                                                    </Stack>
                                                                                                                </Grid>
                                                                                                            </>
                                                                                                        ))}
                                                                                                    </Grid>
                                                                                                </AccordionDetails>
                                                                                            </Accordion>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </>
                                                                        }
                                                                        <Grid item={true} xs={12}>
                                                                            <MainCard title={`${latestConductAction.name}: Actions`}
                                                                                secondary={
                                                                                <Grid container={true} spacing={2}>
                                                                                    <Grid item xs={12}>
                                                                                        <Stack direction="row" spacing={1} justifyContent={"flex-end"}>
                                                                                            {userProfile && userProfile.role != 'PortalViewParticipant' &&
                                                                                                <ButtonGroup>
                                                                                                    <Button variant="contained" size={"small"} onClick={handleShowAssistantToggle}><AssistantOutlined/></Button>

                                                                                                    {showAssistantToggle &&
                                                                                                        (
                                                                                                            <>
                                                                                                            {userProfile && userProfile.role != 'PortalViewParticipant' &&
                                                                                                                <Tooltip title={"Analysis of actions"}>
                                                                                                                    <Button variant="outlined" size={"small"}
                                                                                                                            onClick={() => {
                                                                                                                                closeAll();
                                                                                                                                setShowAnalysisTextField(true);
                                                                                                                            }}>
                                                                                                                        <AssessmentOutlined/>
                                                                                                                    </Button>
                                                                                                                </Tooltip>
                                                                                                             }
                                                                                                            <Tooltip title={"Suggestions for this step"}>
                                                                                                                <Button variant="outlined" size={"small"}
                                                                                                                        onClick={() => {
                                                                                                                            closeAll();
                                                                                                                            setShowSuggestTextField(true);
                                                                                                                        }}>
                                                                                                                    <HelpCenterOutlined/>
                                                                                                                </Button>
                                                                                                            </Tooltip>
                                                                                                            {userProfile && userProfile.role != 'PortalViewParticipant' &&
                                                                                                                <Tooltip title={"Suggest injects for this step"}>
                                                                                                                    <Button variant="outlined" size={"small"}
                                                                                                                            onClick={() => {
                                                                                                                                closeAll();
                                                                                                                                setShowInject(true);
                                                                                                                            }}>
                                                                                                                        <InsertPageBreakOutlined/>
                                                                                                                    </Button>
                                                                                                                </Tooltip>
                                                                                                            }
                                                                                                            </>
                                                                                                            )
                                                                                                    }
                                                                                                </ButtonGroup>
                                                                                            }
                                                                                            <IconButton size={"small"} onClick={fetchData}>
                                                                                                <RefreshOutlined />
                                                                                            </IconButton>
                                                                                        </Stack>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            }
                                                                            >
                                                                                <Grid container={true} spacing={2}>
                                                                                        <Grid item xs={12}>
                                                                                            {conversation && conductId && conversation.length > 0 ?
                                                                                                <GuidedConductChatHistory
                                                                                                    theme={theme}
                                                                                                    chat={conversation.filter(item => item.actionId === latestConductAction.id)}
                                                                                                    onAnnotation={handleAnnotation}
                                                                                                    latestScriptItem={latestScriptItem}
                                                                                                    portalId={portalId ? portalId : ""}
                                                                                                    companyId={scenario.companyId}
                                                                                                    scenarioId={scenario.scenarioId}
                                                                                                    onVerifyAction={handleVerifyAction}
                                                                                                    onConductScriptItemChange={handleConductScriptItemChange}
                                                                                                    onBranch={handleBranch}
                                                                                                    onSectionComplete={handleSectionComplete}
                                                                                                    onStar={handleStarChange}
                                                                                                    onInsights={handleInsightsChange}
                                                                                                    onQuestion={handleQuestionChange}
                                                                                                    onFlagChange={handleFlagChange}
                                                                                                    onTimerChange={handleTimerChange}
                                                                                                    onFileDownload={handleMessageDownload}
                                                                                                    onUpdateChat={handleUpdateChat}
                                                                                                    userProfile={userProfile}
                                                                                                    isVirtualAssistantOn={conduct.isVirtualAssistantOn}
                                                                                                />
                                                                                                :
                                                                                                <Typography variant="caption" color="textSecondary">
                                                                                                    No actions logged or recorded for {latestConductAction.name}. Enter any notes, comments, or actions in the tabletop chat below.
                                                                                                </Typography>
                                                                                            }
                                                                                        </Grid>
                                                                                    </Grid>
                                                                            </MainCard>
                                                                        </Grid>
                                                                        {showFiles && (
                                                                            <>
                                                                                <Card >
                                                                                    <CardContent>
                                                                                        <Grid container={true} spacing={1}>
                                                                                            <Grid item xs={6}>
                                                                                                <Typography variant="h5" color="textSecondary"
                                                                                                            gutterBottom>
                                                                                                    Files for {latestConductAction.name}
                                                                                                </Typography>
                                                                                                {conductFiles && conductFiles.length > 0 ?
                                                                                                    <FileList files={conductFiles} onDownload={handleDownload} /> : <Typography variant="caption">No files uploaded</Typography>
                                                                                                }
                                                                                            </Grid>
                                                                                            <Grid item xs={6}>
                                                                                                <Typography variant="h5" color="textSecondary"
                                                                                                            gutterBottom>
                                                                                                    Artifacts for {latestConductAction.name}
                                                                                                </Typography>
                                                                                                {conductArtifacts && conductArtifacts.length > 0 ?
                                                                                                    <ArtifactList artifacts={conductArtifacts} onDownload={handleArtifactDownload} /> : <Typography variant="caption">No artifacts available</Typography>
                                                                                                }
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </CardContent>
                                                                                </Card>
                                                                            </>
                                                                        )}
                                                                    </Grid>
                                                                </Grid>
                                                                    <Grid item xs={12}>
                                                                        <Box
                                                                            sx={{
                                                                                position: 'fixed',
                                                                                bottom: 0,
                                                                                left: '50%',
                                                                                transform: 'translateX(-50%)',
                                                                                width: '80%',
                                                                                padding: 0,
                                                                                paddingBottom: 2,
                                                                                boxShadow: '0 -2px 10px rgba(0,0,0,0.1)',
                                                                                zIndex: 1000
                                                                            }}
                                                                        >
                                                                            <Card>
                                                                                <Grid container={true} spacing={1}>
                                                                                    {showRecordingBanner &&
                                                                                        <Grid item xs={12}><Alert severity="info">{recordingBannerLabel}</Alert> </Grid>
                                                                                    }
                                                                                    <Grid item xs={12}>
                                                                                        <OutlinedInput
                                                                                            id={`outlined-message${chatId}`}
                                                                                            fullWidth
                                                                                            minRows={2}
                                                                                            multiline={true}
                                                                                            disabled={!isTabletopStarted}
                                                                                            placeholder="Log participant actions, questions, note or comments for this exercise step..."
                                                                                            onChange={(e) => setMessage(e.target.value)}
                                                                                            value={message}
                                                                                            ref={bottomOfChatRef}
                                                                                            onKeyDown={handleKeypress(latestConductAction.id)}
                                                                                            startAdornment={
                                                                                                <Tooltip title={"Attach file for the TTX Assistant"}>
                                                                                                    <InputAdornment position="start">
                                                                                                        <IconButton
                                                                                                            aria-label="clear message"
                                                                                                            disabled={!isTabletopStarted}
                                                                                                            color={message && message.length>0 ? "primary": "default"}
                                                                                                            onClick={() => openMessageSendFile(latestConductAction.id)}
                                                                                                            edge="start"
                                                                                                        >
                                                                                                            <AttachFileOutlined/>
                                                                                                        </IconButton>
                                                                                                    </InputAdornment>
                                                                                                </Tooltip>
                                                                                            }
                                                                                            endAdornment={
                                                                                                <InputAdornment position="end">
                                                                                                    {userProfile && userProfile.role != 'PortalViewParticipant' &&
                                                                                                        <Tooltip title={"Toggle message visibility. Currently your message "+(isPrivateMessage?"will not ":"will ")+"be visible."}>
                                                                                                        <IconButton onClick={togglePrivateMessage} color={isPrivateMessage ? "inherit" : "primary"} size="small">{isPrivateMessage?<VisibilityOffOutlined/>:<VisibilityOutlined/>}</IconButton>
                                                                                                        </Tooltip>
                                                                                                    }
                                                                                                    {message && message.length > 0 ?
                                                                                                        <Tooltip title={"Send message to the TTX Assistant"}>
                                                                                                            <IconButton
                                                                                                                aria-label="send message"
                                                                                                                disabled={!isTabletopStarted}
                                                                                                                size={"small"}
                                                                                                                color={"primary"}
                                                                                                                onClick={sendMessage(latestConductAction.id)}
                                                                                                                edge="end"
                                                                                                            >
                                                                                                                <ArrowCircleUpOutlined />
                                                                                                            </IconButton>
                                                                                                        </Tooltip>
                                                                                                        :
                                                                                                        <Tooltip title={"Click to record a voice message and send to the Scenario Assistant"}>
                                                                                                            <IconButton size={"small"}
                                                                                                                        edge="end"
                                                                                                                        disabled={!isTabletopStarted}
                                                                                                                        onClick={isRecording ? stopRecording : startRecording}
                                                                                                            >
                                                                                                                {isRecording ? <MicOffOutlined color={"error"} /> : <MicOutlined />}
                                                                                                            </IconButton>
                                                                                                        </Tooltip>
                                                                                                    }
                                                                                                </InputAdornment>
                                                                                            }
                                                                                        />

                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Card>
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>

                                                       </>
                                                    )}
                                                </MainCard>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                          </>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
            {/*{userProfile && userProfile.role != 'PortalViewParticipant' &&*/}
            {/*    <>*/}
            {/*        <Grid item xs={12}>*/}
            {/*            <Divider/>*/}
            {/*        </Grid>*/}
            {/*        <Grid item xs={12}>*/}
            {/*            <Stack direction="row" alignItems="center" justifyContent={"flex-end"} spacing={1}>*/}
            {/*                <Typography variant="subtitle2" color={"secondary"}>Use AI Facilitator (Beta)?</Typography>*/}
            {/*                <Switch*/}
            {/*                    checked={isVirtualAssistantOn}*/}
            {/*                    onChange={handleToggleChange}*/}
            {/*                    name="virtualAssistantToggle"*/}
            {/*                    inputProps={{ 'aria-label': 'Virtual Assistant Toggle' }}*/}
            {/*                />*/}
            {/*            </Stack>*/}
            {/*        </Grid>*/}
            {/*    </>*/}
            {/*}*/}
            {/*<Typography variant="caption" color="textSecondary" gutterBottom>{latestConductAction?.id}</Typography>*/}
            </>
    );
};

export default ScenarioTabletopChatComponent;
