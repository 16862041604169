import React from 'react';
import {Dialog, DialogContent, DialogActions, DialogTitle, IconButton, Button, Grid, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface FullScreenImageDialogProps {
    open: boolean;
    onClose: () => void;
    imageUrl: string;
    altText: string;
    name?: string;
}

const FullScreenImageDialog: React.FC<FullScreenImageDialogProps> = ({ open, name, onClose, imageUrl, altText }) => {
    return (
        <Dialog fullScreen open={open} onClose={onClose}>
            <DialogTitle>
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={onClose}
                    aria-label="close"
                    style={{ position: 'absolute', right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                    <Grid container spacing={2}>
                        {name &&
                        <Grid item xs={12}>
                            <Typography variant={"h5"}>{name}</Typography>
                        </Grid>
                        }
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10}>
                            <img src={imageUrl} alt={altText} style={{width: '100%', height: '100%'}}/>
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" variant="contained">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FullScreenImageDialog;