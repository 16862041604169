import React, { useState, useEffect, useCallback } from 'react';
import { ConductFile } from "../../interfaces/interfaces";
// import FullScreenVideoDialog from './FullScreenVideoDialog';
import { Typography } from "@mui/material";

interface ConductFileVideoDisplayProps {
    video: ConductFile;
    videoSize?: number;
    scenarioId: string;
    companyId: string;
    portalId?: string;
    conductId?: string;
}

const ConductFileVideoDisplay: React.FC<ConductFileVideoDisplayProps> = ({ video, videoSize, scenarioId, conductId, portalId, companyId }) => {
    // const [open, setOpen] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [videoUrl, setVideoUrl] = useState<string | null>(null);
    const token = localStorage.getItem('token');

    const fetchVideoUrl = useCallback(async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_REST_API_URL}/api/files/scenario/getfileurl/${token}/${companyId}/${scenarioId}/${video.fileId}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const url = await response.text();
            setVideoUrl(url);
        } catch (error) {
            console.error('Failed to fetch video URL:', error);
            setError('Failed to load video URL');
        }
    }, [token, companyId, scenarioId, video.fileId]);

    useEffect(() => {
        fetchVideoUrl();
    }, [fetchVideoUrl]);

    // const handleClick = () => {
    //     setOpen(true);
    // };

    // const handleClose = () => {
    //     setOpen(false);
    // };

    const handleError = (event: React.SyntheticEvent<HTMLVideoElement, Event>) => {
        const videoElement = event.currentTarget;
        const error = videoElement.error;
        if (error) {
            console.error(`Error code: ${error.code}, message: ${error.message}`);
            setError(`Failed to load video. Error code: ${error.code}`);
        } else {
            setError("Failed to load video. Please check the video URL or format.");
        }
    };

    return (
        <>
            {error ? (
                <Typography variant="body2" color="error">
                    {error}
                </Typography>
            ) : (
                videoUrl && (
                    <video
                        style={{ maxWidth: videoSize ? `${videoSize}px` : '100%' }}
                        src={videoUrl}
                        controls
                       // onClick={handleClick}
                        onError={handleError}
                    />
                )
            )}
            {/*{videoUrl &&*/}
            {/*<FullScreenVideoDialog*/}
            {/*    open={open}*/}
            {/*    onClose={handleClose}*/}
            {/*    videoUrl={videoUrl}*/}
            {/*    name={video.shortName}*/}
            {/*    altText={video.fileName}*/}
            {/*/>*/}
            {/*}*/}
        </>
    );
};

export default ConductFileVideoDisplay;