import React, { useState } from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, CardContent, Tooltip} from '@mui/material';
import MainCard from '../MainCard';
import {ScenarioGroup} from '../../interfaces/interfaces';
import {AddCircleOutline} from "@mui/icons-material";
import GroupList from "./GroupList";
import GroupSelect from "./GroupSelect";

interface GroupCardProps {
    title: string;
    linkedGroups: string[];
    groups: ScenarioGroup[];
    id: string;
    onLinkGroup: (groupId: string, scriptId: string) => void;
    onUnlinkGroup: (groupId: string, scriptId: string) => void;
}

const GroupCard: React.FC<GroupCardProps> = ({ title, linkedGroups, groups, id, onLinkGroup, onUnlinkGroup }) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <MainCard title={title} secondary={
            <Tooltip title="Add Group">
                <Button variant="outlined" startIcon={<AddCircleOutline />} onClick={handleOpen}>
                    Group
                </Button>
            </Tooltip>
        }>
            <CardContent>
                <GroupList linkedGroups={linkedGroups} groups={groups} scriptId={id} onUnlinkGroup={onUnlinkGroup} />
            </CardContent>
            <Dialog open={open} onClose={handleClose} fullWidth={true}>
                <DialogTitle>Select Resource</DialogTitle>
                <DialogContent>
                    <GroupSelect groups={groups}  onLinkGroup={onLinkGroup} linkToId={id} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </MainCard>
    );
};

export default GroupCard;