import { FC } from 'react';
import {Button, List, ListItem, ListItemText, ListItemSecondaryAction, Stack, Grid, Tooltip} from '@mui/material';
import {ScenarioGroup} from '../../interfaces/interfaces';
import {DeleteForeverOutlined} from "@mui/icons-material";

interface GroupListProps {
  linkedGroups: string[];
  groups: ScenarioGroup[];
  scriptId: string;
  onUnlinkGroup?: (groupId: string, scriptId: string) => void;
}

const GroupList: FC<GroupListProps> = ({ linkedGroups, groups, scriptId, onUnlinkGroup }) => {
  return (
    <List>
      {linkedGroups && linkedGroups.map((resourceId) => {
        const resource = groups.find((res) => res.groupId === resourceId);
        return (
          <ListItem key={resourceId}>
            <Grid container spacing={2}>
              <Grid item xs={10}>
            <ListItemText
                primary={resource?.name}
                secondary={resource?.description}
            />
            </Grid>
            <Grid item xs={2}>
            {onUnlinkGroup && (
              <ListItemSecondaryAction>
                <Stack spacing={2} direction="row" justifyContent={"flex-end"}>
                  <Tooltip title={"Unlink Group"}>
                    <Button onClick={() => onUnlinkGroup(resourceId, scriptId || '')}><DeleteForeverOutlined/></Button>
                  </Tooltip>
                </Stack>
              </ListItemSecondaryAction>
            )}
            </Grid>
            </Grid>
          </ListItem>
        );
      })}
    </List>
  );
};

export default GroupList;