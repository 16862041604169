import React from 'react';
import { Box, Typography, useTheme, TypographyProps } from '@mui/material';

interface CustomBoxProps {
    size: number;
    text: string;
    color?: string;
    textSize?: TypographyProps['variant'];
}

const ColorTextBox: React.FC<CustomBoxProps> = ({ size, text, color, textSize = 'h2' }) => {
    const theme = useTheme();
    let displayText = 'TTX';
    let backgroundColor = (color ? color : theme.palette.primary.main) + 'E6'; // E6 is the hex code for 90% opacity

    if (text === 'Review') {
        displayText = 'Rvw';
        backgroundColor = theme.palette.info.main + 'E6';
    } else if (text && text !== 'Tabletop') {
        displayText = text;
    }

    return (
        <Box
            sx={{
                backgroundColor: backgroundColor,
                width: size,
                height: size,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                opacity: 0.9,
                color: '#fff',
                borderRadius: '8px', // Makes the box have rounded corners
                textAlign: 'center'
            }}
        >
            <Typography variant={textSize}>{displayText}</Typography>
        </Box>
    );
};

export default ColorTextBox;