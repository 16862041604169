import React from 'react';
import { ButtonGroup, Button } from '@mui/material';
import { PlayArrow, Pause, Stop } from '@mui/icons-material';

interface PlayPauseStopButtonsProps {
    handlePlay: () => void;
    handlePause?: () => void;
    handleStop: () => void;
}

const PlayPauseStopButtons: React.FC<PlayPauseStopButtonsProps> = ({
                                                                       handlePlay,
                                                                       handlePause,
                                                                       handleStop,
                                                                   }) => {
    const [isPlayDisabled, setIsPlayDisabled] = React.useState(false);
    const [isPauseDisabled, setIsPauseDisabled] = React.useState(true);
    const [isStopDisabled, setIsStopDisabled] = React.useState(true);

    const handlePlayClicked = () => {
        handlePlay();
        setIsPlayDisabled(true);
        setIsPauseDisabled(false);
        setIsStopDisabled(false);
    };

    const handlePauseClicked = () => {
        if (handlePause) {
            handlePause();
            setIsPlayDisabled(false);
        }
    };

    const handleStopClicked = () => {
        handleStop();
        setIsPlayDisabled(false);
        setIsPauseDisabled(true);
        setIsStopDisabled(true);
    };

    return (
        <ButtonGroup variant="contained" aria-label="play-pause-stop buttons" size={"medium"}>
            <Button variant={"contained"}>Conduct</Button>
            <Button
                variant="outlined"
                //color={!isPlayDisabled?"success":"primary"}
                onClick={handlePlayClicked}
                startIcon={<PlayArrow />}
                disabled={isPlayDisabled}
            >
                Start
            </Button>
            {handlePause &&
                <Button
                    variant="outlined"
                    onClick={handlePauseClicked}
                    startIcon={<Pause />}
                    disabled={isPauseDisabled}
                >
                    Pause
                </Button>
            }
            <Button
                variant="outlined"
                onClick={handleStopClicked}
                startIcon={<Stop />}
                disabled={isStopDisabled}
            >
                Stop
            </Button>
        </ButtonGroup>
    );
};

export default PlayPauseStopButtons;