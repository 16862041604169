import { useState, useEffect } from 'react';
import { Autocomplete, TextField } from '@mui/material';

interface SelectRoleTypeProps {
    onChange: (event: string) => void;
    value?: string;
    //... any other props required by SelectRoleType component
}

const SelectRoleType: React.FC<SelectRoleTypeProps> = ({ onChange, value }) => {
    const [roletypes, setRoleTypes] = useState<string[]>([]);
    const [selectedValue, setSelectedValue] = useState(value || '');
    const token = localStorage.getItem('token');

    useEffect(() => {
        fetchData();
    }, []);


    useEffect(() => {
        setSelectedValue(value || '');
    }, [value]);

    const fetchData = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/tabletop/utils/team/getroletypes/' + token);
            const data = await response.json();
            setRoleTypes(data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleChange = (event: any, value: string | null) => {
        setSelectedValue(value || '');
        onChange(value || '');
    };

    return (
        <Autocomplete
            freeSolo
            options={roletypes}
            value={selectedValue}
            onChange={handleChange}
            onInputChange={(event, newInputValue) => {
                setSelectedValue(newInputValue);
                onChange(newInputValue);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Participant's Role"
                    variant="outlined"
                />
            )}
        />
    );
};

export default SelectRoleType;