import React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import {ScriptItem} from "../../interfaces/interfaces";
import {Grid, Stack} from '@mui/material';
import {ArrowForwardOutlined} from "@mui/icons-material";

interface ScriptItemProps {
    script: ScriptItem[];
    orientation?: 'vertical' | 'horizontal';
}

const UniqueSectionTimeline: React.FC<ScriptItemProps> = ({ script, orientation = 'vertical' }) => {
    const uniqueSections = Array.from(new Set(script.map(item => item.section)));

    return (
        <>
            {orientation === 'horizontal' ?
                (
                <Grid container={true} spacing={1} justifyContent="center" alignItems="center">
                    {uniqueSections.map((section, index) => (
                        <Grid item xs={2} key={index}>
                            <Grid container={true} justifyContent="center" alignItems="center">
                                <Grid item xs={12}>
                                    <Stack
                                        direction="row"
                                        spacing={5}
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        >
                                    <Typography color="textSecondary">{section}
                                        {/*{index < uniqueSections.length - 1 && <CallToAction/>}*/}
                                    </Typography>
                                        {index < uniqueSections.length - 1 && <ArrowForwardOutlined/>}
                                    </Stack>
                                </Grid>

                            </Grid>
                        </Grid>
                    ))}
                </Grid>
                ) : (
                    <Timeline>
                        {script.map((section, index) => (
                            <TimelineItem key={index}>
                                <TimelineOppositeContent>
                                    <Typography color="textSecondary">
                                        {section.section ? section.section : section.section}
                                    </Typography>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot color={section.sectionComplete ? "success" : "primary"} variant={section.sectionComplete ? "filled" : "outlined"}/>                                    {index < uniqueSections.length - 1 && <TimelineConnector />}
                                </TimelineSeparator>
                                <TimelineContent></TimelineContent>
                            </TimelineItem>
                        ))}
                    </Timeline>
                )
            }
        </>
    );
};

export default UniqueSectionTimeline;