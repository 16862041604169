// `src/components/ConductSummary.tsx`

import React from 'react';
import { Grid, Card, CardContent, LinearProgress, Stack, Typography } from '@mui/material';
import MainCard from '../../components/MainCard';
import UniqueSectionTimeline from '../../components/scenario/UniqueSectionTimeline';
// import ConductParticipantList from '../../components/tabletop/ConductParticipantList';
// import ConductActionsList from '../../components/tabletop/ConductActionsList';
import ScriptItemListComponent from '../../components/tabletop/ScriptItemListComponent';
import { Scenario, Conduct } from '../../interfaces/interfaces';

interface ConductSummaryProps {
    scenario: Scenario;
    conduct: Conduct;
}

const ConductSummary: React.FC<ConductSummaryProps> = ({ scenario, conduct }) => {
    const completedItems = conduct?.script.filter(item => item.sectionComplete).length || 0;
    const totalItems = conduct?.script.length || 0;
    const progress = (completedItems / totalItems) * 100;

    return (
        <Grid container={true} spacing={2}>
            <Grid item xs={4}>
                <Grid container={true} spacing={2}>
                    <Grid item xs={12}>
                        <MainCard title="Sections Completed">
                            <Card>
                                <CardContent>
                                    <LinearProgress variant="determinate" value={progress}
                                                    color={progress === 100 ? 'success' : 'primary'}
                                    />
                                    <Stack>
                                        <Typography variant="body2">{completedItems} of {totalItems} sections completed</Typography>
                                    </Stack>
                                </CardContent>
                                <UniqueSectionTimeline script={conduct?.script || []} />
                            </Card>
                        </MainCard>
                    </Grid>
                    {/*<Grid item xs={12}>*/}
                    {/*    <MainCard title="Tabletop Participants">*/}
                    {/*        {conduct && conduct.participants && conduct.participants.length > 0 ? (*/}
                    {/*            <ConductParticipantList participants={conduct.participants} />*/}
                    {/*        ) : (*/}
                    {/*            <Card><CardContent>*/}
                    {/*                <Typography variant="body2">No Participants</Typography>*/}
                    {/*            </CardContent></Card>*/}
                    {/*        )}*/}
                    {/*    </MainCard>*/}
                    {/*    <MainCard title="Participant Actions">*/}
                    {/*        {conduct && conduct.actions && <ConductActionsList actions={conduct.actions} />}*/}
                    {/*    </MainCard>*/}
                    {/*</Grid>*/}
                </Grid>
            </Grid>
            <Grid item xs={8}>
                <MainCard title="Sections Conducted">
                    <Card>
                        <CardContent>
                            {conduct && <ScriptItemListComponent scriptItems={conduct.script}
                                                                 showConductSteps={true}
                            />}
                        </CardContent>
                    </Card>
                </MainCard>
            </Grid>
        </Grid>
    );
};

export default ConductSummary;