import  { useState, useEffect } from 'react';

// material-ui
import {
  Grid,
  Stack,
  useMediaQuery,
  Theme,
  // Button,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  Box,
  Slide,
  Pagination,
  Typography
} from '@mui/material';
import usePagination from 'hooks/usePagination';
import {Portal} from "../../../interfaces/interfaces";
import PortalCard from "../../../components/portal/PortalCard";

const allColumns = [
  {
    id: 1,
    header: 'Default'
  },
  {
    id: 2,
    header: 'Name'
  },
  {
    id: 3,
    header: 'Status'
  }
];

function dataSort(data: Portal[], sortBy: string) {
  return data.sort(function (a: any, b: any) {
    if (sortBy === 'Name') return a.name.localeCompare(b.name);
    if (sortBy === 'Status') return a.status.localeCompare(b.status);
    return a;
  });
}

const PortalListPage = () => {
  const matchDownSM = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  //
  const [portals, setPortals] = useState<Portal[]>([]);
  const token = localStorage.getItem('token');
  const [sortBy, setSortBy] = useState('Default');
  const [userCard, setUserCard] = useState<Portal[]>([]);
  const [page, setPage] = useState(1);

  const handleChange = (event: SelectChangeEvent) => {
    setSortBy(event.target.value as string);
  };

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(process.env.REACT_APP_REST_API_URL+'/api/portal/user/getportals/'+token);
          const data = await response.json();
          setPortals(data);
        } catch (error) {
          console.error(error);
        }

      };
      fetchData();
      setUserCard(dataSort(portals, sortBy).reverse());
    }, []);

  const PER_PAGE = 6;

  const count = Math.ceil(userCard.length / PER_PAGE);
  const _DATA = usePagination(userCard, PER_PAGE);

  const handleChangePage = (e: any, p: any) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <>
      <Box sx={{ position: 'relative', marginBottom: 3 }}>
        <Stack direction="row" alignItems="center">
          <Stack
            direction={matchDownSM ? 'column' : 'row'}
            sx={{ width: '100%' }}
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
          >

            <Stack direction={matchDownSM ? 'column' : 'row'} alignItems="center" spacing={1}>
              {portals && portals.length > 1 &&
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                  value={sortBy}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  renderValue={(selected) => {
                    if (!selected) {
                      return <Typography variant="subtitle1">Sort By</Typography>;
                    }

                    return <Typography variant="subtitle2">Sort by ({sortBy})</Typography>;
                  }}
                >
                  {allColumns.map((column) => {
                    return (
                      <MenuItem key={column.id} value={column.header}>
                        {column.header}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              }
            </Stack>
          </Stack>
        </Stack>
      </Box>
      <Grid container spacing={3}>
            {portals.map((portal: Portal) => (
                  <Slide key={portal.portalId} direction="up" in={true} timeout={50}>
                       <Grid item xs={portals && portals.length===0?4:12} >
                          <PortalCard portal={portal} />
                         </Grid>
                       </Slide>
              ))}
      </Grid>
      {portals && portals.length > 1 &&
      <Stack spacing={2} sx={{ p: 2.5 }} alignItems="flex-end">
        <Pagination
          sx={{ '& .MuiPaginationItem-root': { my: 0.5 } }}
          count={count}
          size="medium"
          page={page}
          showFirstButton
          showLastButton
          variant="combined"
          color="primary"
          onChange={handleChangePage}
        />
      </Stack>
      }
    </>
  );
};

export default PortalListPage;
