import React, { useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
    Button,
    Divider
} from '@mui/material';
import { AssistantOutlined } from '@mui/icons-material';
import {AsstantAnalysis} from "../../interfaces/interfaces";

interface AssistantAnalysisComponentProps {
    assistantAnalysis: AsstantAnalysis;
    displayIconOnly: boolean;
}

const AssistantAnalysisComponent: React.FC<AssistantAnalysisComponentProps> = ({ assistantAnalysis, displayIconOnly }) => {
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const getValidScoreColor = (validScore: number) => {
        return validScore >= 5 ? 'green' : 'red';
    };

    return (
        <>
            {displayIconOnly ? (
                <>
                    <Button onClick={handleOpenDialog}>
                        <AssistantOutlined fontSize={"small"}/>
                    </Button>
                    <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth>
                        <DialogTitle>Assistant Analysis</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" style={{ color: getValidScoreColor(assistantAnalysis.validScore) }}>
                                        Valid Score: {assistantAnalysis.validScore}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">{assistantAnalysis.responseAnalysisResult}</Typography>
                                </Grid>
                                <Grid item xs={12}><Divider/></Grid>
                                {assistantAnalysis.talkingPointsResult &&
                                    <Grid item xs={12}>
                                        <Typography variant="h5">Talking Points:</Typography>
                                        <Typography variant="caption">{assistantAnalysis.talkingPointsResult}</Typography>
                                    </Grid>
                                }
                                {assistantAnalysis.storylineResult &&
                                    <Grid item xs={12}>
                                        <Typography variant="h5">Story:</Typography>

                                        <Typography variant="caption">{assistantAnalysis.storylineResult}</Typography>
                                    </Grid>
                                }
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog} color="primary">Close</Button>
                        </DialogActions>
                    </Dialog>
                </>
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Assistant Analysis</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5" style={{ color: getValidScoreColor(assistantAnalysis.validScore) }}>
                            Valid Score: {assistantAnalysis.validScore}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">{assistantAnalysis.responseAnalysisResult}</Typography>
                    </Grid>
                    {assistantAnalysis.talkingPointsResult &&
                    <Grid item xs={12}>
                        <Typography variant="body1">{assistantAnalysis.talkingPointsResult}</Typography>
                    </Grid>
                    }
                    {assistantAnalysis.storylineResult &&
                    <Grid item xs={12}>
                        <Typography variant="body1">{assistantAnalysis.storylineResult}</Typography>
                    </Grid>
                    }
                </Grid>
            )}
        </>
    );
};

export default AssistantAnalysisComponent;