// material-ui
import {
    Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, Divider,
    Grid, Link, Stack, Tooltip, Typography
} from '@mui/material';

// project import
import {useEffect, useState} from "react";
import {AppUserProfile, Conduct, Scenario} from "interfaces/interfaces";
import {useParams} from "react-router";
import MainCard from "../../../components/MainCard";
import QuickLoaderComponent from "../../../components/cards/QuickLoaderComponent";
import {ArrowBackOutlined, DeleteForeverOutlined, PlayArrowOutlined} from "@mui/icons-material";
import {Link as RouterLink} from "react-router-dom";
import ScheduleDrawer from "../../../components/scenario/ScheduleConductDrawer";
import ScenarioDashboardDetails from "../../../components/scenario/ScenarioDashboardDetails";
import ScenarioDashboardReportCards from "../../../components/scenario/ScenarioDashboardReportCards";
import ScenarioWalkthroughDialog from "../../../components/dialog/ScenarioWalkthroughDialog";
import {CalendarOutlined} from "@ant-design/icons";
import ScheduleConductAndParticipantsDialog from "../../../components/dialog/ScheduleConductAndParticipantsDialog";
import {getPlaceholderConduct} from "../../../components/functions/interfacePlaceholders";
import ScenarioImageGridDisplay from "../../../components/scenario/ScenarioImageGridDisplay";
import ColorTextBox from "../../../components/icon/ColorTextBox";
import HoverSocialCard from "../../../components/cards/statistics/HoverSocialCard";
import {useTheme} from "@mui/material/styles";
import SectionFlow from "../../../components/tabletop/SectionFlow";

interface Params {
    scenarioId: string;
    companyId: string;
    portalId: string;
}
const PortalScenarioViewPage= () => {
    // @ts-ignore
    const { scenarioId, companyId, portalId } = useParams<Params>();
    const [scenario, setScenario] = useState<Scenario|undefined>(undefined);

    const token = localStorage.getItem('token');
    const [isLoading, setLoading] = useState<boolean>(false);

    const [label, setLabel] = useState<string>("Loading...");
    const [userProfile, setUserProfile] = useState<AppUserProfile | null | undefined>(null);

    const [open, setOpen] = useState(false);

    const toggleConductScheduleDrawer = () => {
        setConductScheduleDrawerOpen(!conductScheduleDrawerOpen);
    };

    const [conductScheduleDrawerOpen, setConductScheduleDrawerOpen] = useState(false);

    const [newConduct, setNewConduct] = useState<Conduct>(() => {
        const placeholderConduct = getPlaceholderConduct();
        placeholderConduct.scenarioId = scenarioId ? scenarioId : "";
        return placeholderConduct;
    });
    const handleDialogUpdate = async () => {
        setConductScheduleDrawerOpen(false);

        //post newConduct to api
        const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/portal/conduct/scheduleconduct/' + token + '/' + companyId + '/' + portalId + '/' +scenarioId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newConduct)
        });
        const data = await response.json();
        setNewConduct(data);
    }
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        // setLatestMessage("");
        const fetchData = async () => {
            try {
                setLabel("Loading Scenario...");
                setLoading(true);
                const response = await fetch(process.env.REACT_APP_REST_API_URL+'/api/portal/company/getscenario/'+token+'/'+companyId+'/'+portalId+'/' + scenarioId);
                const data = await response.json();
                setScenario(data);
                setLoading(false);
            } catch (error) {
                console.error(error);
            }

        };
        fetchData();
        const fetchData2 = async () => {
            const response2 = await fetch(process.env.REACT_APP_REST_API_URL + '/api/auth/profile/view/' + token);
            const data2 = await response2.json();
            setUserProfile(data2);
        };
        fetchData2();
    }, []);

    const [openScheduleDialog, setOpenScheduleDialog] = useState(false);
    function handleOpenScheduleDialog() {
        setOpenScheduleDialog(true);
    }

    function handleScheduleConduct(scheduledConduct: Conduct | null) {
        const fetchData = async () => {
            setLabel("Scheduling Tabletop Exercise...");
            setLoading(true);
            const updateURL = `${process.env.REACT_APP_REST_API_URL}/api/scenario/company/scheduletabletop/${token}/${scenario?.companyId}/${scenario?.scenarioId}`;
            try {
                await fetch(updateURL, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(scheduledConduct),
                });

            } catch (error) {
                console.error("Error scheduling conduct:", error);
            }
            setLoading(false);
            setOpenScheduleDialog(false);
        };
        fetchData();
    }

    const [conductId, setConductId] = useState<string>();
    const [openDialog, setOpenDialog] = useState(false);
    const handleStartTTX = async () => {
        if (scenarioId) {
            setOpenDialog(true);
            // Call your API to get a new conductId
            const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/portal/scenario/startnewtabletop/' + token + '/' +
                companyId + '/' + portalId + '/' + scenarioId+"/Standard");
            const data = await response.json();
            setConductId(data.conductId);
        }
    }

    function handleDeleteConduct() {

        // console.log("calling API to delete: "+ companyId, scenarioId, conductId);
        try {
            fetch(process.env.REACT_APP_REST_API_URL + '/api/tabletop/company/scenario/tabletop/removeconduct/' + token + '/' + companyId + '/' + scenarioId + '/' + conductId, {
                method: 'GET'
            });
            setConductId(undefined);
            setOpenDialog(false);
        } catch (error) {
            console.error(error);
        }
    }
    const theme = useTheme();
    return (
        <Grid container spacing={2}>
                <Grid item xs={12} >
                    <Grid container={true} spacing={2} >
                        <Grid item xs={12} >
                        <MainCard content={true}>
                            <Grid container={true} spacing={2} >
                                    <Grid item xs={1} >
                                        {scenario && scenario.scenarioStory && scenario.scenarioStory.images ?
                                            <ScenarioImageGridDisplay images={scenario.scenarioStory.images} imageSize={95}/>
                                            :
                                            <>{scenario && scenario.exerciseType && <ColorTextBox size={95} text={scenario.exerciseType}/>}</>
                                        }
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Grid container={true} spacing={1}>
                                            <Grid item xs={12}>
                                                <HoverSocialCard
                                                    primary={"Scenario View"}
                                                    color={theme.palette.primary.main}
                                                    secondary={
                                                        `${scenario?.name || ''}`
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Stack direction="column"  spacing={2}>
                                            <Link color="primary" component={RouterLink} to={`/portal/viewportal/${companyId}/${portalId}`}>
                                                <Button startIcon={<ArrowBackOutlined/>} variant="outlined" size={"medium"}>
                                                    Portal
                                                </Button>
                                            </Link>
                                            {userProfile && userProfile.role != 'PortalViewParticipant' &&
                                            <Tooltip title={"View a detailed walkthrough of the exercise"}>
                                                <Button onClick={handleClickOpen} variant="outlined" size={"medium"}>
                                                    Walkthrough
                                                </Button>
                                            </Tooltip>
                                            }
                                        </Stack>
                                    </Grid>
                            </Grid>
                            <ScheduleDrawer
                                open={conductScheduleDrawerOpen}
                                onClose={toggleConductScheduleDrawer}
                                conduct={newConduct}
                                handleDialogUpdate={handleDialogUpdate}
                                setConduct={setNewConduct}
                            />

                            <Grid item xs={12} >
                                {isLoading &&
                                    <QuickLoaderComponent loadBanner={label} />
                                }
                            </Grid>
                            <Grid container spacing={2} >
                                <Grid item={true} xs={5}></Grid>
                                <Grid item={true} xs={5}>
                                    {userProfile && userProfile.role != 'PortalViewParticipant' &&
                                    <Stack direction="row" justifyContent="flex-start" spacing={1}>
                                        <ButtonGroup variant="outlined" size={"medium"}  color="primary" aria-label="outlined primary button group">
                                                    <Button variant={"contained"}>{scenario && (!scenario.exerciseType || scenario.exerciseType === "Tabletop") ? "Tabletop" : "Review"}</Button>
                                                        <Button variant="outlined" onClick={handleStartTTX}
                                                                 startIcon={<PlayArrowOutlined/> }>
                                                            Start
                                                        </Button>
                                                    <Tooltip title={"Schedule a new Tabletop"}>
                                                        <Button variant="outlined" size={"small"}
                                                                startIcon={<CalendarOutlined/>}
                                                                onClick={handleOpenScheduleDialog}
                                                        >
                                                            Schedule
                                                        </Button>
                                                    </Tooltip>
                                        </ButtonGroup>
                                    </Stack>
                                    }
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} >
                                <Grid item xs={12}><Divider/></Grid>
                            </Grid>
                            <Grid container spacing={2} >
                            <Grid item xs={12}>
                                {scenario && scenario.script &&
                                    <SectionFlow sections={scenario.script} /> }
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1} >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1} >
                                            <Grid item xs={9}>
                                                <ScenarioDashboardDetails  scenario={scenario} />
                                            </Grid>
                                            <Grid item xs={3}>
                                                {scenario &&  <ScenarioDashboardReportCards scenario={scenario} showExerciseDates={true}/> }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                        </MainCard>
                        </Grid>
                    </Grid>
                </Grid>

            <Dialog fullWidth={true} open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Start Conduct</DialogTitle>
                <DialogContent>
                    {!conductId && <QuickLoaderComponent loadBanner={"Creating new Tabletop Exercise..."} />}
                    {conductId &&
                        <>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h5">The new Tabletop Exercise has been created!</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction={"row"} spacing={2}>
                                        <Link  component={RouterLink} to={`/portal/conduct/${portalId}/${companyId}/${scenarioId}/${conductId}`}>
                                            <Tooltip title={"Begin the new tabletop"}>
                                                <Button color={"success"} variant={"contained"}>
                                                    Begin Exercise
                                                </Button>
                                            </Tooltip>
                                        </Link>
                                        <Tooltip title={"Remove the new tabletop and cancel the exercise"}>
                                            <Button color={"error"} variant={"contained"} onClick={handleDeleteConduct}>
                                                Remove Exercise <DeleteForeverOutlined/>
                                            </Button>
                                        </Tooltip>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>Close</Button>
                </DialogActions>
            </Dialog>

            <ScheduleConductAndParticipantsDialog
                scheduleConductOpen={openScheduleDialog}
                handleUpdateConduct={handleScheduleConduct}
                handleScheduleConductClose={() => setOpenScheduleDialog(false)}
                userProfile={userProfile}
                conduct={newConduct}
            />
            {scenario &&
                <ScenarioWalkthroughDialog
                    scenario={scenario}
                    scenarioOverviewOpen={open}
                    handleScenarioWalkthroughClose={handleClose}
                />
            }
        </Grid>
    );
};
export default PortalScenarioViewPage;
