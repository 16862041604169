import React from 'react';
import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    Checkbox,
    LinearProgress,
    Divider,
    Grid,
    Stack
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ScriptItem, ScenarioObjective } from '../../interfaces/interfaces';

interface ObjectivesOverviewProps {
    scriptItems: ScriptItem[];
}

const ObjectivesOverview: React.FC<ObjectivesOverviewProps> = ({ scriptItems }) => {
    const calculateCompletionPercentage = (objectives: ScenarioObjective[], totalItems: number): number => {
        const met = objectives ? objectives.filter(obj => obj && obj.isMet).length : 0;
        return totalItems > 0 ? (met / totalItems) * 100 : 0;
    };

    const calculateItemCompletionPercentage = (items: any[], totalItems: number): number => {
        const met = items ? items.filter(item => item && item.isComplete).length : 0;
        return totalItems > 0 ? (met / totalItems) * 100 : 0;
    };

    const renderObjectives = (objectives: ScenarioObjective[]) => (
        <List>
            {objectives && objectives.map((objective, index) => (
                <ListItem key={index}>
                    <Checkbox checked={objective?.isMet || false} />
                    <ListItemText primary={objective?.objective || ''} />
                </ListItem>
            ))}
        </List>
    );

    const getProgressColor = (percentage: number) => {
        return percentage > 75 ? 'success' : 'secondary';
    };

    return (
        <Box>
            {scriptItems && scriptItems.map((scriptItem, scriptIndex) => {
                const filteredSteps = scriptItem.conductScriptItems.filter(item => item.objectives && item.objectives.length > 0);
                const totalSteps = scriptItem.conductScriptItems.length;
                const sectionCompletion = filteredSteps.length > 0
                    ? calculateCompletionPercentage(filteredSteps.flatMap(item => item.objectives), totalSteps)
                    : calculateItemCompletionPercentage(scriptItem.conductScriptItems, totalSteps);

                return (
                    <Box key={scriptIndex} mb={4}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    Section: {scriptItem.section} {scriptItem.sectionComplete && <CheckCircleIcon style={{ color: 'green', marginLeft: '8px' }} />}
                                </Typography>
                            </Grid>
                            {scriptItem.conductScriptItems.map((conductScriptItem, conductIndex) => {
                                const stepCompletion = conductScriptItem.objectives?.length > 0
                                    ? calculateCompletionPercentage(conductScriptItem.objectives, totalSteps)
                                    : (conductScriptItem.isComplete ? 100 : 0);
                                return (
                                    <Grid item xs={12} key={conductIndex}>
                                        <Box ml={2} mt={2}>
                                            <Grid container spacing={2} alignItems="center">
                                                <Grid item xs={12}>
                                                    <Typography variant="caption" color="textSecondary">Step ({conductIndex + 1} of {totalSteps})</Typography>

                                                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                                                        <Typography variant="subtitle1">
                                                            {stepCompletion === 100 && <CheckCircleIcon style={{ color: 'green' }} />}
                                                        </Typography>
                                                        <Typography variant="subtitle1">
                                                            {conductScriptItem.name} Objectives
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                                {conductScriptItem.objectives &&
                                                <Grid item xs={12}>
                                                    {renderObjectives(conductScriptItem.objectives)}
                                                </Grid>
                                                }
                                                <Grid item xs={12}>
                                                    <Typography variant="body2">
                                                        Step Completion: {stepCompletion.toFixed(2)}%
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <LinearProgress variant="determinate" value={stepCompletion} color={getProgressColor(stepCompletion)} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                );
                            })}
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2">
                                    Section Completion: {sectionCompletion.toFixed(2)}%
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <LinearProgress variant="determinate" value={sectionCompletion} color={getProgressColor(sectionCompletion)} />
                            </Grid>
                        </Grid>
                    </Box>
                );
            })}

            <Box mt={4}>
                <Typography variant="h5">Overall Completion</Typography>
                <Typography variant="body2">
                    {calculateCompletionPercentage(
                        scriptItems.flatMap(scriptItem => scriptItem.conductScriptItems.filter(item => item.objectives && item.objectives.length > 0).flatMap(item => item.objectives)),
                        scriptItems.flatMap(scriptItem => scriptItem.conductScriptItems).length
                    ).toFixed(2)}%
                </Typography>
                <LinearProgress
                    variant="determinate"
                    value={calculateCompletionPercentage(
                        scriptItems.flatMap(scriptItem => scriptItem.conductScriptItems.filter(item => item.objectives && item.objectives.length > 0).flatMap(item => item.objectives)),
                        scriptItems.flatMap(scriptItem => scriptItem.conductScriptItems).length
                    )}
                    color={getProgressColor(calculateCompletionPercentage(
                        scriptItems.flatMap(scriptItem => scriptItem.conductScriptItems.filter(item => item.objectives && item.objectives.length > 0).flatMap(item => item.objectives)),
                        scriptItems.flatMap(scriptItem => scriptItem.conductScriptItems).length
                    ))}
                />
            </Box>
        </Box>
    );
};

export default ObjectivesOverview;