import { FC } from 'react';
import { Stepper, Step, StepLabel, Typography } from '@mui/material';
import {ScriptItem} from "../../interfaces/interfaces";

interface SectionFlowProps {
  sections: ScriptItem[];
  currentSection?: ScriptItem;
}

const SectionFlow: FC<SectionFlowProps> = ({ sections, currentSection }) => {
    return (
        <Stepper activeStep={currentSection ? sections.findIndex(section => section.id === currentSection.id) : 0} alternativeLabel>
            {sections && sections.map((section, index) => (
                <Step key={section.id}>
                    <StepLabel >
                        {(currentSection && section.id === currentSection.id) ? (
                            <Typography variant="body1"> {section.section}</Typography>
                        ) : (
                            <Typography variant="body2"> {section.section}</Typography>
                        )}
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    );
};

export default SectionFlow;