// material-ui
import {Box, Card, CardContent, Chip, Grid, Stack, Typography} from '@mui/material';

// types
import { GenericCardProps } from 'types/root';

// ===========================|| HOVER SOCIAL CARD ||=========================== //

interface HoverSocialCardProps extends GenericCardProps {
    chipText?: string;
    chipLocation?: 'top' | 'bottom';
}
// Utility function to determine if a color is dark
const isColorDark = (color: string): boolean => {
    const hex = color.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    // Using the luminance formula to determine if the color is dark
    const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
    return luminance < 140; // Adjust the threshold as needed
};

const HoverSocialCard = ({ primary, secondary, iconPrimary, color, chipText, chipLocation }: HoverSocialCardProps) => {
    const IconPrimary = iconPrimary!;
    const primaryIcon = iconPrimary ? <IconPrimary /> : null;
    const textColor = color && isColorDark(color) ? '#FFFFFF' : '#000000';

    return (
        <Card sx={{ bgcolor: color ? color : 'default' }} elevation={1}>
            <CardContent>
                <Box>
                    {primaryIcon}
                </Box>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Typography variant="h3" style={{ color: textColor }}>
                                {secondary}
                            </Typography>
                            {chipText && chipLocation && chipLocation === 'top' &&
                                <Chip color={"primary"} label={chipText} size={"small"} />
                            }
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Typography variant="subtitle2" style={{ color: textColor }}>
                                {primary && typeof primary === 'string' && primary.split('\n').map((line: string, i: number) => (
                                    <Typography key={i} variant="subtitle2" style={{ color: textColor }}>
                                        {line}
                                    </Typography>
                                ))}
                            </Typography>
                            {chipText && chipLocation && chipLocation === 'bottom' &&
                                <Chip color={"primary"} label={chipText} size={"small"} />
                            }
                        </Stack>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default HoverSocialCard;