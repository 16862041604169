import React, { FC } from 'react';
import {
  Select,
  MenuItem,
  SelectChangeEvent,
  InputLabel,
  Button,
  Typography,
  List,
  ListItem,
  Stack, Grid
} from '@mui/material';
import {ScenarioGroup} from "../../interfaces/interfaces";
import {LinkOutlined} from "@mui/icons-material";

interface GroupSelectProps {
  groups: ScenarioGroup[];
  onLinkGroup?: (groupId: string, scriptId: string) => void;
  linkToId: string;
}

const GroupSelect: FC<GroupSelectProps> = ({ groups, linkToId, onLinkGroup }) => {
  const [selectedResource, setSelectedResource] = React.useState('');

    const handleChange = (event: SelectChangeEvent<string>) => {
        setSelectedResource(event.target.value);
    };

  function handleLinkResource(resourceId:string, linkToId: string) {
    if (selectedResource && onLinkGroup) {
      //console.log('Linking resource', selectedResource, 'to script', scriptId);
      onLinkGroup(selectedResource, linkToId);
    }

  }

  return (
      <>
      <Grid container spacing={1} >
        <Grid item={true} xs={12} >
          <InputLabel id="demo-simple-select-label">Available Groups</InputLabel>
        </Grid>
        <Grid item={true} xs={8} >
        <Select
            labelId="demo-simple-select-label"
            value={selectedResource}
            label="Available Groups"
            fullWidth={true}
            onChange={handleChange}
          >
            {groups.map((resource) => (
              <MenuItem value={resource.groupId} key={resource.groupId}>
                <List>
                  <ListItem>
                    <Stack spacing={2} direction="row">
                      <Typography variant="body1" component="span">
                        {resource.name}
                      </Typography>
                      <Typography variant="body2" component="span" color="text.secondary">
                        {resource.description}
                      </Typography>
                    </Stack>
                  </ListItem>
                </List>
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item={true} xs={4} >
        <Button
            variant={"outlined"}
            startIcon={<LinkOutlined/>}
            onClick={() => handleLinkResource(selectedResource, linkToId)}
            disabled={!selectedResource}
        >
          Group
        </Button>
        </Grid>
        </Grid>
      </>
  );
};

export default GroupSelect;