
// material-ui
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MainCard from 'components/MainCard';
import { Portal} from "../../interfaces/interfaces";
import {ArrowForwardOutlined, SummarizeOutlined} from "@mui/icons-material";

const PortalCard = ({ portal }: { portal: Portal }) => {
  const navigate = useNavigate();

  const handleClickOpen = () => {
    navigate(`/portal/viewportal/${portal.companyId}/${portal.portalId}`);
  };

  return (
    <>
      <MainCard sx={{ height: 1, '& .MuiCardContent-root': { height: 1, display: 'flex', flexDirection: 'column' } }}>
        <Grid id="print" container spacing={2.25}>
          <Grid item xs={12}>
            <List sx={{ width: 1, p: 0 }}>
              <ListItem
                disablePadding
              >

                <ListItemAvatar>
                  <SummarizeOutlined />
                  {/*<Avatar alt={company.name} src={avatarImage(`./avatar-${!company.avatar ? 1 : company.avatar}.png`)} />*/}
                </ListItemAvatar>
                <ListItemText
                  primary={<Typography variant="subtitle1">{portal.name}</Typography>}
                  secondary={
                   <>
                     <Typography variant="caption" color="secondary">
                      {portal.type}
                    </Typography>
                   </>
                  }
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"h5"}>Portal for: {portal.companyName}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography>{portal.description}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  listStyle: 'none',
                  p: 0.5,
                  m: 0
                }}
                component="ul"
              >
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Stack
          direction="row"
          className="hideforPDf"
          alignItems="center"
          spacing={1}
          justifyContent="space-between"
          sx={{ mt: 'auto', mb: 0, pt: 2.25 }}
        >
          <Typography variant="caption" color="secondary">
            <Chip label="Active" size="small" color="success" />
          </Typography>
          <Button variant="outlined" size="large" onClick={handleClickOpen} endIcon={<ArrowForwardOutlined/>}>
            Enter
          </Button>
        </Stack>
      </MainCard>
    </>
  );
};

export default PortalCard;
