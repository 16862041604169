
import React, { useRef } from 'react';
import {Button, Card, CardContent, Divider, Grid, Stack, TextField, Typography} from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {styled} from "@mui/material/styles";
import {FileData} from "../interfaces/interfaces";
import QuickLoaderComponent from "./cards/QuickLoaderComponent";
import { Autocomplete } from '@mui/material';

interface FileUploaderProps {
    apiUrl: string;
    title: string;
    onUpload?: (json: any) => void;
}

const FileUploader: React.FC<FileUploaderProps> = ({ apiUrl, title, onUpload }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
    const [shortName, setShortName] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [fileText, setFileText] = React.useState("");
    const [type, setType] = React.useState("");
    const [isUploading, setIsUploading] = React.useState(false);

  const handleFileSelect = () => {
    const files = fileInputRef.current?.files;
    if (files) {
      uploadFiles(files);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    uploadFiles(files);
  };

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const uploadTextFile = () => {
        const formData = new FormData();

        // Create a FileData object
        const fileData: FileData = {
            shortName: shortName,
            description: description,
            category: "", // Add the category state variable when it's defined
            type: type,
            fileText: fileText
        };

        const fileDataBlob = new Blob([JSON.stringify(fileData)], { type: 'application/json' });
        formData.append('fileData', fileDataBlob);

        // Create an empty Blob and append it as 'file'
        const emptyBlob = new Blob([''], { type: 'text/plain' });
        formData.append('file', emptyBlob);

        setIsUploading(true);

        fetch(apiUrl, {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(data => {
                setShortName("");
                setDescription("");
                setType("");
                try {
                    onUpload && onUpload(data);
                } catch (e) {
                    console.error(e);
                }
                setIsUploading(false);
            })
            .catch(error => {
                // Handle error
                console.error('Upload error:', error);
                setIsUploading(false);
            });
    };

    const uploadFiles = (files: FileList) => {
        const formData = new FormData();

        // Create a FileData object
        const fileData: FileData = {
            shortName: shortName,
            description: description,
            category: "", // Add the category state variable when it's defined
            type: type,
            fileText: fileText
        };

        const fileDataBlob = new Blob([JSON.stringify(fileData)], { type: 'application/json' });
        formData.append('fileData', fileDataBlob);

        Array.from(files).forEach(file => {
            formData.append('file', file);
        });

        setIsUploading(true);

        fetch(apiUrl, {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(data => {
                setShortName("");
                setDescription("");
                setType("");
                onUpload && onUpload(data);
                setIsUploading(false);
            })
            .catch(error => {
                // Handle error
                console.error('Upload error:', error);
                setIsUploading(false);
            });
    };

    const fileTypes = [
        "Policy",
        "Runbook",
        "Screenshot",
        "Certification",
        "Org Chart",
        "Procedure Document",
        "Incident Report",
        "Service Agreement",
        "Audit Report",
        "SOP (Standard Operating Procedure)",
        "Training Manual",
        "Contingency Plan",
        "Risk Assessment",
        "Business Continuity Plan",
        "Operational Checklist",
        "Contract",
        "Compliance Report",
        "Maintenance Log",
        "Financial Statement",
        "User Guide",
        "Security Protocol",
        "Vendor Document",
        "Annual Report",
        "Meeting Minutes"
    ];

    return (
      <div
          className="p-4 border rounded text-center"
          onDrop={handleDrop}
          onDragOver={event => event.preventDefault()}
      >
                  <Grid container spacing={2}>
                      <Grid item xs={12}>
                          {isUploading && <QuickLoaderComponent loadBanner={"Uploading..."} />}
                      </Grid>
                      <Grid item xs={12}>
                          <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                              {title}
                          </Typography>
                      </Grid>
                      <Grid item xs={12}>
                          <Grid container spacing={1}>
                          <Grid item xs={12}>
                              <Typography variant="caption" color="textSecondary">Step 1: Information about the file</Typography>
                          </Grid>
                              <Grid item xs={12}>
                                  <Card variant="outlined">
                                      <CardContent>
                                        <Grid container spacing={1}>
                                      <Grid item xs={6}>
                                          <Grid container spacing={1}>
                                            <Grid item xs={12}>
                              <Autocomplete
                                  options={fileTypes}
                                  fullWidth={true}
                                  freeSolo
                                  renderInput={(params) => (
                                      <TextField
                                          {...params}
                                          label="Type"
                                          variant="outlined"
                                      />
                                  )}
                                  onChange={(event, value) => setType(value || '')}
                              />
                          </Grid>
                                            <Grid item xs={12}>
                              <TextField
                                  label="Short Name"
                                  fullWidth={true}
                                  variant="outlined"
                                  onChange={event => setShortName(event.target.value)}
                              />
                          </Grid>
                                          </Grid>
                                      </Grid>
                                        <Grid item xs={6}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>

                                                  <TextField
                                                      label="Description (optional)"
                                                      multiline={true}
                                                      fullWidth={true}
                                                      minRows={3}
                                                      variant="outlined"
                                                      onChange={event => setDescription(event.target.value)}
                                                  />
                                            </Grid>
                                        </Grid>
                                  </Grid>
                              </Grid>
                                      </CardContent>
                                  </Card>
                              </Grid>
                          </Grid>
                          <Grid item xs={12}>
                          <Divider orientation={"vertical"} textAlign={"left"}/>
                          </Grid>
                          <Grid item xs={12}><br/></Grid>
                          <Grid item xs={12}>
                              <Typography variant="caption" color="textSecondary">Step 2: Asset Data</Typography>
                          </Grid>
                          <Grid item xs={12}>
                              <Card variant="outlined">
                                  <CardContent>
                              <Grid container spacing={1}>

                                  <Grid item xs={12}>
                                      <Grid container={true} spacing={1}>
                                          <Grid item xs={12}>
                                              <Typography variant="caption" color="textSecondary">For file assets</Typography>
                                              <Card variant="outlined">
                                                  <CardContent>
                                                      <Typography variant="caption" color="textSecondary">
                                                          <span className="drop-text">Drag and drop files here or click to select files</span>
                                                          <VisuallyHiddenInput
                                                              type="file"
                                                              id="file-input"
                                                              multiple
                                                              onChange={handleFileSelect}
                                                              className="d-none"
                                                              ref={fileInputRef}
                                                          />
                                                      </Typography>
                                                  </CardContent>
                                              </Card>
                                          </Grid>
                                      </Grid>
                                  </Grid>
                                  <Grid item xs={12}>
                                      <Stack spacing={1} alignItems="center" direction={"row"} justifyContent="flex-end">
                                          <Button variant="outlined"  size={"small"} onClick={() => fileInputRef.current?.click()} startIcon={<CloudUploadIcon />}>
                                              Select Files to Upload
                                          </Button>
                                      </Stack>
                                  </Grid>
                                  <Grid item xs={12}>
                                      <Grid container spacing={1}>
                                          <Grid item={true} xs={12}>
                                              <Typography variant="caption" color="textSecondary">For text assets</Typography>
                                          </Grid>
                                          <Grid item={true} xs={12}>
                                              <TextField
                                                  label="Paste asset text here"
                                                  multiline={true}
                                                  fullWidth={true}
                                                  minRows={8}
                                                  onChange={event => setFileText(event.target.value)}
                                              />
                                          </Grid>
                                          <Grid item xs={12}>
                                              <Stack spacing={1} alignItems="center" direction={"row"} justifyContent="flex-end">
                                                  <Button variant="outlined" size={"small"} onClick={uploadTextFile} startIcon={<CloudUploadIcon />}>
                                                      Upload Text Asset
                                                  </Button>
                                              </Stack>
                                          </Grid>
                                      </Grid>
                                  </Grid>
                              </Grid>
                                  </CardContent>
                              </Card>
                          </Grid>
                      </Grid>
                  </Grid>
      </div>
  );
};

export default FileUploader;