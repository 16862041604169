import {
     Chip, Divider,
    Grid, List, ListItem,
    ListItemText, Stack, Typography,
} from "@mui/material";
import {GameMessage, ScriptItem} from "../../interfaces/interfaces";
import React, {useEffect, useState} from "react";
import {CheckCircle, ForkRightOutlined, RemoveCircleOutline} from "@mui/icons-material";

interface ScenarioScriptTabletopComponentProps {
    scriptItems: ScriptItem[];
    showSteps?: boolean;
    showConductSteps?: boolean;
    showActionAnalysis?: boolean;
    messages?: GameMessage[];
}

const ScriptItemListComponent: React.FC<ScenarioScriptTabletopComponentProps> = ({ scriptItems, showSteps,
                                                                                     showConductSteps, showActionAnalysis,
                                                                                 messages}) => {
    const [updatedScriptItems, setUpdatedScriptItems] = useState<ScriptItem[]>(scriptItems || []);


    useEffect(() => {
        setUpdatedScriptItems(scriptItems);
    }, [scriptItems]);

    return (
        <>
            <Grid container={true} spacing={2}>
            {updatedScriptItems && updatedScriptItems.map((scriptItem:ScriptItem,index) => {
                return (
                    <>
                        {scriptItem &&
                           <>
                               <Grid item={true} xs={12}>
                                   <Grid container={true} spacing={2}>
                                       <Grid item={true} xs={12}>
                                            <Typography variant="h3">Section {index + 1}: {scriptItem.section}</Typography>
                                       </Grid>
                                    {showConductSteps && scriptItem.conductScriptItems && scriptItem.conductScriptItems.length > 0 &&
                                        <>
                                            {scriptItem.conductScriptItems.map((step, stepIndex) => {
                                                let chipLabel = '';
                                                let chipColor: 'success' | 'primary' | 'error' = 'primary';

                                                if (step.isSuccess) {
                                                    chipLabel = 'Success';
                                                    chipColor = 'success';
                                                } else if (step.isSkipped) {
                                                    chipLabel = 'Skipped';
                                                    chipColor = 'primary';
                                                } else if (step.isFailed) {
                                                    chipLabel = 'Failed';
                                                    chipColor = 'error';
                                                }

                                                return (
                                                    <>
                                                        <Grid item={true} xs={8}>
                                                            <Stack direction="row" justifyContent="flex-start" alignItems="center">
                                                                <Typography variant="h5" >Step {stepIndex+1}: {step.name}</Typography>
                                                            </Stack>
                                                        </Grid>
                                                            <Grid item={true} xs={4}>
                                                            <Stack direction="row" justifyContent="flex-end" alignItems="center">
                                                                {chipLabel &&
                                                                <Chip size={"small"} label={chipLabel} color={chipColor} />
                                                                }
                                                                    {step.branches && step.branches.length > 0 && (
                                                                        <ForkRightOutlined color={"primary"}/>
                                                                    )}
                                                                    {step.isComplete &&
                                                                        <CheckCircle style={{ color: 'green' }} />
                                                                    }
                                                                    {!step.isComplete && !step.branches &&
                                                                        <RemoveCircleOutline style={{ color: 'primary' }} />
                                                                    }
                                                            </Stack>
                                                        </Grid>
                                                        {showActionAnalysis && (
                                                            <>
                                                                {messages && messages.filter(message => message.actionId === step.id).length > 0 ? (
                                                                    messages
                                                                        .filter(message => message.actionId === step.id)
                                                                        .map((message, messageIndex) => (
                                                                            <React.Fragment key={messageIndex}>
                                                                                <Grid item={true} xs={12}>
                                                                                    <Grid container={true} spacing={2}>
                                                                                        <Grid item={true} xs={12}>
                                                                                            <Typography variant="body2" component="em">"{message.gameQuestion}"</Typography>
                                                                                        </Grid>
                                                                                        <Grid item={true} xs={12}>
                                                                                            <Typography
                                                                                                variant="h6"
                                                                                                style={{ color: message.assistantAnalysis?.validScore !== undefined ? (message.assistantAnalysis.validScore <= 6 ? 'red' : message.assistantAnalysis.validScore >= 7 ? 'green' : 'inherit') : 'inherit' }}
                                                                                            >
                                                                                                Score: {message.assistantAnalysis?.validScore}
                                                                                            </Typography>
                                                                                            <Typography variant="body2">{message.assistantAnalysis?.responseAnalysisResult}</Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid item={true} xs={1}></Grid>
                                                                                <Grid item={true} xs={10}><Divider/></Grid>
                                                                                <Grid item={true} xs={1}></Grid>
                                                                            </React.Fragment>
                                                                        ))
                                                                ) : (
                                                                    <Grid item={true} xs={12}>
                                                                        <Typography variant="body2" component="em">No actions</Typography>
                                                                    </Grid>
                                                                )}
                                                            </>
                                                        )}

                                                    </>
                                                );
                                            })}
                                        </>                                    }
                                    {showSteps && scriptItem.scenarioScriptSectionSteps && scriptItem.scenarioScriptSectionSteps.length > 0 &&
                                        <Grid item xs={12}>
                                            <Grid container={true} spacing={2}>
                                                {scriptItem.scenarioScriptSectionSteps.map((step, stepIndex) => (
                                                    <>
                                                        <Grid item xs={2}>
                                                            <Typography variant="h4" component="div">
                                                                Step {stepIndex+1}</Typography>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <Typography variant="h4" component="div">
                                                                {step.stepName}</Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                             <Chip label={step.stepType} color={"primary"}/>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <ListItemText primary={"Overview"} secondary={`${step.stepContent} `} sx={{ userSelect: 'text' }} />
                                                        </Grid>
                                                        {step.stepObjectives && step.stepObjectives.length > 0 &&
                                                        <Grid item xs={12}>
                                                            <ListItemText primary={"Objectives"} secondary={
                                                                <List>
                                                                {step?.stepObjectives?.map((objective, index) => (
                                                                    <ListItem key={index}>
                                                                        {objective.objective}
                                                                    </ListItem>
                                                                ))}
                                                            </List>
                                                            } sx={{ userSelect: 'text' }} />
                                                        </Grid>
                                                        }
                                                         {step.stepInstructions && (
                                                            <Grid item xs={12}>
                                                                <ListItemText primary={"Instructions"} secondary={step.stepInstructions} sx={{ userSelect: 'text' }} />
                                                            </Grid>
                                                        )}
                                                        {step.stepTalkingPoint && (
                                                            <Grid item xs={12}>
                                                                <ListItemText primary={"Talking Points"} secondary={step.stepTalkingPoint} sx={{ userSelect: 'text' }} />
                                                            </Grid>
                                                        )}
                                                       {step.stepStoryline && (
                                                            <Grid item xs={12}>
                                                                <ListItemText primary={"Storyline"} secondary={step.stepStoryline} sx={{ userSelect: 'text' }} />
                                                            </Grid>
                                                        )}
                                                        <Grid item xs={12}>
                                                            <Divider/>
                                                        </Grid>
                                                    </>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    }
                                   </Grid>
                                </Grid>
                               <Grid item={true} xs={12}>
                                   <Divider/>
                                 </Grid>
                        </>
                        }
                    </>
                );
            })}
            </Grid>
        </>
    );
};

export default ScriptItemListComponent;